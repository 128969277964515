define('tickets/components/department-users-select', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Component.extend({
		tagName: '',
		addEmptyOption: true,
		selectedUsers: [],
		selectedGroups: [],
		groupUsers: Ember.computed('selectedGroups', function () {
			var _this = this;

			var groupsIds = this.selectedGroups;

			if (!groupsIds) {
				return [];
			}

			var allGroupUsers = groupsIds.reduce(function (groupUsers, groupId) {
				var users = _this.groups.findBy('id', groupId).users.toArray();

				groupUsers.push.apply(groupUsers, _toConsumableArray(users));

				return groupUsers.uniq();
			}, []);

			this.unselectNotVisibleUsers(allGroupUsers);

			return allGroupUsers;
		}),
		unselectNotVisibleUsers: function unselectNotVisibleUsers(allGroupsUsers) {
			var visibleUserIds = allGroupsUsers.map(function (_ref) {
				var id = _ref.id;
				return id;
			});
			var updatedSelectedUsers = this.selectedUsers.filter(function (selectedId) {
				return visibleUserIds.includes(selectedId);
			});

			this.set('selectedUsers', updatedSelectedUsers);

			return this.sendAction('updateSelectedItems', updatedSelectedUsers);
		},

		actions: {
			updateSelectedDepartments: function updateSelectedDepartments(ids) {
				this.set('selectedGroups', ids);
			},
			updateSelectedUsers: function updateSelectedUsers(selected) {
				this.sendAction('updateSelectedItems', selected);
			}
		}
	});
});