define('tickets/components/departments-edit-section', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['container-content-panel'],
		sectionGroups: [],
		selectedSections: Ember.computed.alias('sections.[]'), //TODO wrap selectable table in separate component
		didRender: function didRender() {
			this.$('.tabular.menu .item').tab();
		},

		actions: {
			toggleAll: function toggleAll() {
				this.sendAction('toggleAll');
			},
			toggle: function toggle(value) {
				this.sendAction('toggle', value);
			},
			save: function save() {
				this.sendAction('save');
			}
		}
	});
});