define('tickets/components/date-time-field', ['exports', 'tickets/components/date-time-picker'], function (exports, _dateTimePicker) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _dateTimePicker.default.extend({
		classNames: ['highlighted-section'],
		classNameBindings: ['focused:active-box'],
		focused: false,
		description: '',
		date: ''
	});
});