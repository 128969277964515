define('tickets/controllers/users/index', ['exports', 'tickets/mixins/controllers/listing', 'tickets/mixins/controllers/filters'], function (exports, _listing, _filters) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	var SELECT_INPUT_QUERY_PARAMS = ['userName'];

	exports.default = Ember.Controller.extend(_listing.default, _filters.default, {
		userName: '',
		showFiltersReset: true,
		queryParams: SELECT_INPUT_QUERY_PARAMS,
		pageCount: Ember.computed.alias('model.users.meta.pageCount'),
		selectUserRoute: 'users.edit',
		columns: Ember.computed(function () {
			return [{
				name: 'User name',
				attribute: 'userName'
			}, {
				name: 'User email',
				attribute: 'email'
			}, {
				name: 'Enabled',
				attribute: 'isActive'
			}, {
				name: 'Role',
				attribute: 'role.name'
			}, {
				name: 'Number of groups',
				attribute: 'groups.length'
			}];
		}),
		selectInputFilterOptions: [{ value: 'userName', name: 'User Name' }],
		selectInputKey: Ember.computed.apply(undefined, SELECT_INPUT_QUERY_PARAMS.concat([function () {
			var _this = this;

			var preselectedQuery = SELECT_INPUT_QUERY_PARAMS.find(function (query) {
				return _this.get(query);
			});

			return preselectedQuery || 'userName';
		}])),
		filters: Ember.computed.apply(undefined, SELECT_INPUT_QUERY_PARAMS.concat([function () {
			return [{
				type: 'select-input',
				mapProperty: 'value',
				displayProperty: 'name',
				placeholder: 'Press enter to search',
				dropdownValue: this.get('selectInputFilterOptions'),
				property: this.get('selectInputKey'),
				value: this.get(this.selectInputKey),
				regex: /^[^]{3,}$/,
				validationMessage: 'This field should contain at least 3 symbols'
			}];
		}])),
		actions: {
			updateFilter: function updateFilter(filter, value) {
				var _this2 = this;

				Ember.run.next(function () {
					var _this2$setProperties;

					return _this2.setProperties((_this2$setProperties = {}, _defineProperty(_this2$setProperties, filter, value), _defineProperty(_this2$setProperties, 'page', 1), _this2$setProperties));
				});
			}
		}
	});
});