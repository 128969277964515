define('tickets/routes/notifications', ['exports', 'tickets/mixins/routes/ticketing-system'], function (exports, _ticketingSystem) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_ticketingSystem.default, {
		counter: Ember.inject.service(),
		queryParams: {
			page: {
				refreshModel: true
			},
			limit: {
				refreshModel: true
			}
		},
		model: function model(_ref) {
			var page = _ref.page,
			    limit = _ref.limit;

			this.counter.fetchCounter();

			return Ember.RSVP.hash({
				notifications: this.store.query('notification', {
					page: page,
					limit: limit
				})
			});
		}
	});
});