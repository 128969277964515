define('tickets/helpers/rsvp-hash', ['exports', '@abcum/ember-helpers/helpers/rsvp-hash'], function (exports, _rsvpHash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _rsvpHash.default;
    }
  });
  Object.defineProperty(exports, 'rsvpHash', {
    enumerable: true,
    get: function () {
      return _rsvpHash.rsvpHash;
    }
  });
});