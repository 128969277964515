define('tickets/enums/sourceTypes', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		NONE: '100',
		EMAIL: '200',
		GEMINI: '300',
		LIGHTHOUSE: '400',
		WEB_FORM: '800'
	});
});