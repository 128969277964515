define('tickets/routes/roles/edit', ['exports', 'tickets/mixins/routes/edit', 'tickets/enums/requiredPermissions'], function (exports, _edit, _requiredPermissions) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_edit.default, {
		requiredPermissions: _requiredPermissions.default.ROLES,
		model: function model(_ref) {
			var id = _ref.id;

			return Ember.RSVP.hash({
				role: this.getModel('role', id),
				permissions: this.store.peekAll('permission')
			});
		}
	});
});