define('tickets/utils/validate-email', ['exports', 'tickets/utils/regex'], function (exports, _regex) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateEmail;


  /**
   * Validates email
   * @param {String} email Email that should be validated
   * @returns {boolean} Is email valid
   */
  function validateEmail(email) {
    return email && email.length && (0, _regex.testEmail)(email);
  }
});