define('tickets/mixins/controllers/routing-tabs/wrapper', ['exports', 'tickets/mixins/controllers/routing-tabs/common'], function (exports, _common) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create(_common.default, {
		router: Ember.inject.service(),
		tabs: [],
		activeTab: Ember.computed.alias('router.currentRouteName'),
		currentController: Ember.computed('activeTab', function () {
			var owner = Ember.getOwner(this);
			var currentRouteName = this.get('activeTab');
			var route = owner.lookup('route:' + currentRouteName);

			return route && route.controller;
		})
	});
});