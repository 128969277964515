define('tickets/enums/taskStatuses', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		OPEN: '100',
		DONE: '500'
	});
});