define('tickets/routes/surveys', ['exports', 'tickets/mixins/routes/ticketing-system', 'tickets/enums/requiredPermissions'], function (exports, _ticketingSystem, _requiredPermissions) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_ticketingSystem.default, {
		requiredPermissions: _requiredPermissions.default.SURVEYS,
		groups: Ember.inject.service(),
		model: function model() {
			return Ember.RSVP.hash({
				groups: this.groups.getAll(),
				whitelistedGroups: this.groups.getWhitelisted()
			});
		}
	});
});