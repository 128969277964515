define('tickets/components/generic-reports-table', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		headers: [],
		rows: []
	});
});