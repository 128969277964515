define('tickets/mixins/controllers/tasks/edit', ['exports', 'tickets/enums/taskStatuses'], function (exports, _taskStatuses) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		users: Ember.inject.service(),
		task: null,
		showAssociatedTicketLink: false,
		showReminderField: Ember.computed.bool('task.deadline'),
		disablePastDates: Ember.computed.not('task.deadline'),
		showCompleteDate: Ember.computed('task.taskStatus.id', 'task.updatedAt', function () {
			return this.get('task.taskStatus.id') === _taskStatuses.default.DONE;
		}),
		markAsDoneDisabled: Ember.computed('task.taskStatus.id', 'task.assignee.id', 'task.reporter.id', function () {
			var currentUserId = this.get('users.self.id');
			var taskReporterId = this.get('task.reporter.id');
			var taskAssigneeId = this.get('task.assignee.id');
			var currentUserIsTaskReporter = taskReporterId === currentUserId;
			var currentUserIsTaskAssignee = taskAssigneeId === currentUserId;
			var taskIsOpen = this.get('task.taskStatus.id') === _taskStatuses.default.OPEN;

			return !((currentUserIsTaskReporter || currentUserIsTaskAssignee) && taskIsOpen);
		}),
		reminders: [{
			text: '1 hour',
			value: 1
		}, {
			text: '6 hours',
			value: 6
		}, {
			text: '1 day',
			value: 24
		}, {
			text: '2 days',
			value: 48
		}, {
			text: '1 week',
			value: 168
		}, {
			text: '1 month',
			value: 720
		}],
		actions: {
			markTaskDone: function markTaskDone() {
				var taskDone = this.store.peekRecord('taskStatus', _taskStatuses.default.DONE);
				this.setProperties({
					'task.updatedAt': null, // Do not show completed date if save button is not pressed yet
					'task.taskStatus': taskDone
				});
			},
			setDeadline: function setDeadline(date) {
				var task = this.get('task');
				task && task.setProperties({ deadline: date, reminder: null });
			}
		}
	});
});