define('tickets/helpers/chain', ['exports', '@abcum/ember-helpers/helpers/chain'], function (exports, _chain) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _chain.default;
    }
  });
  Object.defineProperty(exports, 'chain', {
    enumerable: true,
    get: function () {
      return _chain.chain;
    }
  });
});