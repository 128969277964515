define('tickets/controllers/tickets/create', ['exports', 'tickets/enums/ticketChannelTypes', 'tickets/utils/promisesSettledQueue', 'tickets/mixins/controllers/tickets/edit'], function (exports, _ticketChannelTypes, _promisesSettledQueue, _edit) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var computed = Ember.computed;
	exports.default = Ember.Controller.extend(_edit.default, {
		pageTitle: 'Create Ticket',
		queryParams: ['phone', 'customerId'],
		includedChannelTypes: [_ticketChannelTypes.default.PHONE, _ticketChannelTypes.default.EMAIL, _ticketChannelTypes.default.CHAT],
		selectableChannelTypes: computed(function () {
			var _this = this;

			return this.model.ticketChannelTypes.filter(function (_ref) {
				var id = _ref.id;
				return _this.includedChannelTypes.includes(id);
			});
		}),
		enableDownloadAllButton: false,
		saveComments: function saveComments() {
			var _this2 = this;

			var internalComments = this.get('ticket.internalComments').toArray();
			var externalComments = this.get('ticket.externalComments').toArray();

			return (0, _promisesSettledQueue.default)([].concat(_toConsumableArray(internalComments), _toConsumableArray(externalComments)).map(function (comment) {
				return function () {
					return comment.isNew && _this2.save(comment, false);
				};
			}));
		},
		resetModel: function resetModel() {
			this.get('ticket.attachments').forEach(function (attachment) {
				attachment.destroyRecord();
			});
		},
		updateTicketPriorityType: function updateTicketPriorityType() {
			this.set('ticket.priorityType', this.get('ticket.group.priorityType'));
		},

		actions: {
			save: function save() {
				var _this3 = this;

				this._super().then(function () {
					_this3.saveComments();
					_this3.transitionToRoute('tickets.edit', _this3.ticket.id);
				}).catch(function () {});
			},
			updateWatchers: function updateWatchers(watchers) {
				this.set('ticket.watchers', watchers);
			},
			selectGroup: function selectGroup() {
				this._super.apply(this, arguments);

				this.updateTicketPriorityType();
			}
		}
	});
});