define('tickets/components/generic-filters/dropdown-filter', ['exports', 'tickets/components/input-field'], function (exports, _inputField) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _inputField.default.extend({
		filter: null,
		actions: {
			onDropdownChanged: function onDropdownChanged(value) {
				this.set('filter.value', value);
				this.sendAction('updateFilter', this.get('filter.property'), value);
			}
		}
	});
});