define('tickets/validators/dropdown', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Dropdown = _base.default.extend({
		validate: function validate(value) {
			return !!value.get('id');
		}
	});

	exports.default = Dropdown;
});