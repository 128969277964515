define('tickets/helpers/ucwords', ['exports', '@abcum/ember-helpers/helpers/ucwords'], function (exports, _ucwords) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ucwords.default;
    }
  });
  Object.defineProperty(exports, 'ucwords', {
    enumerable: true,
    get: function () {
      return _ucwords.ucwords;
    }
  });
});