define('tickets/services/download', ['exports', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _fileSaver) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend(_fileSaver.default, {
		ajax: Ember.inject.service(),
		download: function download(url, fileName) {
			var _this = this;

			var absoluteUrl = '/api/v1/' + url;

			return this.get('ajax').request(absoluteUrl, {
				dataType: 'arraybuffer'
			}).then(function (file) {
				return _this.saveFileAs(fileName, file);
			}).catch(function (e) {
				throw e.payload;
			});
		}
	});
});