define('tickets/enums/ticketsDisplayTypes', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		ALL_TICKETS: 'all',
		NOT_ARCHIVED_TICKETS: 'not-archived'
	});
});