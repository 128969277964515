define('tickets/initializers/modelChangeTracker', ['exports', 'ember-data/model'], function (exports, _model) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.initialize = initialize;

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	/**
  * Add methods to Ember Model Class to make available changes tracking for not saved models.
  */
	function initialize() {
		_model.default.reopen({
			initState: {},
			enableChangeTrackingOfInitState: false,
			getCustomChanges: function getCustomChanges(changes) {
				return changes;
			},
			saveModelState: function saveModelState() {
				var currentState = this.toJSON();

				var notEmptyAttrs = Object.entries(currentState).reduce(function (acc, _ref) {
					var _ref2 = _slicedToArray(_ref, 2),
					    key = _ref2[0],
					    value = _ref2[1];

					if (value) {
						acc[key] = value;
					}

					return acc;
				}, {});

				this.set('initState', notEmptyAttrs);
			},
			excludeInitStateFromChanges: function excludeInitStateFromChanges(currentChanges) {
				var currentState = this.toJSON();
				var initState = this.get('initState');

				Object.keys(initState).forEach(function (key) {
					var newValue = currentState[key];
					var oldValue = initState[key];

					if (JSON.stringify(oldValue) === JSON.stringify(newValue)) {
						delete currentChanges[key];
					}
				});

				return currentChanges;
			},
			clearSavedModelState: function clearSavedModelState() {
				this.set('initState');
			},
			getModelChanges: function getModelChanges() {
				var currentChanges = this.modelChanges();

				currentChanges = this.getCustomChanges(currentChanges);

				if (this.enableChangeTrackingOfInitState) {
					currentChanges = this.excludeInitStateFromChanges(currentChanges);
				}

				return currentChanges;
			}
		});
	}

	exports.default = {
		after: 'ember-data-change-tracker',
		initialize: initialize
	};
});