define('tickets/mixins/routes/groups', ['exports', 'tickets/mixins/routes/ticketing-system', 'tickets/enums/sourceTypes', 'tickets/enums/requiredPermissions'], function (exports, _ticketingSystem, _sourceTypes, _requiredPermissions) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create(_ticketingSystem.default, {
		requiredPermissions: _requiredPermissions.default.GROUPS,
		users: Ember.inject.service(),
		departments: Ember.inject.service(),
		queryGroup: function queryGroup() {
			return this.store.createRecord('group', {
				sourceType: this.store.peekRecord('sourceType', _sourceTypes.default.NONE),
				department: this.departments.getAll().findBy('name', 'General'),
				isActive: true
			});
		},
		model: function model(params) {
			return Ember.RSVP.hash({
				sourceTypes: this.store.peekAll('sourceType').filterBy('readOnly', false),
				brandingStrategies: this.store.peekAll('brandingStrategy'),
				priorityTypes: this.store.peekAll('priorityType'),
				departments: this.departments.getAll(),
				brands: this.store.peekAll('brand'),
				users: this.users.getAll(),
				group: this.queryGroup(params)
			});
		},
		setupController: function setupController(controller) {
			this._super.apply(this, arguments);

			controller.setProperties({
				validations: null
			});
		},
		afterModel: function afterModel(_ref) {
			var group = _ref.group;

			group.saveModelState();
		}
	});
});