define('tickets/abilities/user', ['exports', 'tickets/mixins/ability', 'tickets/enums/permissions'], function (exports, _ability, _permissions) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ability.default.extend({
		canRead: Ember.computed('users.permissions.[]', function () {
			return this.hasPermission(_permissions.default.MANAGEMENT_USERS_READ) || this.hasPermission(_permissions.default.MANAGEMENT_USERS_WRITE);
		}),
		canWrite: Ember.computed('users.permissions.[]', function () {
			return this.hasPermission(_permissions.default.MANAGEMENT_USERS_WRITE);
		})
	});
});