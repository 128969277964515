define('tickets/routes/roles/index', ['exports', 'tickets/mixins/routes/ticketing-system', 'tickets/enums/requiredPermissions'], function (exports, _ticketingSystem, _requiredPermissions) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_ticketingSystem.default, {
		requiredPermissions: _requiredPermissions.default.ROLES,
		queryParams: {
			page: { refreshModel: true },
			limit: { refreshModel: true }
		},
		model: function model(_ref) {
			var page = _ref.page,
			    limit = _ref.limit;

			return Ember.RSVP.hash({ roles: this.store.query('role', { page: page, limit: limit }) });
		}
	});
});