define('tickets/components/comments-tab/comment/public', ['exports', 'tickets/enums/sourceTypes'], function (exports, _sourceTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _extends = Object.assign || function (target) {
		for (var i = 1; i < arguments.length; i++) {
			var source = arguments[i];

			for (var key in source) {
				if (Object.prototype.hasOwnProperty.call(source, key)) {
					target[key] = source[key];
				}
			}
		}

		return target;
	};

	var BODY_REGEX = /^([\s\S])+?(?=(> )?On [A-Z]{1}[a-z]{2}.*wrote:)/;

	exports.default = Ember.Component.extend({
		message: '',
		conversation: '',
		tagName: '',
		hiddenCommentSources: [],
		publicComment: Ember.computed('comment.{user,details,sourceType}', function () {
			var details = this.get('comment.details') || {};
			var userName = this.get('comment.user.userName');
			var sourceTypeId = this.get('comment.sourceType.id');
			var hiddenCommentSources = this.get('hiddenCommentSources');
			var from = details.from,
			    to = details.to,
			    cc = details.cc;

			var isHidden = hiddenCommentSources.includes(sourceTypeId);
			var defaultData = { isHidden: isHidden };

			this.setMessageAndConversation();

			switch (sourceTypeId) {
				case _sourceTypes.default.EMAIL:
					return _extends({}, defaultData, { author: from.join(', '), to: to, cc: cc, source: 'wrote from email' });
				case _sourceTypes.default.GEMINI:
					return _extends({}, defaultData, { author: from, source: 'wrote from Gemini' });
				case _sourceTypes.default.LIGHTHOUSE:
					return _extends({}, defaultData, { author: from, source: 'wrote from Lighthouse' });
				default:
					return _extends({}, defaultData, { author: userName, to: to, cc: cc, source: 'add comment', outgoing: true });
			}
		}),
		setMessageAndConversation: function setMessageAndConversation() {
			var conversation = void 0,
			    message = '';
			var comment = this.get('comment');
			var fullComment = Ember.getWithDefault(comment, 'text', '');
			var text = fullComment.match(BODY_REGEX) || '';

			if (text) {
				message = text[0];
				conversation = fullComment.substring(message.length);
			} else {
				message = fullComment;
			}

			return this.setProperties({
				message: message,
				conversation: conversation
			});
		},
		actions: {
			getAttachment: function getAttachment() {
				this.sendAction.apply(this, ['getAttachment'].concat(Array.prototype.slice.call(arguments)));
			},
			deleteAttachment: function deleteAttachment() {
				this.sendAction.apply(this, ['deleteAttachment'].concat(Array.prototype.slice.call(arguments)));
			}
		}
	});
});