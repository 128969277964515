define('tickets/controllers/tickets/unassigned', ['exports', 'tickets/mixins/controllers/filters', 'tickets/mixins/controllers/tickets/listing', 'tickets/mixins/controllers/tickets/bulkAssign'], function (exports, _filters, _listing, _bulkAssign) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_filters.default, _listing.default, _bulkAssign.default, {
		showAssigneeFilter: false,
		selectedRowIds: [],
		showFiltersReset: true,
		assigneeId: 'unassigned'
	});
});