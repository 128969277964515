define('tickets/components/additional-info-modal', ['exports', 'tickets/mixins/modal'], function (exports, _modal) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend(_modal.default, {
		tagName: '',
		additionalInfoModalName: 'additional-info-modal',
		labelText: '',
		modalContent: Ember.computed('modalContent', function () {
			return Array.isArray(this.modalContent) ? this.modalContent : [this.modalContent];
		}),
		actions: {
			showModal: function showModal() {
				this.showModal(this.get('additionalInfoModalName'));
			},
			hideModal: function hideModal() {
				this.hideModal(this.get('additionalInfoModalName'));
			}
		}
	});
});