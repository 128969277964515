define('tickets/controllers/tasks/reported', ['exports', 'tickets/mixins/controllers/tasks/listing'], function (exports, _listing) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_listing.default, {
		relatedName: 'Assignee',
		relatedAttribute: 'assignee.userName',
		selectTaskOptions: { parentRoute: 'tasks.reported' }
	});
});