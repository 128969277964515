define('tickets/services/groups', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		store: Ember.inject.service(),
		ajax: Ember.inject.service(),
		fetch: function fetch() {
			var _this = this;

			return this.ajax.request('/api/v1/groups/all').then(function (groups) {
				return _this.store.pushPayload(groups);
			});
		},
		getAll: function getAll() {
			return this.store.peekAll('group').sortBy('name').filter(function (group) {
				return group.isActive && group.get('department').get('isActive');
			});
		},
		getWhitelisted: function getWhitelisted() {
			return this.getAll().filter(function (group) {
				return group.isWhitelisted;
			});
		}
	});
});