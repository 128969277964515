define('tickets/utils/array', ['exports'], function (exports) {
  'use strict';

  /**
   * Casts value to array if it is not
   * @param {String|Number|Array} value Value should be cast to array
   * @returns {Array} Array
   */

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = castArray;
  function castArray(value) {
    return value instanceof Array ? value : [value];
  }
});