define('tickets/routes/reports', ['exports', 'tickets/mixins/routes/reports'], function (exports, _reports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_reports.default, {
		groups: Ember.inject.service(),
		categories: Ember.inject.service(),
		users: Ember.inject.service(),
		departments: Ember.inject.service(),
		tags: Ember.inject.service(),
		model: function model() {
			return Ember.RSVP.hash({
				groups: this.groups.getAll(),
				categories: this.categories.getAllRequestTypes(),
				users: this.users.getAll(),
				departments: this.departments.getAll(),
				transfers: this.groups.getAll(),
				permissions: this.get('users.self.role.permissions'),
				tags: this.tags.getAll(),
				ticketStatuses: this.store.peekAll('ticketStatus'),
				assignees: this.users.getAll(),
				sourceTypes: this.store.peekAll('ticketChannelType'),
				resolutionTypes: this.store.peekAll('resolutionType')
			});
		}
	});
});