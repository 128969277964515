define("tickets/services/sort-resolvers/simple", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (items, property) {
		return items ? items.sortBy(property) : [];
	};
});