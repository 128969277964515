define('tickets/routes/departments/edit/mailing-options', ['exports', 'tickets/mixins/routes/departments'], function (exports, _departments) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_departments.default, {
		model: function model() {
			var _modelFor = this.modelFor('departments.edit'),
			    department = _modelFor.department,
			    mailingOptions = _modelFor.mailingOptions;

			return Ember.RSVP.hash({
				department: department,
				mailingOptions: mailingOptions
			});
		}
	});
});