define('tickets/helpers/first', ['exports', '@abcum/ember-helpers/helpers/first'], function (exports, _first) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _first.default;
    }
  });
  Object.defineProperty(exports, 'first', {
    enumerable: true,
    get: function () {
      return _first.first;
    }
  });
});