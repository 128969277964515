define('tickets/services/change-logs/ticketRelation', ['exports', 'tickets/enums/ticketRelationTypes'], function (exports, _ticketRelationTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (_ref) {
		var relatedTicketId = _ref.newValue,
		    unrelatedTicketId = _ref.oldValue,
		    ticketRelationTypeId = _ref.ticketRelationTypeId,
		    attribute = _ref.attribute,
		    isDependent = _ref.isDependent,
		    ticket = _ref.ticket,
		    user = _ref.user,
		    createdAt = _ref.createdAt;

		var text = 'made changes: Ticket ';
		var ticketId = ticket.get('id');
		var isDuplicate = ticketRelationTypeId === _ticketRelationTypes.default.DUPLICATES;

		if (unrelatedTicketId) {
			text = 'unrelated Ticket ' + ticketId + ' from Ticket ' + unrelatedTicketId;
		} else if (!isDuplicate) {
			text += ticketId + ' is related to Ticket ' + relatedTicketId;
		} else if (isDuplicate && isDependent) {
			text += ticketId + ' duplicates Ticket ' + relatedTicketId;
		} else if (isDuplicate && !isDependent) {
			text += ticketId + ' is duplicated by Ticket ' + relatedTicketId;
		}

		return { text: text, user: user, createdAt: createdAt, attribute: attribute };
	};
});