define('tickets/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
		session: Ember.inject.service(),
		preheat: Ember.inject.service(),
		toast: Ember.inject.service(),
		users: Ember.inject.service(),
		sessionAuthenticated: function sessionAuthenticated() {
			return this.get('preheat').fetchAppData();
		},
		sessionInvalidated: function sessionInvalidated() {
			return this._super.apply(this, arguments);
		},
		beforeModel: function beforeModel() {
			return this.get('session.isAuthenticated') && this.get('preheat').fetchAppData();
		},

		actions: {
			error: function error(e) {
				// eslint-disable-next-line no-console
				console.error(e); //https://deprecations.emberjs.com/v3.x/#toc_use-console-rather-than-ember-logger
				return this.transitionTo('/not-found');
			}
		}
	});
});