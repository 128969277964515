define('tickets/controllers/reports', ['exports', 'ember-cli-file-saver/mixins/file-saver', 'tickets/mixins/controllers/reports', 'tickets/enums/reportTypes'], function (exports, _fileSaver, _reports, _reportTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var REPORTS_WITH_AVAILABLE_FIELDS_SELECT = [_reportTypes.REPORTS.allTickets];

	exports.default = Ember.Controller.extend(_fileSaver.default, _reports.default, {
		permissions: Ember.computed.alias('model.permissions'),
		reportTypes: Ember.computed('permissions', function () {
			var permissions = this.permissions.getEach('id');
			var mappedPermissions = _reportTypes.AVAILABLE_REPORT_TYPES.filter(function (reportType) {
				return permissions.includes(reportType.permission);
			});
			return [].concat(_toConsumableArray(mappedPermissions), _toConsumableArray(_reportTypes.REPORTS_WITHOUT_PERMISSIONS));
		}),
		itemsModelProperty: Ember.computed('reportType', function () {
			switch (this.reportType) {
				case _reportTypes.REPORTS.agent:
					return 'users';
				case _reportTypes.REPORTS.openTickets:
				case _reportTypes.REPORTS.allTickets:
				case _reportTypes.REPORTS.users:
					return 'groups';
				case _reportTypes.REPORTS.merchantReport:
					return 'merchant';
				default:
					return this.reportType;
			}
		}),
		itemsDisplayProperty: Ember.computed('reportType', function () {
			switch (this.reportType) {
				case _reportTypes.REPORTS.users:
				case _reportTypes.REPORTS.agent:
					return 'userName';
				default:
					return 'name';
			}
		}),
		itemsPlaceholder: Ember.computed('reportType', function () {
			switch (this.reportType) {
				case _reportTypes.REPORTS.categories:
					return 'request type';
				case _reportTypes.REPORTS.agent:
					return 'user';
				case _reportTypes.REPORTS.transfers:
				case _reportTypes.REPORTS.allTickets:
				case _reportTypes.REPORTS.openTickets:
					return 'groups';
				default:
					return this.reportType;
			}
		}),
		itemsSelectTypes: Ember.computed('reportType', function () {
			switch (this.reportType) {
				case _reportTypes.REPORTS.closedSameDayRatio:
					return 'selectUsersByDeptAndGroup';
				case _reportTypes.REPORTS.agent:
					return 'singleOption';
				case _reportTypes.REPORTS.users:
					return 'selectUsersByMultiSelectGroupsInDepartments';
				case _reportTypes.REPORTS.openTickets:
					return 'multiSelectGroupsInDepartments';
				case _reportTypes.REPORTS.groups:
					return 'multiSelectGroupsInDepartments';
				case _reportTypes.REPORTS.allTickets:
					return 'allTickets';
				case _reportTypes.REPORTS.merchantReport:
					return 'merchantIdInputField';
				default:
					return 'multiSelect';
			}
		}),
		isDateRangeReport: Ember.computed('reportType', function () {
			switch (this.reportType) {
				case _reportTypes.REPORTS.allTickets:
					return false;
				case _reportTypes.REPORTS.merchantReport:
					return 'merchant report';
				default:
					return true;
			}
		}),
		isReportWithAvailableFields: Ember.computed('reportType', function () {
			return REPORTS_WITH_AVAILABLE_FIELDS_SELECT.includes(this.reportType);
		})
	});
});