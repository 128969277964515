define('tickets/components/file-upload', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed;


	var INVALID_EXTENSIONS = ['.exe', '.bat'];

	exports.default = Ember.Component.extend({
		enableDownloadAllButton: false,
		attachButtonText: 'Attach file',
		downloadAllText: 'Download all files',
		fileSizeLimit: 15 * 1024 * 1024,
		fileSizeLimitMegabytes: computed('fileSizeLimit', function () {
			return this.get('fileSizeLimit') / (1024 * 1024);
		}),
		uploadError: function uploadError(error) {
			this.sendAction('onUploadError', error);
		},

		actions: {
			openUploadDialog: function openUploadDialog() {
				this.$('input[type=file]').click();
			},
			downloadAllAttachments: function downloadAllAttachments() {
				this.sendAction('downloadAllAttachments');
			},
			upload: function upload(e) {
				var file = e.target.files[0];

				this.$('input[type=file]').val(null);

				if (file.size === 0) {
					return this.uploadError('You can\'t upload empty file');
				}

				if (file.size > this.get('fileSizeLimit')) {
					return this.uploadError('The file size exceeds ' + this.get('fileSizeLimitMegabytes') + ' Mb limit');
				}

				var forbidenCharactersInFilename = file.name.split('').filter(function (char) {
					return char.charCodeAt(0) > 127;
				});
				if (forbidenCharactersInFilename.length > 0) {
					return this.uploadError('The file name contains forbidden characters "' + forbidenCharactersInFilename + '"');
				}

				if (INVALID_EXTENSIONS.some(function (extension) {
					return file.name.toLowerCase().includes(extension.toLowerCase());
				})) {
					return this.uploadError('You can\'t upload with the following extensions ' + INVALID_EXTENSIONS.join());
				}

				this.sendAction('onUpload', file);
			}
		}
	});
});