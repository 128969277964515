define('tickets/enums/reportTypes', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var REPORTS = exports.REPORTS = {
		agent: 'agent',
		users: 'users',
		groups: 'groups',
		transfers: 'transfers',
		categories: 'categories',
		openTickets: 'open-tickets',
		closedSameDayRatio: 'closed-same-day-ratio',
		allTickets: 'all-tickets',
		merchantReport: 'merchant',
		merchantCommentsReport: 'merchant-comments'
	};

	var REPORTS_WITHOUT_PERMISSIONS = exports.REPORTS_WITHOUT_PERMISSIONS = [{
		text: 'Open Tickets Report',
		value: REPORTS.openTickets
	}, {
		text: 'All Tickets Report',
		value: REPORTS.allTickets
	}, {
		text: 'Closed Same Day Ratio Report',
		value: REPORTS.closedSameDayRatio
	}];

	var AVAILABLE_REPORT_TYPES = exports.AVAILABLE_REPORT_TYPES = [{
		text: 'Groups Report',
		permission: 'reports-group',
		value: REPORTS.groups
	}, {
		text: 'Categories Report',
		permission: 'reports-category',
		value: REPORTS.categories
	}, {
		text: 'Agent Report',
		permission: 'reports-user',
		value: REPORTS.users
	}, {
		text: 'Transfers Report',
		permission: 'reports-transfers',
		value: REPORTS.transfers
	}, {
		text: 'Agent Activity Report',
		permission: 'reports-user',
		value: REPORTS.agent
	}, {
		text: 'Merchant Report',
		permission: 'reports-merchant',
		value: REPORTS.merchantReport
	}, {
		text: 'Source Type',
		permission: 'reports-source-type',
		value: REPORTS.allTickets
	}];
});