define('tickets/helpers/ix', ['exports', '@abcum/ember-helpers/helpers/ix'], function (exports, _ix) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ix.default;
    }
  });
  Object.defineProperty(exports, 'ix', {
    enumerable: true,
    get: function () {
      return _ix.ix;
    }
  });
});