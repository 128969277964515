define('tickets/controllers/general-report', ['exports', 'ember-cli-file-saver/mixins/file-saver', 'tickets/mixins/controllers/reports'], function (exports, _fileSaver, _reports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_fileSaver.default, _reports.default, {
		reportTypes: [{
			text: 'Categories Report',
			value: 'categories'
		}],
		itemsModelProperty: Ember.computed('reportType', function () {
			switch (this.reportType) {
				default:
					return this.reportType;
			}
		}),
		itemsDisplayProperty: Ember.computed('reportType', function () {
			switch (this.reportType) {
				default:
					return 'name';
			}
		}),
		itemsPlaceholder: Ember.computed('reportType', function () {
			switch (this.reportType) {
				case 'categories':
					return 'request type';
				default:
					return this.reportType;
			}
		}),
		itemsSelectTypes: Ember.computed('reportType', function () {
			switch (this.reportType) {
				default:
					return 'multiSelect';
			}
		})
	});
});