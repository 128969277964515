define('tickets/services/customers-tickets', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		store: Ember.inject.service(),
		getLatestByCustomerId: function getLatestByCustomerId(customerId) {
			return this.store.query('ticket', { customerId: customerId, type: 'latestByCustomerId' });
		}
	});
});