define('tickets/controllers/tickets/available', ['exports', 'tickets/mixins/controllers/listing'], function (exports, _listing) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_listing.default, {
		selectTicketRoute: 'tickets.edit',
		selectTicketOptions: { parentOptions: JSON.stringify({ prevListing: 'tickets.available' }), autoAssign: true },
		pageCount: Ember.computed.alias('model.tickets.meta.pageCount'),
		pageTitle: 'Available Tickets',
		columns: [{
			name: 'Ticket #',
			attribute: 'id',
			className: 'narrow-column'
		}, {
			name: 'Priority',
			template: 'ticket-priority',
			className: 'narrow-column'
		}, {
			name: 'Status',
			mapAttribute: 'ticketStatus.id',
			displayAttribute: 'ticketStatus.name',
			template: 'ticket-status-label'
		}, {
			name: 'Subject',
			attribute: 'subject'
		}, {
			name: 'Assignee',
			attribute: 'assignee.userName',
			template: 'assignee'
		}, {
			name: 'Customer',
			attribute: 'customerName'
		}, {
			name: 'Group',
			attribute: 'group.name'
		}, {
			name: 'Last updated',
			attribute: 'updatedAt',
			template: 'date-time-cell'
		}, {
			name: 'Created',
			attribute: 'createdAt',
			template: 'time-from-now'
		}]
	});
});