define('tickets/router', ['exports', 'tickets/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Router = Ember.Router.extend({ location: _environment.default.locationType, rootURL: _environment.default.rootURL });

	Router.map(function () {
		this.route('sign-in', { path: 'sign-in/:strategy' });
		this.route('sign-out');
		this.route('tickets', { path: '/' }, function () {
			this.route('all', { path: 'tickets/all' });
			this.route('available', { path: 'tickets/available' });
			this.route('my', { path: 'tickets/my' });
			this.route('unassigned', { path: 'tickets/unassigned' });
			this.route('create', { path: 'tickets/create' });
			this.route('edit', { path: 'tickets/:id' });
		});
		this.route('incoming-call');
		this.route('notifications');
		this.route('groups', function () {
			this.route('create');
			this.route('edit', { path: '/:id' }, function () {
				this.route('general');
				this.route('settings');
				this.route('email-templates');
			});
		});
		this.route('surveys');
		this.route('users', function () {
			this.route('edit', { path: '/:id' });
		});
		this.route('tasks', function () {
			this.route('edit', { path: '/:id' });
			this.route('reported');
		});
		this.route('reports');
		this.route('general-report');
		this.route('categories');
		this.route('departments', function () {
			this.route('create');
			this.route('edit', { path: '/:id' }, function () {
				this.route('general');
				this.route('mailing-options');
			});
		});
		this.route('tags', function () {
			this.route('create');
			this.route('edit', { path: '/:id' }, function () {
				this.route('general');
			});
		});
		this.route('roles', function () {
			this.route('edit', { path: '/:id' });
		});
		this.route('preheat', { path: 'preheat/:route' });
		this.route('help');
		this.route('user-deactivated');
		this.route('not-found', { path: '*path' });
		this.route('manage-notifications');
	});

	exports.default = Router;
});