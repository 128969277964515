define('tickets/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.RESTSerializer.extend({
		keyForRelationship: function keyForRelationship(key, relationship) {
			if (relationship === 'belongsTo') {
				return key + 'Id';
			}

			return key;
		}
	});
});