define('tickets/services/change-logs/watchers', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (log) {
		var oldValues = JSON.parse(log.get('oldValue'));
		var newValues = JSON.parse(log.get('newValue'));

		return {
			displayAttribute: log.get('displayAttribute'),
			createdAt: log.get('createdAt'),
			user: log.get('user'),
			oldValue: Array.isArray(oldValues) ? oldValues.join(', ') : oldValues,
			newValue: Array.isArray(newValues) ? newValues.join(', ') : newValues
		};
	};
});