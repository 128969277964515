define('tickets/models/global-setting', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		key: _emberData.default.attr('string'),
		value: _emberData.default.attr()
	});
});