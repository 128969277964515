define('tickets/utils/timeRangesBuilder', ['exports', 'moment'], function (exports, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = buildTimeRanges;

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	/**
  * Builds time ranges for date-time input
  * @param {Array} ranges - array of time ranges: [title, count, period]
  * @returns {Array} Array of time range objects
  */
	function buildTimeRanges(ranges) {
		var now = (0, _moment.default)();
		var endDate = now.clone().endOf('day');
		var startOfTheDay = now.clone().startOf('day');

		return ranges.map(function (_ref) {
			var _ref2 = _slicedToArray(_ref, 2),
			    title = _ref2[0],
			    dateShift = _ref2[1];

			return {
				title: title,
				startDate: startOfTheDay.clone().add(dateShift),
				endDate: endDate
			};
		});
	}
});