define('tickets/controllers/tags/create', ['exports', 'tickets/mixins/controllers/routing-tabs/wrapper', 'tickets/mixins/controllers/tags/common'], function (exports, _wrapper, _common) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_wrapper.default, _common.default, {
		nextStepRouteName: 'tags.edit.general',
		tabs: [{
			title: 'General Information',
			route: 'tags.create'
		}]
	});
});