define('tickets/components/dropdown-select', ['exports', 'tickets/components/input-field', 'tickets/services/sort-resolvers'], function (exports, _inputField, _sortResolvers) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	var DEFAULT_MAP_PROPERTY = 'id';
	var DEFAULT_SORT_RESOLVER = 'simple';

	exports.default = _inputField.default.extend({
		tagName: 'div',
		classNames: ['ui input'],
		focused: false,
		addEmptyOption: false,
		refreshAfterRender: false,
		autoSelectSingleValue: false,
		emptyOptionText: '',
		fullTextSearch: 'exact',
		sortResolver: '',
		className: 'search selection scrolling',
		items: [],
		disabledItems: [],
		disableSorting: false,
		sortedItems: Ember.computed('items.[]', 'displayProperty', function () {
			var sort = _sortResolvers.default[this.sortResolver || DEFAULT_SORT_RESOLVER];

			return this.disableSorting ? this.get('items') : sort(this.get('items'), this.get('displayProperty'));
		}),
		didRender: function didRender() {
			if (this.refreshAfterRender) {
				this.$('.ui.dropdown').dropdown('refresh');
			}

			if (this.autoSelectSingleValue && this.sortedItems.length === 1) {
				this.set('selected', this.sortedItems.get('firstObject'));
			}
		},
		isValueChanged: function isValueChanged(value) {
			var mapProperty = this.get('mapProperty') || DEFAULT_MAP_PROPERTY;
			var selected = this.get('selected');
			var current = value;

			if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object' && value !== null) {
				selected = this.get('selected.' + mapProperty);
				current = value[mapProperty];
			}

			return selected !== current;
		},

		actions: {
			onDropdownChange: function onDropdownChange(value) {
				if (!this.isValueChanged(value)) {
					return;
				}

				if (this.get('onChange')) {
					this.sendAction('onChange', value);
				} else {
					this.set('selected', value);
				}
			}
		}
	});
});