define('tickets/mixins/controllers/app-menu', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		users: Ember.inject.service(),
		counter: Ember.inject.service(),
		permissions: Ember.inject.service(),
		sideMenuItems: Ember.computed('users.{self,brand.id,permissions.[]}', 'counter.counter', function () {
			var actualMenuItems = this.buildTSMenuItems();
			return actualMenuItems.map(function (block) {
				return block.compact();
			});
		}),
		canBuildLink: function canBuildLink(routeName) {
			var owner = Ember.getOwner(this);
			var route = owner.lookup('route:' + routeName);

			var requiredPermissions = route ? route.requiredPermissions : null;
			return this.permissions.can(requiredPermissions);
		},
		buildDefaultLink: function buildDefaultLink(presetValue, routeName) {
			if (!this.canBuildLink(routeName)) {
				return null;
			}
			return Object.assign(presetValue, {
				params: [routeName, {
					isQueryParams: true,
					values: {
						page: 1
					}
				}]
			});
		},
		buildTicketLink: function buildTicketLink(customParams, listingName) {
			var routeName = 'tickets.' + listingName;

			if (!this.canBuildLink(routeName)) {
				return null;
			}

			return Object.assign(customParams, {
				params: [routeName, {
					isQueryParams: true,
					values: {
						presetFilters: true,
						page: 1
					}
				}]
			});
		},
		buildTSMenuItems: function buildTSMenuItems() {
			var _get = this.get('counter'),
			    counter = _get.counter;

			return [[this.buildTicketLink({ displayName: 'All tickets', counter: counter.tickets }, 'all'), this.buildDefaultLink({ displayName: 'Available tickets' }, 'tickets.available'), this.buildTicketLink({ displayName: 'My tickets' }, 'my'), this.buildTicketLink({ displayName: 'Unassigned' }, 'unassigned')], [this.buildDefaultLink({ displayName: 'Assigned Tasks', counter: counter.tasks }, 'tasks.index'), this.buildDefaultLink({ displayName: 'Reported Tasks' }, 'tasks.reported')], [this.buildDefaultLink({ displayName: 'Departments' }, 'departments.index'), this.buildDefaultLink({ displayName: 'Categories' }, 'categories'), this.buildDefaultLink({ displayName: 'Groups' }, 'groups.index'), this.buildDefaultLink({ displayName: 'Surveys' }, 'surveys'), this.buildDefaultLink({ displayName: 'Tags' }, 'tags.index'), this.buildDefaultLink({ displayName: 'Users' }, 'users.index'), this.buildDefaultLink({ displayName: 'Roles' }, 'roles.index')], [this.buildDefaultLink({
				displayName: 'Notifications',
				counter: counter.notifications
			}, 'notifications'), this.buildDefaultLink({ displayName: 'Reports' }, 'reports')], [{ displayName: 'Help', params: ['help'] }], [{ displayName: 'Sign Out', params: ['sign-out'] }]];
		}
	});
});