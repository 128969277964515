define('tickets/components/brand-logo', ['exports', 'tickets/enums/userTypes'], function (exports, _userTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed,
	    Component = Ember.Component;


	var SHIFT4_LOGO = 'shift4-payments-llc';

	exports.default = Component.extend({
		user: null,
		path: 'logo',
		classNames: '',
		getLogoUrl: function getLogoUrl(logoName) {
			return './images/' + this.path + '/' + logoName + '.png';
		},

		brandLogoUrl: computed('user.userTypeId', 'user.brand.name', function () {
			if (this.get('user.userTypeId') === _userTypes.default.GOOGLE) {
				return this.getLogoUrl(SHIFT4_LOGO);
			}

			var brandName = this.get('user.brand.name');

			if (!brandName) {
				return null;
			}

			var logoName = brandName.replace(/[^A-Za-z0-9]+/g, '').dasherize();

			return this.getLogoUrl(logoName);
		})
	});
});