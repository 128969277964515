define('tickets/routes/preheat', ['exports', 'tickets/mixins/routes/authenticated'], function (exports, _authenticated) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticated.default, {
		preheat: Ember.inject.service(),
		model: function model() {
			return this.preheat.fetchAppData();
		},
		afterModel: function afterModel(model, transition) {
			transition.abort();
			this.transitionTo(transition.params.preheat.route);
		}
	});
});