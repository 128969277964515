define('tickets/routes/tickets/available', ['exports', 'tickets/mixins/routes/authenticated', 'tickets/mixins/routes/tickets/deferred-customers'], function (exports, _authenticated, _deferredCustomers) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticated.default, _deferredCustomers.default, {
		queryParams: {
			page: {
				refreshModel: true
			},
			limit: {
				refreshModel: true
			}
		},
		model: function model(_ref) {
			var page = _ref.page,
			    limit = _ref.limit;

			return Ember.RSVP.hash({ tickets: this.store.query('ticket', { page: page, limit: limit, type: 'available' }) });
		}
	});
});