define('tickets/resources/dealersAdditionalInfo', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		supportQueue: {
			rm: [{
				header: 'Support Queue Hours of operation:',
				paragraphs: ['RM tech support department is open on business days (Monday-Friday) between 9:00 am and 8:00 pm Eastern Standard Time, except holidays. If you wish to tell us about problem appeared, please click on the “Create Ticket” button below. RM guarantees attention to all requests posted by 7:00 pm EST within one hour.', 'If you need technical support after business hours, please call the after hours support number 866-263-9885. Tech support staff does not monitor the help desk queue after business hours.']
			}, {
				header: 'Note Regarding International Calls',
				paragraphs: ['RM technicians can only place calls within the United States and Canada. If you are calling from outside the United  States 	or Canada, you may place your request via Help Desk portal or by calling 301-445-6100 and RM Technicians will respond via email.']
			}],
			fp: [{
				header: 'Support Queue Hours of operation:',
				paragraphs: ['Future POS tech support department is open on business days (Monday-Friday) between 8:00 am and 7:00 pm Eastern Standard Time, except holidays. If you wish to submit a new ticket on your own, please click on the "Create Ticket" button below.', 'If you need emergency technical support after business hours, please call the main line 724-285-3636 x 2 and you will be directed to the on-call technician. Tech support staff does not monitor the help desk ticket queue after business hours.']
			}, {
				header: 'Note Regarding International Calls',
				paragraphs: ['Future POS technicians can only place calls within the United States and Canada. If you wish to submit a ticket from outside the United States or Canada, please create a new case via the ticketing system as aforementioned.']
			}]
		}
	});
});