define('tickets/validators/assignee', ['exports', 'ember-cp-validations/validators/base', 'tickets/enums/ticketStatuses'], function (exports, _base, _ticketStatuses) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Assignee = _base.default.extend({
		validationMessage: 'Set assignee before resolving ticket',
		validate: function validate(value, options, model) {
			var ticketStatus = model.get('ticketStatus.id');
			var ticketResolvedWithoutAssignee = ticketStatus === _ticketStatuses.default.RESOLVED && !value.get('id');

			return ticketResolvedWithoutAssignee ? this.validationMessage : true;
		}
	});

	Assignee.reopenClass({
		getDependentsFor: function getDependentsFor() {
			return ['model.ticketStatus'];
		}
	});

	exports.default = Assignee;
});