define('tickets/services/categories', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		store: Ember.inject.service(),
		getAll: function getAll() {
			return this.store.peekAll('category').rejectBy('parent.id').filterBy('isActive').sortBy('name');
		},
		getByParentId: function getByParentId(parentId) {
			return parentId ? this.store.peekRecord('category', parentId).get('children').filterBy('isActive').sortBy('name') : [];
		},
		getAllRequestTypes: function getAllRequestTypes() {
			return this.store.peekAll('category').rejectBy('parent.id').sortBy('name');
		},
		getAllByParentId: function getAllByParentId(parentId) {
			return parentId ? this.store.peekRecord('category', parentId).get('children').sortBy('name') : [];
		}
	});
});