define('tickets/mixins/controllers/tickets/listing', ['exports', 'tickets/mixins/controllers/errors', 'tickets/mixins/controllers/listing', 'tickets/utils/timeRangesBuilder', 'tickets/enums/ticketsDisplayTypes'], function (exports, _errors, _listing, _timeRangesBuilder, _ticketsDisplayTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	var MIN_THREE_INTEGERS_REGEX = /^[0-9]{3,}$/;
	var MIN_THREE_INTEGERS_VALIDATION_MESSAGE = 'Numbers only allowed (at least 3 symbols)';
	var SELECT_INPUT_QUERY_PARAMS = ['id', 'mId', 'email', 'phone', 'serialId', 'officeId', 'officeName', 'customerName', 'fullTextSearch'];
	var QUERY_PARAMS = [].concat(SELECT_INPUT_QUERY_PARAMS, ['presetFilters', 'ticketStatusId', 'assigneeId', 'createdAt', 'direction', 'groupId', 'column', 'tagId', 'requestTypeId', 'productId', 'categoryId', 'subCategoryId', 'originatingDepartment', 'sourceTypeId', 'resolutionTypeId', 'ticketsDisplayType']);

	var CATEGORY_HIERARCHY = {
		requestTypeId: {
			child: 'productId',
			grandChild: 'categoryId'
		},
		productId: {
			child: 'categoryId',
			grandChild: 'subCategoryId'
		},
		categoryId: {
			child: 'subCategoryId'
		}
	};

	exports.default = Ember.Mixin.create(_listing.default, _errors.default, {
		users: Ember.inject.service(),
		filterStorage: Ember.inject.service(),
		deferredCustomers: Ember.inject.service(),
		categoriesService: Ember.inject.service('categories'),
		id: '',
		mId: '',
		phone: '',
		email: '',
		serialId: '',
		customerName: '',
		createdAt: [],
		officeId: '',
		officeName: '',
		fullTextSearch: '',
		tagId: [],
		groupId: [],
		assigneeId: [],
		ticketStatusId: [],
		resolutionTypeId: [],
		originatingDepartment: [],
		requestTypeId: '',
		productId: '',
		categoryId: '',
		subCategoryId: '',
		sourceTypeId: '',
		direction: 'desc',
		column: 'updatedAt',
		presetFilters: false,
		queryParams: QUERY_PARAMS,
		selectTicketRoute: 'tickets.edit',
		archivesAdded: Ember.computed('ticketsDisplayType', function () {
			return this.get('ticketsDisplayType') === _ticketsDisplayTypes.default.ALL_TICKETS;
		}),
		isContentLoading: Ember.computed.alias('model.tickets.isUpdating'),
		metaErrors: Ember.computed.alias('model.tickets.meta.errors'),
		columns: Ember.computed('fullTextSearch', function () {
			return [{
				name: 'Ticket #',
				attribute: 'id',
				sortParam: 'id',
				className: 'narrow-column'
			}, {
				name: 'Source Type',
				attribute: 'channel.ticketChannelTypeName',
				className: 'narrow-column'
			}, {
				name: 'Priority',
				template: 'ticket-priority',
				sortParam: 'priorityTypeId',
				className: 'narrow-column'
			}, {
				name: 'Status',
				mapAttribute: 'ticketStatus.id',
				displayAttribute: 'ticketStatus.name',
				template: 'ticket-status-label',
				sortParam: 'ticketStatusId'
			}, {
				name: 'Resolution Reason',
				mapAttribute: 'resolutionType.id',
				displayAttribute: 'resolutionType.name',
				template: 'ticket-resolution-status-label',
				sortParam: 'resolutionTypeId'
			}, {
				name: 'Subject',
				attribute: 'subject',
				className: 'large-column',
				template: 'large-text'
			}, {
				name: 'Assignee',
				attribute: 'assignee.userName',
				template: 'assignee',
				sortParam: 'assignee'
			}, {
				name: 'Customer',
				attribute: 'customer.customerName'
			}, {
				name: 'Customer ID',
				attribute: 'customer.customerId'
			}, {
				name: 'Customer Rank',
				attribute: 'customer.rank',
				className: 'narrow-column'
			}, {
				name: 'Tags',
				attribute: 'tags',
				property: 'name',
				template: 'relations'
			}, {
				name: 'Originating Department',
				attribute: 'originatingDepartmentName'
			}, {
				name: 'Department',
				attribute: 'department.name',
				sortParam: 'department'
			}, {
				name: 'Group',
				attribute: 'group.name',
				sortParam: 'group'
			}, {
				name: 'Description',
				attribute: 'description',
				template: 'large-text'
			}, {
				name: 'Action Plan',
				attribute: 'actionPlan',
				template: 'large-text'
			}, {
				name: 'Case Summary',
				attribute: 'summary',
				template: 'large-text'
			}, {
				name: 'Manager Notes',
				attribute: 'managerNotes',
				template: 'large-text'
			}, {
				name: 'Resolve Date',
				attribute: 'resolvedAt',
				template: 'date-time-cell',
				sortParam: 'resolvedAt'
			}, {
				name: 'Last updated',
				attribute: 'updatedAt',
				template: 'date-time-cell',
				sortParam: 'updatedAt'
			}, {
				name: 'Created',
				attribute: 'createdAt',
				template: 'time-from-now',
				sortParam: 'createdAt'
			}];
		}),
		selectInputFilterOptions: [{
			value: 'id',
			name: 'Ticket Id',
			regex: /^[0-9]*$/,
			validationMessage: 'Numbers only allowed'
		}, { value: 'mId', name: 'MID' }, { value: 'officeId', name: 'Office Id' }, { value: 'officeName', name: 'Office Name' }, { value: 'customerName', name: 'DBA name' }, { value: 'email', name: 'Email' }, {
			value: 'phone',
			name: 'Phone',
			regex: MIN_THREE_INTEGERS_REGEX,
			validationMessage: MIN_THREE_INTEGERS_VALIDATION_MESSAGE
		}, {
			value: 'serialId',
			name: 'Serial #',
			regex: MIN_THREE_INTEGERS_REGEX,
			validationMessage: MIN_THREE_INTEGERS_VALIDATION_MESSAGE
		}, { value: 'fullTextSearch', name: 'Text' }],
		listingType: Ember.computed('target.currentRouteName', function () {
			return this.get('target.currentRouteName').split('.').pop();
		}),
		pageTitle: Ember.computed('listingType', function () {
			return Ember.String.capitalize(this.get('listingType'));
		}),
		selectTicketOptions: Ember.computed('target.currentRouteName', function () {
			return { parentOptions: JSON.stringify({ prevListing: this.target.currentRouteName }) };
		}),
		selectInputKey: Ember.computed.apply(undefined, SELECT_INPUT_QUERY_PARAMS.concat([function () {
			var _this = this;

			var preselectedQuery = SELECT_INPUT_QUERY_PARAMS.find(function (query) {
				return _this.get(query);
			});

			return preselectedQuery || 'id';
		}])),
		allCategories: Ember.computed('requestTypeId', 'productId', 'categoryId', function () {
			var requestTypeId = this.get('requestTypeId');
			var productId = this.get('productId');
			var categoryId = this.get('categoryId');
			return {
				products: this.categoriesService.getAllByParentId(requestTypeId),
				categories: this.categoriesService.getAllByParentId(productId),
				subCategories: this.categoriesService.getAllByParentId(categoryId)
			};
		}),
		filterObserver: Ember.observer('requestTypeId', 'productId', 'categoryId', function (instance, name) {
			var _setProperties;

			var grandChild = CATEGORY_HIERARCHY[name].grandChild || '';
			var child = CATEGORY_HIERARCHY[name].child;
			this.setProperties((_setProperties = {}, _defineProperty(_setProperties, child, ''), _defineProperty(_setProperties, grandChild, ''), _setProperties));
		}),
		filters: Ember.computed.apply(undefined, _toConsumableArray(QUERY_PARAMS).concat([function () {
			var assigneeFilter = this.get('showAssigneeFilter') ? [{
				type: 'multi-select',
				property: 'assigneeId',
				placeholder: 'Assignee',
				dynamicPlaceholder: true,
				items: this.get('assignees'),
				sortResolver: 'assignee',
				displayProperty: 'userName',
				mapProperty: 'id',
				value: this.get('assigneeId')
			}] : [];

			var productHidden = !this.get('requestTypeId');
			var categoriesHidden = productHidden || !this.get('productId');
			var subCategoriesHidden = categoriesHidden || !this.get('categoryId');

			return [{
				type: 'select-input',
				mapProperty: 'value',
				displayProperty: 'name',
				placeholder: 'Press enter to search',
				dropdownValue: this.get('selectInputFilterOptions'),
				property: this.get('selectInputKey'),
				value: this.get(this.selectInputKey),
				regex: /^[^]{3,}$/,
				validationMessage: 'This field should contain at least 3 symbols'
			}].concat(assigneeFilter, [{
				type: 'multi-select',
				property: 'originatingDepartment',
				placeholder: 'Originating Dept.',
				dynamicPlaceholder: true,
				items: this.get('model.departments'),
				displayProperty: 'name',
				mapProperty: 'id',
				value: this.get('originatingDepartment')
			}, {
				type: 'multi-select-with-subitems',
				property: 'groupId',
				placeholder: 'Depts./Groups',
				dynamicPlaceholder: true,
				groupBy: 'department',
				parentDisplayProperty: 'name',
				items: this.get('model.groups'),
				displayProperty: 'name',
				value: this.get('groupId')
			}, {
				type: 'multi-select',
				property: 'ticketStatusId',
				placeholder: 'Ticket Status',
				dynamicPlaceholder: true,
				items: this.get('model.ticketStatuses'),
				displayProperty: 'name',
				mapProperty: 'id',
				value: this.get('ticketStatusId')
			}, {
				type: 'multi-select',
				property: 'resolutionTypeId',
				placeholder: 'Resolution Reason',
				dynamicPlaceholder: true,
				items: this.get('model.resolutionTypes'),
				displayProperty: 'name',
				mapProperty: 'id',
				value: this.get('resolutionTypeId')
			}, {
				type: 'multi-select',
				property: 'tagId',
				placeholder: 'Tags',
				dynamicPlaceholder: true,
				items: this.get('tags'),
				sortResolver: 'tag',
				displayProperty: 'name',
				mapProperty: 'id',
				value: this.get('tagId')
			}, {
				type: 'date-range',
				property: 'createdAt',
				placeholder: 'Created During',
				ranges: (0, _timeRangesBuilder.default)([['Today'], ['3 days', { days: -2 }], ['Week', { days: -6 }], ['Month', { month: -1, days: 1 }]]),
				value: this.get('createdAt')
			}, {
				type: 'dropdown',
				property: 'sourceTypeId',
				placeholder: 'Source type',
				emptyOptionText: 'Source type',
				dynamicPlaceholder: true,
				displayProperty: 'name',
				sortResolver: 'sourceTypeId',
				addEmptyOption: true,
				items: this.get('model.ticketChannelTypes'),
				mapProperty: 'id',
				value: this.get('sourceTypeId')
			}, {
				type: 'dropdown',
				property: 'requestTypeId',
				placeholder: 'Request type',
				emptyOptionText: 'Request type',
				displayProperty: 'name',
				addEmptyOption: true,
				items: this.get('model.requestTypes'),
				mapProperty: 'id',
				value: this.get('requestTypeId')
			}, {
				type: 'dropdown',
				property: 'productId',
				placeholder: 'Product/Service',
				emptyOptionText: 'Product/Service',
				displayProperty: 'name',
				addEmptyOption: true,
				items: this.get('allCategories.products'),
				mapProperty: 'id',
				value: this.get('productId'),
				hidden: productHidden
			}, {
				type: 'dropdown',
				property: 'categoryId',
				placeholder: 'Categories',
				emptyOptionText: 'Categories',
				dynamicPlaceholder: true,
				addEmptyOption: true,
				groupBy: 'name',
				items: this.get('allCategories.categories'),
				displayProperty: 'name',
				mapProperty: 'id',
				value: this.get('categoryId'),
				hidden: categoriesHidden
			}, {
				type: 'dropdown',
				property: 'subCategoryId',
				placeholder: 'Sub-Categories',
				emptyOptionText: 'Sub-Categories',
				dynamicPlaceholder: true,
				addEmptyOption: true,
				groupBy: 'name',
				items: this.get('allCategories.subCategories'),
				displayProperty: 'name',
				mapProperty: 'id',
				value: this.get('subCategoryId'),
				hidden: subCategoriesHidden
			}]);
		}])),
		showFiltersReset: false,
		sortQuery: Ember.computed('column', 'direction', function () {
			return {
				column: this.get('column'),
				direction: this.get('direction')
			};
		}),
		pageCount: Ember.computed.alias('model.tickets.meta.pageCount'),
		assignees: Ember.computed('model.assignees', function () {
			var assignees = this.get('model.assignees').toArray();

			return [{ id: 'unassigned', userName: 'Unassigned' }].concat(_toConsumableArray(assignees));
		}),
		tags: Ember.computed('model.tags', function () {
			var tags = this.get('model.tags').toArray();
			return [{ id: 'none', name: 'Untagged' }].concat(_toConsumableArray(tags));
		}),
		setInactiveInputFilterQueryParams: function setInactiveInputFilterQueryParams(filter) {
			var _this2 = this;

			if (SELECT_INPUT_QUERY_PARAMS.includes(filter)) {
				this.set('selectInputKey', filter);
				SELECT_INPUT_QUERY_PARAMS.without(filter).forEach(function (query) {
					return _this2.set(query, '');
				});
			}
		},

		actions: {
			updateFilter: function updateFilter(filter, value) {
				var _this3 = this;

				Ember.run.next(function () {
					var _this3$setProperties;

					_this3.setProperties((_this3$setProperties = {}, _defineProperty(_this3$setProperties, filter, value), _defineProperty(_this3$setProperties, 'page', 1), _this3$setProperties));
					_this3.setInactiveInputFilterQueryParams(filter);
				});
			},
			updateSort: function updateSort(_ref) {
				var _this4 = this;

				var column = _ref.column,
				    direction = _ref.direction;

				Ember.run.next(function () {
					return _this4.setProperties({ column: column, direction: direction, page: 1 });
				});
			},
			resetFilter: function resetFilter(filter) {
				this.set(filter); ///TODO check if this is used anywhere
			},
			handleArchivedEnvironment: function handleArchivedEnvironment(value) {
				var _this5 = this;

				Ember.run.next(function () {
					_this5.setProperties({
						ticketsDisplayType: value ? _ticketsDisplayTypes.default.ALL_TICKETS : _ticketsDisplayTypes.default.NOT_ARCHIVED_TICKETS,
						page: 1,
						archivesAdded: value
					});
				});
			}
		}
	});
});