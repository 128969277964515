define('tickets/components/relations-section', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		relations: [],
		relationsSortParams: ['ticketRelationTypeId.value:asc', 'createdAt:asc'],
		sortedRelations: Ember.computed.sort('relations', 'relationsSortParams'),
		relatedRowId: 'relatedTicket.id',
		relatedTicketURL: 'tickets.edit',
		relatedColumns: [{
			template: 'delete-row',
			className: 'delete-row-column'
		}, {
			name: 'Relation',
			attribute: 'ticketRelationTypeId.name',
			className: 'auto-width-column'
		}, {
			name: 'Ticket #',
			attribute: 'relatedTicket.id',
			className: 'auto-width-column'
		}, {
			name: 'Status',
			mapAttribute: 'relatedTicket.ticketStatus.id',
			displayAttribute: 'relatedTicket.ticketStatus.name',
			template: 'ticket-status-label',
			className: 'auto-width-column'
		}, {
			name: 'Subject',
			attribute: 'relatedTicket.subject',
			className: 'auto-width-column'
		}, {
			name: 'Customer',
			attribute: 'relatedTicket.customerName',
			className: 'auto-width-column'
		}],
		actions: {
			showModal: function showModal() {
				this.sendAction('showModal');
			},
			deleteRelation: function deleteRelation(value) {
				this.sendAction('deleteRelation', value);
			}
		}
	});
});