define('tickets/services/change-logs/tag', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (log, store) {
		var oldValueIds = JSON.parse(log.get('oldValue'));
		var newValueIds = JSON.parse(log.get('newValue'));
		var attribute = log.get('attribute');

		return {
			displayAttribute: log.get('displayAttribute'),
			createdAt: log.get('createdAt'),
			user: log.get('user'),
			oldValue: getTagNames(oldValueIds, store, attribute),
			newValue: getTagNames(newValueIds, store, attribute)
		};
	};

	/**
  * Get tag names by given ids
  * @param {String} ids tags ids
  * @param {Object} store Ember data store
  * @param {String} attribute name of column
  * @returns {String} Tags names
  */
	function getTagNames(ids, store, attribute) {
		var mapRecord = function mapRecord(id) {
			return store ? store.peekRecord(attribute, id).get('name') : id;
		};
		return Array.isArray(ids) ? ids.map(mapRecord).join(', ') : '';
	} /**
    * Formats tag change log
    * @param {Object} log Change log
    * @param {Object|null} store Ember store
    * @returns {Object} Ticket requested change log
    */
});