define('tickets/helpers/reject', ['exports', '@abcum/ember-helpers/helpers/reject'], function (exports, _reject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _reject.default;
    }
  });
  Object.defineProperty(exports, 'reject', {
    enumerable: true,
    get: function () {
      return _reject.reject;
    }
  });
});