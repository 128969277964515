define('tickets/utils/url', ['exports', 'qs', 'tickets/utils/clearFalsyValues'], function (exports, _qs, _clearFalsyValues) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = prepareGetRequestPath;


  /**
   * Clears falsy values in queryParams object
   * and stringifies arrays in comma-separated format
   * @param {String} url Request url
   * @param {Object} options Request options
   * @returns {Object} Request url with stringified query
   */
  function prepareGetRequestPath(url, options) {
    var optionsData = options && options.data || {};
    var truthyData = (0, _clearFalsyValues.default)(optionsData);
    var query = _qs.default.stringify(truthyData, { arrayFormat: 'comma', addQueryPrefix: true });

    return '' + url + query;
  }
});