define('tickets/mixins/modal', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var $ = Ember.$;
	exports.default = Ember.Mixin.create({
		showModal: function showModal(modalClass) {
			var currentModal = $('.ui.modal.' + modalClass);
			currentModal.modal({
				closable: false,
				observeChanges: true
			}).modal('show');

			if (currentModal.length > 1) {
				currentModal[1].remove();
			}
		},
		hideModal: function hideModal(modalClass) {
			$('.ui.modal.' + modalClass).modal('hide');
		}
	});
});