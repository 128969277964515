define('tickets/enums/requiredPermissions', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		TICKETING_SYSTEM: ['access ticketing-system'],
		GROUPS: [['access ticketing-system', 'read group'], ['access ticketing-system', 'write group']],
		DEPARTMENTS: [['access ticketing-system', 'read department'], ['access ticketing-system', 'write department']],
		TAGS: [['access ticketing-system', 'read tag'], ['access ticketing-system', 'write tag']],
		CATEGORIES: [['access ticketing-system', 'read categories'], ['access ticketing-system', 'write categories']],
		ROLES: [['access ticketing-system', 'read role'], ['access ticketing-system', 'write role']],
		USERS: [['access ticketing-system', 'read user'], ['access ticketing-system', 'write user']],
		TICKETS_LISTING_UNASSIGNED: ['access ticketing-system', 'access unassigned tickets-listing'],
		REPORTS: ['access ticketing-system', 'manage report'],
		SURVEYS: [['access ticketing-system', 'read survey'], ['access ticketing-system', 'write survey']]
	});
});