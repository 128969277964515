define('tickets/services/change-logs/relation', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (log) {
		return {
			newValue: log.get('newValue'),
			user: log.get('user'),
			type: log.get('type'),
			displayAttribute: log.get('displayAttribute'),
			createdAt: log.get('createdAt'),
			template: 'relation'
		};
	};
});