define('tickets/components/textarea-field', ['exports', 'tickets/components/input-field'], function (exports, _inputField) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var DEFAULT_CLASS = 'textarea-component-field';

	exports.default = _inputField.default.extend({
		resize: false,
		rows: 3,
		class: Ember.computed('resize', function () {
			return this.resize ? DEFAULT_CLASS + ' resize' : DEFAULT_CLASS;
		}),
		actions: {
			keyUp: function keyUp() {
				this.sendAction.apply(this, ['keyUp'].concat(Array.prototype.slice.call(arguments)));
			}
		}
	});
});