define('tickets/enums/ticketRelations', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		RELATED: { name: 'relates to', value: 100 },
		DUPLICATED: { name: 'is duplicated by', value: 200 },
		DUPLICATES: { name: 'duplicates', value: 300 }
	});
});