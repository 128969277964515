define('tickets/services/counter', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var COUNTER_UPDATE_INTERVAL = 30000;

	exports.default = Ember.Service.extend({
		ajax: Ember.inject.service(),
		filterStorage: Ember.inject.service(),
		pageVisibility: Ember.inject.service(),
		ticketsVisitedAt: new Date().toISOString(),
		counter: {},
		renewTicketsVisit: function renewTicketsVisit() {
			this.set('ticketsVisitedAt', new Date().toISOString());
		},
		fetchCounter: function fetchCounter() {
			var _this = this;

			if (!this.pageVisibility.isVisible()) {
				return;
			}

			return this.get('ajax').request('/api/v1/counter', {
				data: {
					timestamp: this.get('ticketsVisitedAt'),
					filter: this.get('filterStorage.all')
				}
			}).then(function (counter) {
				return _this.set('counter', counter);
			});
		},
		startPolling: function startPolling() {
			var _this2 = this;

			this.fetchCounter();
			setInterval(function () {
				return _this2.fetchCounter();
			}, COUNTER_UPDATE_INTERVAL);
		},
		updateTimeAndCounter: function updateTimeAndCounter() {
			this.renewTicketsVisit();
			this.fetchCounter();
		}
	});
});