define('tickets/components/conversation-section', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Component.extend({
		ticket: null,
		newInternalComment: null,
		newExternalComment: null,
		disableInternalComments: false,
		disablePublicCommentRecipientsEdit: false,
		hiddenCommentSources: [],
		originEmail: Ember.computed('ticket.channel.details.originEmail', 'ticket.group.id', function () {
			var details = this.get('ticket.channel.details') || {};
			var originEmail = details.originEmail;

			var groupEmails = this.get('ticket.group.link.emails');

			var originEmails = [];

			if (originEmail) {
				originEmails.push(originEmail);
			}

			if (groupEmails) {
				originEmails.push.apply(originEmails, _toConsumableArray(groupEmails));
			}

			return originEmails;
		}),
		didRender: function didRender() {
			this._super.apply(this, arguments);

			this.$('.tabular.menu .item').tab();
		},

		actions: {
			addInternalComment: function addInternalComment(comment) {
				this.sendAction('addInternalComment', comment);
			},
			addExternalComment: function addExternalComment(comment) {
				this.sendAction('addExternalComment', comment);
			},
			getTabData: function getTabData(modelName) {
				this.sendAction('getTabData', modelName);
			},
			upload: function upload(file, isForNewComment) {
				this.sendAction('upload', file, isForNewComment);
			},
			uploadError: function uploadError(error) {
				this.sendAction('uploadError', error);
			},
			clearNewCommentAttachments: function clearNewCommentAttachments() {
				this.sendAction('clearNewCommentAttachments');
			},
			clearNewComment: function clearNewComment() {
				this.sendAction.apply(this, ['clearNewComment'].concat(Array.prototype.slice.call(arguments)));
			}
		}
	});
});