define('tickets/validators/category', ['exports', 'ember-cp-validations/validators/base', 'tickets/enums/ticketStatuses'], function (exports, _base, _ticketStatuses) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Category = _base.default.extend({
		groups: Ember.inject.service(),
		validationMessage: 'This field can\'t be blank',
		validate: function validate(value, options, model) {
			return this.validateDependingOnGroup(value, model);
		},
		validateDependingOnGroup: function validateDependingOnGroup(value, model) {
			if (model.get('ticketStatus.id') !== _ticketStatuses.default.RESOLVED) {
				return true;
			}

			var currentGroupId = model.get('group.id');
			var brandGroupSelected = this.groups.getAll().mapBy('id').includes(currentGroupId);
			var noGroupOrBrandGroupSelected = !currentGroupId || brandGroupSelected;

			return noGroupOrBrandGroupSelected && !value.get('id') ? this.validationMessage : true;
		}
	});

	Category.reopenClass({
		getDependentsFor: function getDependentsFor() {
			return ['model.group', 'model.category'];
		}
	});

	exports.default = Category;
});