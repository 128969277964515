define('tickets/services/sort-resolvers/index', ['exports', 'tickets/services/sort-resolvers/assignee', 'tickets/services/sort-resolvers/simple', 'tickets/services/sort-resolvers/tag'], function (exports, _assignee, _simple, _tag) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		assignee: _assignee.default,
		simple: _simple.default,
		tag: _tag.default
	};
});