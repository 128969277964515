define('tickets/serializers/group', ['exports', 'ember-data', 'tickets/serializers/application'], function (exports, _emberData, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
		attrs: {
			priorityType: { serialize: 'ids', deserialize: 'records', key: 'priorityTypeId' },
			users: { serialize: 'ids', deserialize: 'records' }
		}
	});
});