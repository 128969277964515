define("tickets/enums/priorityTypes", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		LOW: 100,
		MEDIUM: 200,
		HIGH: 300,
		BLOCKER: 400
	});
});