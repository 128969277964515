define('tickets/routes/tickets/index', ['exports', 'tickets/mixins/routes/authenticated'], function (exports, _authenticated) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var TICKETS_ALL_PATH = 'tickets.all';

	exports.default = Ember.Route.extend(_authenticated.default, {
		model: function model() {
			this._super.apply(this, arguments);

			this.transitionTo(TICKETS_ALL_PATH, { queryParams: { presetFilters: true } });
		}
	});
});