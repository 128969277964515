define('tickets/components/generic-table-with-checkboxes', ['exports', 'tickets/templates/components/generic-table', 'tickets/components/generic-table'], function (exports, _genericTable, _genericTable2) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _genericTable2.default.extend({
		layout: _genericTable.default,
		enableSelectingRows: true,
		selectedRowIds: null,
		allChecked: Ember.computed('rows.@each.isSelected', 'selectedRowIds.[]', function () {
			return this.get('rows').isEvery('isSelected', true);
		}),
		onSelectionChanged: function onSelectionChanged() {
			var selectedIds = this.get('rows').filterBy('isSelected').mapBy('id');
			var notSelectedIds = this.get('rows').rejectBy('isSelected').mapBy('id');

			this.sendAction('updateSelectedRowIds', selectedIds, notSelectedIds);
		},

		actions: {
			toggleAllRows: function toggleAllRows(_ref) {
				var isChecked = _ref.isChecked;

				this.get('rows').setEach('isSelected', isChecked);
				this.onSelectionChanged();
			},
			toggleRow: function toggleRow(_ref2) {
				var isChecked = _ref2.isChecked,
				    value = _ref2.value;

				value.set('isSelected', isChecked);
				this.onSelectionChanged();
			}
		}
	});
});