define('tickets/mixins/controllers/save', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		toast: Ember.inject.service(),
		router: Ember.inject.service(),
		validations: {},
		save: function save(model) {
			var _this = this;

			var showSuccessToast = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
			var toastOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

			var toast = this.get('toast');

			return model.save().then(function () {
				if (showSuccessToast) {
					toast.success(model.constructor.modelName.capitalize() + ' saved successfully!');

					if (model.redirectAfterSave) {
						var _model$redirectAfterS = model.redirectAfterSave,
						    route = _model$redirectAfterS.route,
						    queryParams = _model$redirectAfterS.queryParams;

						delete model.redirectAfterSave;

						_this.get('router').transitionTo(route, { queryParams: queryParams });
					}
				}
			}).catch(function (e) {
				var message = toastOptions.errorMessage || e.payload && e.payload.message || 'Unknown server error';

				toast.error('Error: ' + message);
				throw e;
			});
		},
		validate: function validate(model) {
			var _this2 = this;

			return model.validate().then(function (_ref) {
				var validations = _ref.validations;

				if (validations.isValid) {
					return model;
				}

				_this2.get('toast').error('Please enter the correct values in the form and try submitting again');
				throw model.get('validations.attrs');
			});
		},

		actions: {
			save: function save() {
				var _this3 = this;

				var modelName = this.get('modelName');
				var model = this.get('model.' + modelName);

				if (model.validations) {
					return this.validate(model).then(function () {
						return _this3.save(model);
					});
				}

				return this.save(model).catch(function () {});
			}
		}
	});
});