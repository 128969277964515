define('tickets/mixins/controllers/errors', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		toast: Ember.inject.service(),
		metaErrors: '',
		errorsObserver: Ember.observer('metaErrors', function () {
			var _this = this;

			var metaErrors = this.get('metaErrors');

			if (metaErrors) {
				metaErrors.forEach(function (e) {
					return _this.toast.error(e);
				});
			}
		})
	});
});