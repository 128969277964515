define('tickets/helpers/effect-allowed', ['exports', '@abcum/ember-helpers/helpers/effect-allowed'], function (exports, _effectAllowed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _effectAllowed.default;
    }
  });
  Object.defineProperty(exports, 'effectAllowed', {
    enumerable: true,
    get: function () {
      return _effectAllowed.effectAllowed;
    }
  });
});