define('tickets/mixins/routes/reports', ['exports', 'tickets/enums/requiredPermissions', 'tickets/mixins/routes/ticketing-system'], function (exports, _requiredPermissions, _ticketingSystem) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create(_ticketingSystem.default, {
		requiredPermissions: _requiredPermissions.default.REPORTS,
		setupController: function setupController(controller) {
			this._super.apply(this, arguments);

			controller.set('reportType');
			controller.set('start');
			controller.set('end');
			controller.set('fields');
		}
	});
});