define('tickets/enums/ticketChannelTypes', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		PHONE: '100',
		EMAIL: '200',
		LIGHTHOUSE: '400',
		MANUAL: '500',
		CHAT: '700',
		WEB_FORM: '1000'
	});
});