define('tickets/components/input-field', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var $ = Ember.$,
	    computed = Ember.computed;
	exports.default = Ember.Component.extend({
		readonly: false,
		disabled: false,
		focused: false,
		tagName: '',
		description: '',
		placeholder: null,
		trimValue: false,
		highlightError: computed('validation.isValid', function () {
			return this.get('validation') && !this.get('validation.isValid');
		}),
		actions: {
			showTooltip: function showTooltip(event) {
				event.stopPropagation();
				event.preventDefault();

				$('label.tooltip').hide();
				$(event.target).siblings('label.tooltip').toggle();

				$(window).click(function () {
					$('label.tooltip').hide();
					$('window').off();
				});
			},
			onKeyPress: function onKeyPress() {
				if (this.get('onKeyPress')) {
					this.sendAction('onKeyPress');
				}
			},
			onChange: function onChange() {
				if (this.get('trimValue')) {
					this.set('value', this.value.trim());
				}
			}
		}
	});
});