define('tickets/controllers/tickets/my', ['exports', 'tickets/mixins/controllers/filters', 'tickets/mixins/controllers/tickets/listing'], function (exports, _filters, _listing) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_filters.default, _listing.default, {
		showAssigneeFilter: false,
		showFiltersReset: true,
		assigneeId: Ember.computed.alias('users.self.id')
	});
});