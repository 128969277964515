define('tickets/controllers/tags/index', ['exports', 'tickets/mixins/controllers/listing'], function (exports, _listing) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_listing.default, {
		selectTagRoute: 'tags.edit.general',
		pageCount: Ember.computed.alias('model.tags.meta.pageCount'),
		columns: [{
			name: 'Tag name',
			attribute: 'name'
		}]
	});
});