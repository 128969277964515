define('tickets/components/watchers-section', ['exports', 'tickets/utils/regex'], function (exports, _regex) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		readonly: false,
		watchers: [],
		actions: {
			onChange: function onChange(watchers) {
				watchers = watchers.filter(_regex.testEmail);

				this.sendAction('updateWatchers', watchers);
			}
		}
	});
});