define('tickets/helpers/pattern-phone', ['exports', '@abcum/ember-helpers/helpers/pattern-phone'], function (exports, _patternPhone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _patternPhone.default;
    }
  });
  Object.defineProperty(exports, 'patternPhone', {
    enumerable: true,
    get: function () {
      return _patternPhone.patternPhone;
    }
  });
});