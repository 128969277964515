define('tickets/components/generic-filters', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: '',
		filters: null,
		showReset: false,
		actions: {
			updateFilter: function updateFilter(property, value) {
				this.sendAction('updateFilter', property, value);
			},
			resetFilters: function resetFilters() {
				this.sendAction('resetFilters');
			},
			resetFilter: function resetFilter(filter) {
				this.sendAction('resetFilter', filter);
			},
			updateValidationMessage: function updateValidationMessage(value) {
				this.sendAction('updateValidationMessage', value);
			}
		}
	});
});