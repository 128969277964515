define('tickets/enums/resolutionTypes', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		DONE: '100',
		NO_RESPONSE: '200',
		NO_RESPONSE_AUTO: '300'
	});
});