define('tickets/adapters/user', ['exports', 'tickets/adapters/application', 'tickets/config/environment'], function (exports, _application, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		urlForQuery: function urlForQuery(query) {
			switch (query.type) {
				case 'availableAssignees':
					delete query.type;

					return _environment.default.host + '/api/v1/users/available-to-assign-tickets';
				case 'all':
					delete query.type;

					return _environment.default.host + '/api/v1/users/all';
				default:
					return this._super.apply(this, arguments);
			}
		},
		urlForQueryRecord: function urlForQueryRecord(query) {
			if (query.type === 'self') {
				delete query.type;

				return _environment.default.host + '/api/v1/users/self';
			}

			return this._super.apply(this, arguments);
		}
	});
});