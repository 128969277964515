define('tickets/controllers/roles/edit', ['exports', 'tickets/mixins/controllers/save'], function (exports, _save) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Controller.extend(_save.default, {
		canService: Ember.inject.service('can'),
		editableGroups: ['management', 'tickets-edit', 'reports'],
		editablePermissions: ['tickets-listing-unassigned'],
		modelName: 'role',
		parentRoute: 'roles',
		confirmPageLeaving: true,
		allSelected: Ember.computed('permissions.[]', 'model.permissions.[]', function () {
			return this.get('permissions.length') === this.get('model.permissions.length');
		}),
		permissions: Ember.computed.alias('model.role.permissions.[]'),
		permissionGroups: Ember.computed('model.permissions.[]', function () {
			var permissions = this.get('model.permissions');

			return permissions.rejectBy('parent.id').sortBy('order');
		}),
		isEditable: function isEditable(permission) {
			var parent = permission.get('parent.content');
			var permissionId = parent ? parent.id : permission.id;

			return this.editableGroups.includes(permissionId) || this.editablePermissions.includes(permission.id);
		},

		actions: {
			toggle: function toggle(permission) {
				if (!this.isEditable(permission) && !this.canService.can('write role')) {
					return;
				}

				var permissions = this.get('permissions');
				var parent = permission.get('parent.content');
				var childPermissions = permission.get('children').toArray();

				if (permissions.includes(permission)) {
					permissions.removeObjects([permission].concat(_toConsumableArray(childPermissions)));
				} else {
					permissions.pushObjects([permission].concat(_toConsumableArray(childPermissions)));
				}

				if (parent) {
					var siblings = parent.get('children');

					if (siblings.any(function (s) {
						return permissions.includes(s);
					})) {
						permissions.pushObject(parent);
					}

					if (siblings.every(function (s) {
						return !permissions.includes(s);
					})) {
						permissions.removeObject(parent);
					}
				}
			},
			toggleAll: function toggleAll() {
				var _this = this;

				if (!this.canService.can('write role')) {
					return;
				}

				if (this.get('allSelected')) {
					var editableGroups = this.get('permissions').filter(function (p) {
						return _this.isEditable(p);
					});

					this.get('permissions').removeObjects(editableGroups);
				} else {
					this.set('permissions', this.get('model.permissions'));
				}
			}
		}
	});
});