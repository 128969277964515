define('tickets/services/ajax', ['exports', 'tickets/config/environment', 'ember-ajax/services/ajax', 'tickets/utils/url'], function (exports, _environment, _ajax, _url) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var GET_REQUEST_TYPE = 'GET';

	exports.default = _ajax.default.extend({
		host: _environment.default.host,
		session: Ember.inject.service(),
		contentType: 'application/json',
		headers: Ember.computed('session.data', 'session.isAuthenticated', function () {
			return this.get('session.isAuthenticated') ? { 'auth-token': this.get('session.data.authenticated').token } : {};
		}),
		request: function request(url, options) {
			if (this._isGetRequest(options)) {
				var requestPath = (0, _url.default)(url, options);

				options && delete options.data;

				return this._super(requestPath, options);
			}

			return this._super.apply(this, arguments);
		},
		handleResponse: function handleResponse(status) {
			// if we get back a 401, then we should invalidate the session
			if (shouldInvalidateSession(status, this.get('session'))) {
				this.get('session').invalidate();
			}

			return this._super.apply(this, arguments);
		},
		_isGetRequest: function _isGetRequest(options) {
			return options && (!options.method || options.method === GET_REQUEST_TYPE) && (!options.type || options.type === GET_REQUEST_TYPE);
		}
	});


	/**
  * Checks if session has to be invalidated.
  *
  * @param {Number} status - status code of response
  * @param {Object} session - session service
  * @returns {Boolean} check result
  */
	function shouldInvalidateSession(status, session) {
		return status === 401 && session.get('isAuthenticated');
	}
});