define('tickets/services/change-log', ['exports', 'tickets/services/change-logs/callers', 'tickets/services/change-logs/request', 'tickets/services/change-logs/relation', 'tickets/services/change-logs/ticketRelation', 'tickets/services/change-logs/delayedResponse', 'tickets/services/change-logs/tag', 'tickets/services/change-logs/watchers', 'tickets/services/change-logs/task', 'tickets/services/change-logs/internalConversation', 'tickets/services/change-logs/publicConversation'], function (exports, _callers, _request, _relation, _ticketRelation, _delayedResponse, _tag, _watchers, _task, _internalConversation, _publicConversation) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _extends = Object.assign || function (target) {
		for (var i = 1; i < arguments.length; i++) {
			var source = arguments[i];

			for (var key in source) {
				if (Object.prototype.hasOwnProperty.call(source, key)) {
					target[key] = source[key];
				}
			}
		}

		return target;
	};

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	var CHANGE_LOGS_HANDLERS_MAP = Object.freeze({
		callers: _callers.default,
		request: _request.default,
		attachment: _relation.default,
		ticketRelation: _ticketRelation.default,
		delayedResponse: _delayedResponse.default,
		tag: _tag.default,
		watchers: _watchers.default,
		task: _task.default,
		internalConversation: _internalConversation.default,
		publicConversation: _publicConversation.default
	});

	exports.default = Ember.Service.extend({
		store: Ember.inject.service(),
		formatChangeLog: function formatChangeLog(log) {
			var _this = this;

			return new Ember.RSVP.Promise(function (resolve) {
				var changeLogHandler = CHANGE_LOGS_HANDLERS_MAP[log.get('attribute')];

				if (changeLogHandler) {
					return resolve(changeLogHandler(log, _this.store));
				}

				if (log.get('relationModel')) {
					return resolve(Ember.RSVP.all([_this.getRelationModelAttribute(log, log.get('oldValue')), _this.getRelationModelAttribute(log, log.get('newValue'))]).then(function (_ref) {
						var _ref2 = _slicedToArray(_ref, 2),
						    oldValue = _ref2[0],
						    newValue = _ref2[1];

						return _extends({}, log.serialize(), {
							oldValue: oldValue,
							newValue: newValue,
							user: log.get('user'),
							displayAttribute: log.get('displayAttribute')
						});
					}));
				}

				resolve(log);
			});
		},
		getRelationModelAttribute: function getRelationModelAttribute(log, id) {
			return id ? this.handleRelationModel(log, id) : this.handleEmptyRelationModel(log);
		},
		handleRelationModel: function handleRelationModel(log, id) {
			return this.getRelationModel(log.get('relationModel'), id).then(function (relationModel) {
				var attributes = log.get('relationAttribute').split(',');

				return attributes.reduce(function (acc, attr) {
					return acc + ' ' + relationModel.get(attr);
				}, '');
			});
		},
		getRelationModel: function getRelationModel(model, id) {
			var _this2 = this;

			return new Ember.RSVP.Promise(function (resolve) {
				var relationModel = _this2.get('store').peekRecord(model, id);

				if (!relationModel) {
					return resolve(_this2.get('store').findRecord(model, id));
				}

				resolve(relationModel);
			});
		},
		handleEmptyRelationModel: function handleEmptyRelationModel(log) {
			switch (log.get('attribute')) {
				case 'assignee':
					return 'Unassigned';
				default:
					return '';
			}
		}
	});
});