define('tickets/helpers/history-forward', ['exports', '@abcum/ember-helpers/helpers/history-forward'], function (exports, _historyForward) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _historyForward.default;
    }
  });
  Object.defineProperty(exports, 'historyForward', {
    enumerable: true,
    get: function () {
      return _historyForward.historyForward;
    }
  });
});