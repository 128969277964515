define('tickets/models/ticket', ['exports', 'ember-data', 'ember-cp-validations', 'tickets/enums/ticketRelationTypes'], function (exports, _emberData, _emberCpValidations, _ticketRelationTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo,
	    hasMany = _emberData.default.hasMany;


	var validations = (0, _emberCpValidations.buildValidations)({
		subject: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('length', { max: 256 })],
		description: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('length', { max: 10000 })],
		summary: [(0, _emberCpValidations.validator)('length', { max: 10000 })],
		errorCode: [(0, _emberCpValidations.validator)('length', { max: 1024 })],
		resolutionBarrier: [(0, _emberCpValidations.validator)('length', { max: 1024 })],
		why: [(0, _emberCpValidations.validator)('length', { max: 1024 })],
		actionPlan: [(0, _emberCpValidations.validator)('length', { max: 1024 })],
		currentStateNote: [(0, _emberCpValidations.validator)('length', { max: 1024 })],
		managerNotes: [(0, _emberCpValidations.validator)('length', { max: 1024 })],
		solution: [(0, _emberCpValidations.validator)('length', { max: 10000 })],
		customerTemperature: [(0, _emberCpValidations.validator)('length', { max: 128 })],
		relatedMIDs: [(0, _emberCpValidations.validator)('length', { max: 128 })],
		softwareVersion: [(0, _emberCpValidations.validator)('length', { max: 64 })],
		businessName: [(0, _emberCpValidations.validator)('length', { max: 128 })],
		softwareSerial: [(0, _emberCpValidations.validator)('length', { max: 128 })],
		customer: [(0, _emberCpValidations.validator)('customer')],
		callers: [(0, _emberCpValidations.validator)('callers')],
		isCallersEmailsValid: [(0, _emberCpValidations.validator)('callers-email')],
		department: [(0, _emberCpValidations.validator)('presence', true)],
		group: [(0, _emberCpValidations.validator)('presence', true)],
		assignee: [(0, _emberCpValidations.validator)('assignee')],
		channelType: [(0, _emberCpValidations.validator)('channelType')],
		requestType: [(0, _emberCpValidations.validator)('category')]
	});

	exports.default = _emberData.default.Model.extend(validations, {
		subject: attr('string'),
		description: attr('string'),
		summary: attr('string'),
		errorCode: attr('string'),
		resolutionBarrier: attr('string'),
		why: attr('string'),
		actionPlan: attr('string'),
		solution: attr('string'),
		currentStateNote: attr('string'),
		managerNotes: attr('string'),
		customerTemperature: attr('string'),
		reportedAt: attr('date', { defaultValue: function defaultValue() {
				return new Date();
			} }),
		createdAt: attr('date'),
		updatedAt: attr('date'),
		problemStarted: attr('string'), /** Using "string" attr for date field to fix ember-data changes tracking bug */
		resolvedAt: attr('date'),
		watchers: attr(),
		isCallersEmailsValid: attr(),
		callers: attr('callers', { defaultValue: function defaultValue() {
				return [{}];
			} }),
		relatedMIDs: attr('string'),
		softwareVersion: attr('string'),
		businessName: attr('string'),
		softwareSerial: attr('string'),
		sendResolveEmail: attr('boolean', { defaultValue: true }),
		isArchived: attr('boolean', { defaultValue: false }),
		customer: belongsTo('customer'),
		reporter: belongsTo('user'),
		assignee: belongsTo('user'),
		communicationBrand: belongsTo('brand'),
		ticketChannelType: belongsTo('ticketChannelType'),
		department: belongsTo('department'),
		group: belongsTo('group'),
		channel: belongsTo('channel'),
		requestType: belongsTo('category'),
		product: belongsTo('category'),
		category: belongsTo('category'),
		subCategory: belongsTo('category'),
		ticketStatus: belongsTo('ticketStatus'),
		resolutionType: belongsTo('resolutionType'),
		priorityType: belongsTo('priorityType'),
		utgBuild: belongsTo('utgBuild'),
		terminalSoft: belongsTo('terminalSoft'),
		internalComments: hasMany('internalComment'),
		externalComments: hasMany('externalComment'),
		changeLogs: hasMany('changeLog'),
		attachments: hasMany('attachment'),
		tasks: hasMany('task'),
		tags: hasMany('tag'),
		relatedTickets: hasMany('ticketRelation'),
		notifyCallers: attr('boolean'),
		originatingDepartment: attr('string'),
		originatingDepartmentName: Ember.computed('originatingDepartment', function () {
			return this.originatingDepartment ? this.store.peekRecord('department', this.originatingDepartment).name : 'N/A';
		}),
		availableGroups: Ember.computed('department', 'group', function () {
			var departmentGroups = this.get('department').get('groups');
			var groups = this.store.peekAll('group');
			var ticketGroup = this.get('group');
			var filteredGroupList = departmentGroups.filter(function (departmentGroup) {
				return groups.find(function (group) {
					return group.id === departmentGroup.id && group.isActive;
				});
			});

			if (ticketGroup.get('name') && !ticketGroup.get('isActive')) {
				return [].concat(_toConsumableArray(filteredGroupList), [ticketGroup]);
			}

			return filteredGroupList;
		}),
		disabledGroups: Ember.computed('department', function () {
			var groups = this.get('department').get('groups');
			return groups.rejectBy('isActive', true).map(function (group) {
				return group.id;
			});
		}),
		availableDepartments: Ember.computed('department', function () {
			var departmentsList = this.store.peekAll('department');
			var ticketDepartment = this.get('department');
			var filteredDepartmentsList = departmentsList.filter(function (departmentsListItem) {
				return departmentsListItem.get('isActive');
			});
			if (ticketDepartment.get('name') && !ticketDepartment.get('isActive')) {
				return [].concat(_toConsumableArray(filteredDepartmentsList), [ticketDepartment]);
			}
			return filteredDepartmentsList;
		}),
		disabledDepartments: Ember.computed('department', function () {
			var departments = this.store.peekAll('department');
			return departments.rejectBy('isActive', true).map(function (department) {
				return department.get('id');
			});
		}),
		customerName: Ember.computed('customer.customerName', function () {
			return this.get('customer.customerName');
		}),
		duplicatedTickets: Ember.computed('relatedTickets.[]', function () {
			return this.get('relatedTickets').filterBy('ticketRelationTypeId.value', _ticketRelationTypes.default.DUPLICATES);
		}),
		duplicatedTicketsIds: Ember.computed.map('duplicatedTickets', function (t) {
			return t.get('relatedTicket.id');
		}),
		duplicatedTicketsInternalComments: Ember.computed('duplicatedTickets.@each.internalComments', function () {
			return this._getDuplicatedComments('internalComments');
		}),
		duplicatedTicketsExternalComments: Ember.computed('duplicatedTickets.@each.externalComments', function () {
			return this._getDuplicatedComments('externalComments');
		}),
		allInternalComments: Ember.computed.union('internalComments', 'duplicatedTicketsInternalComments'),
		allExternalComments: Ember.computed.union('externalComments', 'duplicatedTicketsExternalComments'),
		rollbackAttributes: function rollbackAttributes() {
			if (this.get('isNew')) {
				this.set('solution', '');
			} else {
				this._super.apply(this, arguments);
			}
		},
		getCustomChanges: function getCustomChanges(currentChanges) {
			this._hasComments('externalComments') && (currentChanges.externalComments = true);
			this._hasComments('internalComments') && (currentChanges.internalComments = true);
			this._hasAttachments() && (currentChanges.attachments = true);

			return currentChanges;
		},
		_hasComments: function _hasComments(type) {
			return !!this.get(type).filterBy('isNew').length;
		},
		_hasAttachments: function _hasAttachments() {
			return this.isNew && !!this.get('attachments').length;
		},
		_getDuplicatedComments: function _getDuplicatedComments(commentsProperty) {
			return this.get('duplicatedTickets').map(function (ticket) {
				return ticket.get(commentsProperty).toArray();
			}).flat();
		},


		changeTracker: {
			except: ['attachments', 'internalComments', 'externalComments', 'channel', 'tasks', 'changeLogs']
		},
		enableChangeTrackingOfInitState: true
	});
});