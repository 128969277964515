define('tickets/mixins/controllers/groups/edit', ['exports', 'tickets/utils/regex', 'tickets/mixins/modal', 'tickets/enums/sourceTypes', 'tickets/resources/groupPageTexts', 'tickets/mixins/controllers/save'], function (exports, _regex, _modal, _sourceTypes, _groupPageTexts, _save) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var LINK_SOURCE_TYPES = [_sourceTypes.default.EMAIL];

	exports.default = Ember.Mixin.create(_save.default, _modal.default, {
		modelName: 'group',
		parentRoute: 'groups',
		confirmPageLeaving: true,
		group: Ember.computed.alias('model.group'),
		validations: {},
		brandingStrategyTooltipText: _groupPageTexts.default.tooltips.origin,
		communicationBrandTooltipText: _groupPageTexts.default.tooltips.brand,
		priorityTypeTooltipText: _groupPageTexts.default.tooltips.priority,
		customerIdentificationTooltipText: _groupPageTexts.default.tooltips.customerIdentification,
		unassignAfterReopenTooltipText: _groupPageTexts.default.tooltips.unassignAfterReopen,
		resetGroupData: function resetGroupData() {
			if (!LINK_SOURCE_TYPES.includes(this.get('group.sourceType.id'))) {
				this.set('group.link');
			}

			if (this.get('group.sourceType.id') !== _sourceTypes.default.EMAIL) {
				this.set('group.teamName');
			}
		},

		customerIdentificationOptions: [{ name: 'Auto-identify Customer by Email', value: true }, { name: 'Don\'t Identify Customer by Email', value: false }],
		showGroupValidationErrors: function showGroupValidationErrors(validations) {
			this.setProperties({ validations: validations });
		},

		actions: {
			save: function save() {
				var _this = this;

				this.resetGroupData();
				this._super().catch(function (validations) {
					return _this.showGroupValidationErrors(validations);
				});
			},
			updateSelectedUsers: function updateSelectedUsers(users) {
				this.set('group.users', users);
			},
			updateIsActive: function updateIsActive(_ref) {
				var isChecked = _ref.isChecked;

				this.set('group.isActive', isChecked);
			},
			sourceTypeChanged: function sourceTypeChanged(sourceType) {
				this.set('group.sourceType', sourceType);

				if (this.get('group.sourceType.id') === _sourceTypes.default.EMAIL && !this.get('group.link')) {
					return this.set('group.link', { identifyCustomerByEmail: true });
				}
			},

			emailsChanged: function emailsChanged(emails) {
				this.set('group.link.emails', emails.filter(_regex.testEmail));
			},
			externalEmailsChanged: function externalEmailsChanged(emails) {
				this.set('group.externalCommunicationEmails', emails.filter(_regex.testEmail));
			}
		}
	});
});