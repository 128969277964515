define('tickets/controllers/application', ['exports', 'tickets/mixins/sidebar', 'tickets/mixins/controllers/app-menu'], function (exports, _sidebar, _appMenu) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_sidebar.default, _appMenu.default, {
		session: Ember.inject.service(),
		appController: Ember.inject.controller('application'),
		currentRouteName: Ember.computed.reads('appController.currentRouteName'),
		currentController: Ember.computed('currentRouteName', function () {
			var owner = Ember.getOwner(this);
			var currentRouteName = this.get('currentRouteName');
			var route = owner.lookup('route:' + currentRouteName);
			return route && route.controller;
		}),
		parentRoute: Ember.computed('currentController', function () {
			return this.get('currentController.parentRoute');
		}),
		appMenuHeader: Ember.computed.alias('currentController.appMenuHeader'),
		parentOptions: Ember.computed('currentController', function () {
			return this.get('currentController.parentOptions');
		}),
		actions: {
			onMenuRendered: function onMenuRendered() {
				this.initSidebar();
			},
			onMenuButtonClick: function onMenuButtonClick() {
				this.toggleSidebar();
			}
		}
	});
});