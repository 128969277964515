define('tickets/mixins/controllers/filters', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _extends = Object.assign || function (target) {
		for (var i = 1; i < arguments.length; i++) {
			var source = arguments[i];

			for (var key in source) {
				if (Object.prototype.hasOwnProperty.call(source, key)) {
					target[key] = source[key];
				}
			}
		}

		return target;
	};

	exports.default = Ember.Mixin.create({
		showFiltersReset: true,
		filters: [],
		actions: {
			resetFilters: function resetFilters() {
				var resetFilters = this.filters.mapBy('property').reduce(function (acc, filter) {
					acc[filter] = undefined;

					return acc;
				}, {});

				this.setProperties(_extends({}, resetFilters, { page: 1 }));
			}
		}
	});
});