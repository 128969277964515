define('tickets/components/dropdown-multi-select-labels', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: '',
		selected: [],
		options: [],
		clearable: true,
		allowAdditions: true,
		focused: false,
		mapProperty: '',
		defaultText: '',
		className: 'multiple search selection',
		actions: {
			onChange: function onChange(value) {
				this.sendAction('onChange', value);
			}
		}
	});
});