define('tickets/routes/general-report', ['exports', 'tickets/mixins/routes/reports'], function (exports, _reports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_reports.default, {
		groups: Ember.inject.service(),
		categories: Ember.inject.service(),
		users: Ember.inject.service(),
		departments: Ember.inject.service(),
		model: function model() {
			return Ember.RSVP.hash({
				groups: this.groups.getAll(),
				categories: this.categories.getAll(),
				users: this.users.getAll(),
				departments: this.departments.getAll(),
				transfers: this.groups.getAll()
			});
		}
	});
});