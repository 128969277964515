define('tickets/validators/unique-department-name', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var UniqueDepartmentName = _base.default.extend({
		departments: Ember.inject.service(),
		validate: function validate(value, options, model) {
			var departmentId = model.get('id');
			var lowerCaseValue = value.toLowerCase();
			var duplicatedName = this.departments.getAll().rejectBy('id', departmentId).mapBy('name').any(function (name) {
				return name && name.toLowerCase() === lowerCaseValue;
			});

			return duplicatedName ? 'Department ' + value + ' already exists' : true;
		}
	});

	exports.default = UniqueDepartmentName;
});