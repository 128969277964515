define('tickets/components/date-range-picker', ['exports', 'moment'], function (exports, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var DATE_FORMAT = 'MM/DD/YYYY';

	exports.default = Ember.Component.extend({
		tagName: 'div',
		classNames: ['input-in calendar'],
		classNameBindings: ['focused:active-box'],
		ranges: [],
		focused: false,
		value: Ember.computed('start', 'end', function () {
			if (!this.start || !this.end) {
				return '';
			}

			return (0, _moment.default)(this.start).format(DATE_FORMAT) + ' - ' + (0, _moment.default)(this.end).format(DATE_FORMAT);
		}),
		resetButtonHandler: function resetButtonHandler(picker, e) {
			if (e.target.className === 'calentim-cancel') {
				this.reInitDateRangePicker(picker);
			}
		},
		emptyInputHandler: function emptyInputHandler(picker, e) {
			if (!e.target.value) {
				this.reInitDateRangePicker(picker);
			}
		},
		reInitDateRangePicker: function reInitDateRangePicker(picker) {
			picker.destroy();
			this.sendAction('resetRange');
			this.initDateRangePicker();
		},
		initDateRangePicker: function initDateRangePicker() {
			var component = this;
			var container = component.$('.date-range-picker');

			if (!container) {
				return;
			}

			return container.calentim({
				minDate: (0, _moment.default)().subtract(1, 'year'),
				maxDate: (0, _moment.default)(),
				format: DATE_FORMAT,
				startEmpty: true,
				calendarCount: 1,
				oneCalendarWidth: 280,
				showHeader: false,
				showFooter: true,
				showTimePickers: false,
				showOn: 'bottom',
				ranges: component.ranges,
				showButtons: true,
				cancelLabel: 'Clear',
				container: '.calentim-unique-container',
				oninit: function oninit(calentim) {
					calentim.elem.addEventListener('change', component.emptyInputHandler.bind(component, calentim));
					calentim.container[0].addEventListener('click', component.resetButtonHandler.bind(component, calentim));
				},
				ondestroy: function ondestroy(calentim) {
					calentim.elem.removeEventListener('change', component.emptyInputHandler);
					calentim.container[0].removeEventListener('click', component.resetButtonHandler);
				},
				onaftershow: function onaftershow() {
					component.set('focused', true);
				},
				onafterselect: function onafterselect(calentim, start, end) {
					var startOfTheDay = start.clone().startOf('day');
					var endOfTheDay = end.clone().endOf('day');

					component.sendAction('setRange', { start: startOfTheDay, end: endOfTheDay });
				}
			});
		},
		didInsertElement: function didInsertElement() {
			if (this.presetDates) {
				var _presetDates = this.presetDates,
				    start = _presetDates.start,
				    end = _presetDates.end;


				this.setProperties({ start: start, end: end });
			}

			this.initDateRangePicker();
		}
	});
});