define('tickets/models/change-log', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo;
	var computed = Ember.computed;
	exports.default = _emberData.default.Model.extend({
		oldValue: attr(),
		newValue: attr(),
		type: attr('string'),
		attribute: attr('string'),
		relationModel: attr('string'),
		relationAttribute: attr('string'),
		ticketRelationTypeId: attr('number'),
		isDependent: attr('boolean'),
		createdAt: attr('date'),
		user: belongsTo('user'),
		ticket: belongsTo('ticket'),
		displayAttribute: computed('attribute', function () {
			return this.get('attribute').dasherize().replace(/-/g, ' ').capitalize();
		})
	});
});