define('tickets/mixins/routes/departments', ['exports', 'tickets/mixins/routes/permissions', 'tickets/enums/requiredPermissions'], function (exports, _permissions, _requiredPermissions) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create(_permissions.default, {
		requiredPermissions: _requiredPermissions.default.DEPARTMENTS,
		queryDepartment: function queryDepartment() {
			return this.store.createRecord('department', {
				isActive: true,
				notifyCallers: true,
				defaultNotifyCallersSelection: true
			});
		},
		model: function model(params) {
			return Ember.RSVP.hash({
				department: this.queryDepartment(params),
				mailingOptions: this.store.peekAll('mailingOption')
			});
		},
		setupController: function setupController(controller) {
			this._super.apply(this, arguments);

			controller.setProperties({
				validations: null
			});
		}
	});
});