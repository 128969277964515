define('tickets/components/recursive-tree', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: '',
		items: [],
		levelNames: [],
		parent: {},
		disabled: false,
		recursiveDepth: 0,
		showNewItemInput: false,
		newItemInputValue: '',
		newItemInputValidation: '',
		displayProperty: 'name',
		childProperty: 'children',
		isActiveProperty: 'isActive',
		validationMessage: 'Can\'t add empty or duplicating item!',
		currentLevelName: Ember.computed('levelNames', function () {
			return this.levelNames[this.recursiveDepth] || this.levelNames[0];
		}),
		nextLevelDepth: Ember.computed('recursiveDepth', function () {
			return this.recursiveDepth + 1;
		}),
		canCreateNewItems: Ember.computed('nextLevelDepth', 'levelNames.length', function () {
			return this.get('nextLevelDepth') < this.get('levelNames.length');
		}),
		isNewValueUniqueAndTruthy: function isNewValueUniqueAndTruthy() {
			var newValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
			var item = arguments[1];

			var trimmedValue = newValue.trim();
			var itemWithSameValue = this.items.without(item).rejectBy(this.isActiveProperty, false).findBy(this.displayProperty, trimmedValue);

			return trimmedValue && !itemWithSameValue;
		},

		actions: {
			addNewItem: function addNewItem() {
				if (this.get('disabled')) {
					return;
				}

				if (!this.isNewValueUniqueAndTruthy(this.newItemInputValue)) {
					return this.send('setNewItemInputValidation', false);
				}

				this.sendAction('addItem', this.newItemInputValue, this.get('parent.id'));
				this.send('hideNewItemInput');
				this.set('parent.showChildren', true);
			},
			updateItem: function updateItem(item) {
				if (this.get('disabled')) {
					return;
				}

				if (!this.isNewValueUniqueAndTruthy(item.get(this.displayProperty), item)) {
					return item.set('validation.isValid', false);
				}

				this.sendAction('updateItem', item);
				item.toggleProperty('isEditing');
			},
			toggleChildren: function toggleChildren(item) {
				item.toggleProperty('showChildren');
			},
			toggleItemIsEditing: function toggleItemIsEditing(item) {
				item.rollbackAttributes();
				item.toggleProperty('isEditing');
				this.send('clearItemValidation', item);
			},
			setNewItemInputValidation: function setNewItemInputValidation(isValid) {
				this.set('newItemInputValidation', { message: this.validationMessage, isValid: isValid });
			},
			clearItemValidation: function clearItemValidation(item) {
				item.set('validation', { message: this.validationMessage, isValid: true });
			},
			hideNewItemInput: function hideNewItemInput() {
				this.set('showNewItemInput', false);
				this.set('newItemInputValue');
				this.send('setNewItemInputValidation', true);
			},
			updateIsActive: function updateIsActive(_ref) {
				var item = _ref.item,
				    isChecked = _ref.isChecked;

				if (this.get('disabled')) {
					return;
				}

				this.sendAction('updateIsActive', { item: item, isChecked: isChecked });
			}
		}
	});
});