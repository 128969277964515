define('tickets/mixins/routes/document-title', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		metaTags: Ember.inject.service(),
		getRouteTitle: function getRouteTitle() {
			throw new Error('Not implemented');
		},
		setDocumentTitle: function setDocumentTitle(model) {
			var title = this.getRouteTitle(model);

			return this.metaTags.updateTitle(title);
		},
		afterModel: function afterModel(model) {
			this._super.apply(this, arguments);

			return this.setDocumentTitle(model);
		},

		actions: {
			willTransition: function willTransition() {
				this._super.apply(this, arguments);

				this.metaTags.resetTitle();
			}
		}
	});
});