define('tickets/utils/clearFalsyValues', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = clearFalsyValues;

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	/**
  * Clears falsy values in queryParams object.
  * @param {Object} obj - Target object
  * @returns {Object} Object with truthy values
  */
	function clearFalsyValues(obj) {
		return Object.keys(obj).reduce(function (query, key) {
			var value = obj[key];

			if (value) {
				if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object') {
					if (Array.isArray(value)) {
						if (value.length !== 0) {
							query[key] = value;
						}
					} else {
						query[key] = clearFalsyValues(value);
					}
				} else {
					query[key] = value;
				}
			}

			return query;
		}, {});
	}
});