define('tickets/serializers/internal-comment', ['exports', 'ember-data', 'tickets/serializers/application'], function (exports, _emberData, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
		attrs: {
			user: { serialize: 'ids', deserialize: 'ids' }
		}
	});
});