define('tickets/controllers/groups/index', ['exports', 'tickets/mixins/controllers/listing', 'tickets/utils/deepEqual'], function (exports, _listing, _deepEqual) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_listing.default, {
		queryParams: ['departmentId'],
		pageCount: Ember.computed.alias('model.groups.meta.pageCount'),
		selectGroupRoute: 'groups.edit.general',
		departmentId: [],
		columns: [{
			name: 'Group name',
			attribute: 'name'
		}, {
			name: 'Group link',
			attribute: 'sourceType.name'
		}, {
			name: 'Department',
			attribute: 'department.name'
		}, {
			name: 'Number of users',
			attribute: 'users.length'
		}],
		filters: Ember.computed('departmentId', function () {
			return [{
				type: 'multi-select',
				property: 'departmentId',
				placeholder: 'Department',
				dynamicPlaceholder: true,
				items: this.get('model.departments'),
				displayProperty: 'name',
				mapProperty: 'id',
				value: this.get('departmentId')
			}];
		}),
		filterQuery: Ember.computed('departmentId', function () {
			return {
				departmentId: this.get('departmentId')
			};
		}),
		queryObserver: Ember.observer('departmentId', function () {
			var _this = this;

			Ember.run.next(function () {
				if (_this.get('model.groups')) {
					_this.set('page', 1);
					_this.updateTable();
				}
			});
		}),
		pageObserver: Ember.observer('page', 'limit', function () {
			if (this.get('model.groups')) {
				this.updateTable();
			}
		}),
		updateTable: function updateTable() {
			var newQueryParams = {
				page: this.get('page'),
				limit: this.get('limit'),
				filter: this.get('filterQuery')
			};

			if (!(0, _deepEqual.default)(newQueryParams, this.get('model.groups.query'))) {
				this.set('model.groups.query', newQueryParams);
				this.get('model.groups').update();
			}
		},

		actions: {
			updateFilter: function updateFilter(filter, value) {
				var _this2 = this;

				Ember.run.next(function () {
					return _this2.set(filter, value);
				});
			}
		}
	});
});