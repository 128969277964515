define('tickets/helpers/is-active', ['exports', '@abcum/ember-helpers/helpers/is-active'], function (exports, _isActive) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isActive.default;
    }
  });
  Object.defineProperty(exports, 'isActive', {
    enumerable: true,
    get: function () {
      return _isActive.isActive;
    }
  });
});