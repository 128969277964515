define('tickets/enums/verificationTypes', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		'Yes – Owner/Signer': 10,
		'Yes – Managing Member': 20,
		'Yes – Authorized Contact': 30,
		'Yes – Technical Contact': 40,
		'No': 50
	});
});