define('tickets/components/app-menu', ['exports', 'tickets/mixins/leavePageConfirmation', 'tickets/mixins/keyboardHandlers'], function (exports, _leavePageConfirmation, _keyboardHandlers) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend(_leavePageConfirmation.default, _keyboardHandlers.default, {
		user: null,
		parentRoute: null,
		showMenu: false,
		sideMenuItems: [],
		menuInited: false,
		router: Ember.inject.service(),
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			this.initMenu();
		},

		initMenuObserver: Ember.observer('showMenu', function () {
			this.initMenu();
		}),
		initMenu: function initMenu() {
			if (this.showMenu && !this.menuInited) {
				this.sendAction('onInsertElement');
				this.menuInited = true;
			}

			if (this.router.currentURL.includes('manage-notifications')) {
				this.$('.ui.sidebar').remove();
				this.$('.top-menu').remove();
			}
		},

		actions: {
			toggleSidebar: function toggleSidebar() {
				this.sendAction('onMenuButtonClick');
			}
		}
	});
});