define('tickets/mixins/routes/tasks', ['exports', 'tickets/mixins/routes/ticketing-system'], function (exports, _ticketingSystem) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create(_ticketingSystem.default, {
		counter: Ember.inject.service(),
		taskType: 'assigned',
		queryParams: {
			page: {
				refreshModel: true
			},
			limit: {
				refreshModel: true
			}
		},
		model: function model(_ref) {
			var page = _ref.page,
			    limit = _ref.limit,
			    column = _ref.column,
			    direction = _ref.direction;

			this.counter.fetchCounter();
			return Ember.RSVP.hash({
				tasks: this.store.query('task', { page: page, limit: limit, sort: { column: column, direction: direction }, type: this.get('taskType') })
			});
		},

		actions: {
			queryParamsDidChange: function queryParamsDidChange() {
				this._super.apply(this, arguments);
				this.refresh();
			}
		}
	});
});