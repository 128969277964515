define('tickets/controllers/departments/index', ['exports', 'tickets/mixins/controllers/listing'], function (exports, _listing) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_listing.default, {
		pageCount: Ember.computed.alias('model.departments.meta.pageCount'),
		selectDepartmentRoute: 'departments.edit.general',
		columns: [{
			name: 'Department name',
			attribute: 'name'
		}]
	});
});