define('tickets/mixins/routes/tags', ['exports', 'tickets/mixins/routes/permissions', 'tickets/enums/requiredPermissions'], function (exports, _permissions, _requiredPermissions) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create(_permissions.default, {
		requiredPermissions: _requiredPermissions.default.TAGS,
		queryTag: function queryTag() {
			return this.store.createRecord('tag');
		},
		model: function model(params) {
			return Ember.RSVP.hash({
				tag: this.queryTag(params)
			});
		},
		setupController: function setupController(controller) {
			this._super.apply(this, arguments);

			controller.setProperties({
				validations: null
			});
		}
	});
});