define('tickets/validators/unique-group-name', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var UniqueGroupName = _base.default.extend({
		groups: Ember.inject.service(),
		validate: function validate(value, options, model) {
			var groups = this.groups.getAll();
			var lowerCaseValue = value.toLowerCase();
			var groupNames = groups.filterBy('department.id', model.get('department.id')).rejectBy('id', model.id).mapBy('name');
			var duplicatedName = groupNames.any(function (name) {
				return name.toLowerCase() === lowerCaseValue;
			});

			return duplicatedName ? 'Group ' + value + ' already exists' : true;
		}
	});

	UniqueGroupName.reopenClass({
		getDependentsFor: function getDependentsFor() {
			return ['model.department'];
		}
	});

	exports.default = UniqueGroupName;
});