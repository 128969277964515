define('tickets/validators/group-link', ['exports', 'ember-cp-validations/validators/base', 'tickets/enums/sourceTypes'], function (exports, _base, _sourceTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var GroupLink = _base.default.extend({
		validationMessage: 'The field should contain at least one email',
		validate: function validate() {
			var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
			    _ref$emails = _ref.emails,
			    emails = _ref$emails === undefined ? [] : _ref$emails;

			var isEmailType = this.get('model.sourceType.id') === _sourceTypes.default.EMAIL;
			var notValid = isEmailType && !emails.length;

			return notValid ? this.validationMessage : true;
		}
	});

	GroupLink.reopenClass({
		getDependentsFor: function getDependentsFor() {
			return ['model.sourceType', 'model.link.emails'];
		}
	});

	exports.default = GroupLink;
});