define('tickets/resources/reportDescriptions', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var ERROR_GENERATION_WARNING = '<br>Please be aware that this report processes large number of data. If you receive error message: <b>Error while' + ' generating report: timeout due to large request criteria</b> - select smaller number of groups or shorter range.';

	exports.default = Object.freeze({
		groups: 'Shows the number of tickets assigned to groups and transferred from the groups over a period of time;\n\t\thow many tickets of every group were resolved and the average length of time taken to resolve it. ' + ERROR_GENERATION_WARNING,
		categories: 'Shows the number of tickets created in relation to request type, product or service,\n\t\tcategory and subcategory over a period of time. This helps you understand problem category differentiation.',
		users: 'Shows the number of tickets created by users and assigned to them over a period of time;\n\t\thow many tickets were resolved and the average length of time taken to resolve it for each user.\n\t\tThis helps you understand if your team is keeping up with incoming work.',
		transfers: 'Shows the number of tickets transferred from one group to another over a period of time',
		agent: 'Shows activities of users over a period of time.\n\t\tThis report helps to be sure that agents are being productive within chosen timeframe.',
		merchant: 'Shows all tickets that have been created for a specific merchant with problem description and comments.',
		quickie: 'Shows all tickets which have comments of the specified users dated within the timeframe.\n\t\tThis report might be helpful when assessing the effectiveness of involvement of specific people.',
		'closed-same-day-ratio': 'Shows all tickets which have comments of the specified users dated within the timeframe.\n\t\tThis report might be helpful when assessing the effectiveness of involvement of specific people.',
		'open-tickets': 'Shows the number of Open, In Progress and Waiting on Customer tickets in a Department and Group. You can filter the tickets included in this report according to the date they were created.',
		'all-tickets': 'Shows all tickets by available fields that were created or updated over a period of time. It will show only columns that you\'ve specified in "Available fields".'
	});
});