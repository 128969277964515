define('tickets/components/dropdown-multi-select-labels-field', ['exports', 'tickets/components/input-field'], function (exports, _inputField) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _inputField.default.extend({
		selected: [],
		items: [],
		focused: false,
		defaultText: 'placeholder',
		actions: {
			onChange: function onChange(value) {
				if (this.readonly) {
					return true;
				}

				this.sendAction('onChange', value);
			}
		}
	});
});