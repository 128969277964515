define('tickets/validators/channel-type', ['exports', 'ember-cp-validations/validators/base', 'tickets/enums/ticketChannelTypes'], function (exports, _base, _ticketChannelTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var ChannelType = _base.default.extend({
		validationMessage: 'Please select one of the possible ticket sources.',
		validate: function validate(value, options, model) {
			var isNew = model.get('isNew');
			var hasSelectedSource = Object.values(_ticketChannelTypes.default).includes(model.get('ticketChannelType.id'));

			var isValid = isNew ? hasSelectedSource : true;

			return isValid || this.validationMessage;
		}
	});

	ChannelType.reopenClass({
		getDependentsFor: function getDependentsFor() {
			return ['model.ticketChannelType.id'];
		}
	});

	exports.default = ChannelType;
});