define('tickets/components/generic-table-with-radiobuttons', ['exports', 'tickets/components/generic-table-with-checkboxes'], function (exports, _genericTableWithCheckboxes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _genericTableWithCheckboxes.default.extend({
		enableSelectingRowsRadioButton: true,
		actions: {
			selectUniqRow: function selectUniqRow(_ref) {
				var isChecked = _ref.isChecked,
				    value = _ref.value;

				this.get('rows').setEach('isSelected', false);
				value.set('isSelected', isChecked);
				this.onSelectionChanged();
			}
		}
	});
});