define('tickets/enums/permissions', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		TICKETS_LISTING: 'tickets-listing',
		TICKETS_LISTING_ALL: 'tickets-listing-all',
		TICKETS_LISTING_AVAILABLE: 'tickets-listing-available',
		TICKETS_LISTING_MY: 'tickets-listing-my',
		TICKETS_LISTING_UNASSIGNED: 'tickets-listing-unassigned',
		TICKETS_EDIT: 'tickets-edit',
		TICKETS_CHANGE_ASSIGNEE: 'tickets-edit-change-assignee',
		TICKETS_EDIT_ONLY_MY_TICKETS: 'tickets-edit-only-my-tickets',
		TASKS: 'tasks',
		MANAGEMENT: 'management',
		MANAGEMENT_DEPARTMENTS_READ: 'management-departments-read',
		MANAGEMENT_DEPARTMENTS_WRITE: 'management-departments',
		MANAGEMENT_TAGS_READ: 'management-tags-read',
		MANAGEMENT_TAGS_WRITE: 'management-tags',
		MANAGEMENT_CATEGORIES_READ: 'management-categories-read',
		MANAGEMENT_CATEGORIES_WRITE: 'management-categories',
		MANAGEMENT_GROUPS_READ: 'management-groups-read',
		MANAGEMENT_GROUPS_WRITE: 'management-groups',
		MANAGEMENT_USERS_READ: 'management-users-read',
		MANAGEMENT_USERS_WRITE: 'management-users',
		MANAGEMENT_ROLES_READ: 'management-roles-read',
		MANAGEMENT_ROLES_WRITE: 'management-roles',
		MANAGEMENT_SURVEYS_READ: 'management-surveys-read',
		MANAGEMENT_SURVEYS_WRITE: 'management-surveys',
		REPORTS: 'reports',
		REPORTS_GROUP: 'reports-group',
		REPORTS_CATEGORY: 'reports-category',
		REPORTS_USER: 'reports-user',
		REPORTS_TRANSFERS: 'reports-transfers',
		REPORTS_MERCHANT: 'reports-merchants'
	});
});