define('tickets/services/page-visibility', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var VISIBLE_VISIBILITY_STATE = 'visible';

	exports.default = Ember.Service.extend({
		isVisible: function isVisible() {
			var _document = document,
			    visibilityState = _document.visibilityState;


			return visibilityState === VISIBLE_VISIBILITY_STATE;
		}
	});
});