define('tickets/components/comments-tab/public', ['exports', 'tickets/utils/regex'], function (exports, _regex) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _extends = Object.assign || function (target) {
		for (var i = 1; i < arguments.length; i++) {
			var source = arguments[i];

			for (var key in source) {
				if (Object.prototype.hasOwnProperty.call(source, key)) {
					target[key] = source[key];
				}
			}
		}

		return target;
	};

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Component.extend({
		modelName: 'externalComment',
		comments: [],
		commentsSortParams: ['createdAt:asc'],
		sortedComments: Ember.computed.sort('comments', 'commentsSortParams'),
		uploadPending: false,
		disableRecipientsEdit: false,
		newExternalComment: null,
		validations: {},
		hiddenCommentSources: [],
		latestCommentDetails: Ember.computed('comments.lastObject.details', function () {
			return this.get('comments.lastObject.details') || {};
		}),
		ticketChannelDetails: Ember.computed('ticketChannel.details', function () {
			return this.get('ticketChannel.details') || {};
		}),
		emailsList: Ember.computed('originEmail', 'group.externalCommunicationEmails', 'department.externalCommunicationEmails', function () {
			var sortedUniqueEmailsArray = this._generateEmailList();
			var emailOptions = this._generateEmailOptions(sortedUniqueEmailsArray);
			this.set('newExternalComment.from', this._resolveDefaultFromEmail(emailOptions));

			return emailOptions;
		}),
		_generateEmailList: function _generateEmailList() {
			var originEmail = this.get('originEmail');
			var originEmailArray = [];
			if (originEmail) {
				originEmailArray = Array.isArray(originEmail) ? originEmail : [originEmail];
			}
			var groupCommunicationEmails = this.get('group.externalCommunicationEmails') || [];
			var departmentCommunicationEmails = this.get('department.externalCommunicationEmails') || [];
			return [].concat(_toConsumableArray(new Set([].concat(_toConsumableArray(groupCommunicationEmails), _toConsumableArray(departmentCommunicationEmails), _toConsumableArray(originEmailArray)))));
		},
		_generateEmailOptions: function _generateEmailOptions() {
			var emails = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
			return emails.map(function (email) {
				return {
					value: email
				};
			});
		},
		_resolveDefaultFromEmail: function _resolveDefaultFromEmail() {
			var emails = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

			var fromValue = this._getFromValue();

			if (emails.length) {
				if (fromValue && emails.includes(fromValue)) {
					return fromValue;
				}
				return emails[0].value;
			}
			return undefined;
		},
		_getFromValue: function _getFromValue() {
			var from = this.get('newExternalComment.from');
			return (typeof from === 'undefined' ? 'undefined' : _typeof(from)) === 'object' ? from.value : from;
		},
		to: Ember.computed('latestCommentDetails', {
			get: function get() {
				var _get = this.get('latestCommentDetails'),
				    _get$from = _get.from,
				    from = _get$from === undefined ? [] : _get$from,
				    _get$to = _get.to,
				    to = _get$to === undefined ? [] : _get$to;

				return this.excludeForbiddenRecipients([].concat(_toConsumableArray(from), _toConsumableArray(to)));
			},
			set: function set(key, value) {
				return value;
			}
		}),
		cc: Ember.computed('latestCommentDetails', 'watchers', {
			get: function get() {
				var _get2 = this.get('latestCommentDetails'),
				    _get2$cc = _get2.cc,
				    cc = _get2$cc === undefined ? [] : _get2$cc;

				var watchers = this.get('watchers') || [];

				return [].concat(_toConsumableArray(this.excludeForbiddenRecipients(cc)), _toConsumableArray(watchers));
			},
			set: function set(key, value) {
				return value;
			}
		}),
		availableRecipients: Ember.computed('ticketChannelDetails', 'watchers', 'comments.@each.{details}', function () {
			var _get3 = this.get('ticketChannelDetails'),
			    customerEmail = _get3.customerEmail,
			    _get3$originEmails = _get3.originEmails,
			    originEmails = _get3$originEmails === undefined ? [] : _get3$originEmails,
			    _get3$carbonCopyEmail = _get3.carbonCopyEmails,
			    cc = _get3$carbonCopyEmail === undefined ? [] : _get3$carbonCopyEmail;

			var watchers = this.get('watchers') || [];
			var prevRecipients = this.comments.reduce(function (recipients, _ref) {
				var details = _ref.details;

				var _ref2 = details || {},
				    _ref2$to = _ref2.to,
				    to = _ref2$to === undefined ? [] : _ref2$to,
				    _ref2$cc = _ref2.cc,
				    commentCC = _ref2$cc === undefined ? [] : _ref2$cc;

				return [].concat(_toConsumableArray(recipients), _toConsumableArray(to), _toConsumableArray(commentCC));
			}, []);
			var allRecipients = [].concat(_toConsumableArray(originEmails), _toConsumableArray(cc), _toConsumableArray(watchers), _toConsumableArray(prevRecipients), [customerEmail]);
			var availableRecipients = this.excludeForbiddenRecipients(allRecipients);

			return availableRecipients.compact().uniq();
		}),
		forbiddenRecipients: Ember.computed('ticketChannelDetails', function () {
			var _get4 = this.get('ticketChannelDetails'),
			    originEmail = _get4.originEmail,
			    aliasEmail = _get4.aliasEmail;

			return [originEmail, aliasEmail];
		}),
		editableCC: Ember.computed('availableRecipients', 'to.[]', function () {
			var _this = this;

			var availableRecipients = this.get('availableRecipients');

			return availableRecipients.reject(function (r) {
				return _this.get('to').includes(r);
			});
		}),
		editableTo: Ember.computed('availableRecipients', 'cc.[]', function () {
			var _this2 = this;

			var availableRecipients = this.get('availableRecipients');

			return availableRecipients.reject(function (r) {
				return _this2.get('cc').includes(r);
			});
		}),
		excludeForbiddenRecipients: function excludeForbiddenRecipients(recipients) {
			var forbiddenRecipients = this.get('forbiddenRecipients');

			return recipients.filter(_regex.testEmail).reject(function (r) {
				return forbiddenRecipients.includes(r);
			});
		},

		actions: {
			addComment: function addComment() {
				var _getProperties = this.getProperties('to', 'cc'),
				    to = _getProperties.to,
				    cc = _getProperties.cc;

				var from = this._getFromValue();
				var toNotEmpty = !!to.length;
				var ccNotEmpty = !!cc.length;

				this.sendAction('addComment', _extends({
					text: this.get('newExternalComment.text'),
					attachments: this.get('newCommentAttachments')
				}, (toNotEmpty || ccNotEmpty) && {
					details: _extends({}, toNotEmpty && { to: to }, ccNotEmpty && { cc: cc })
				}, {
					from: from
				}));
			},
			discardComment: function discardComment() {
				this.sendAction('clearNewCommentAttachments');
				this.sendAction('clearNewComment', this.modelName);
			},
			getAttachment: function getAttachment() {
				this.sendAction.apply(this, ['getAttachment'].concat(Array.prototype.slice.call(arguments)));
			},
			deleteAttachment: function deleteAttachment() {
				this.sendAction.apply(this, ['deleteAttachment'].concat(Array.prototype.slice.call(arguments)));
			},
			upload: function upload(file) {
				this.sendAction('upload', file, true);
			},
			uploadError: function uploadError(error) {
				this.sendAction('uploadError', error);
			},
			updateRecipients: function updateRecipients(recipientsField, recipients) {
				var emailRecipients = recipients.filter(_regex.testEmail);

				this.set(recipientsField, emailRecipients);
			}
		}
	});
});