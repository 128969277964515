define('tickets/models/external-comment', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo,
	    hasMany = _emberData.default.hasMany;


	var validations = (0, _emberCpValidations.buildValidations)({
		text: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('length', { max: 50000 })]
	});

	exports.default = _emberData.default.Model.extend(validations, {
		text: attr('string'),
		from: attr('string'),
		createdAt: attr('date'),
		details: attr(),
		user: belongsTo('user'),
		ticket: belongsTo('ticket'),
		sourceType: belongsTo('sourceType'),
		attachments: hasMany('attachment')
	});
});