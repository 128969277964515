define('tickets/serializers/user', ['exports', 'ember-data', 'tickets/serializers/application'], function (exports, _emberData, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
		attrs: {
			groups: { serialize: 'ids', deserialize: 'records' },
			role: { serialize: 'ids', deserialize: 'records', key: 'roleId' }
		}
	});
});