define('tickets/components/callers-section', ['exports', 'tickets/enums/timezones', 'tickets/enums/verificationTypes', 'tickets/utils/validate-email'], function (exports, _timezones, _verificationTypes, _validateEmail) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	var computed = Ember.computed;


	var EMAIL_VALIDATION_MESSAGE = 'Please enter a valid email';
	var TIMEZONES = Object.entries(_timezones.default).map(function (_ref) {
		var _ref2 = _slicedToArray(_ref, 2),
		    key = _ref2[0],
		    value = _ref2[1];

		return { name: key, value: value };
	});
	var VERIFICATION_TYPES = Object.entries(_verificationTypes.default).map(function (_ref3) {
		var _ref4 = _slicedToArray(_ref3, 2),
		    key = _ref4[0],
		    value = _ref4[1];

		return { name: key, value: value };
	});

	exports.default = Ember.Component.extend({
		readonly: false,
		timezones: TIMEZONES,
		verificationTypes: VERIFICATION_TYPES,
		phoneTypes: [{ type: 'Businessline' }, { type: 'Cell' }, { type: 'Home' }, { type: 'Office' }, { type: 'Voicemail' }],
		callers: [],
		validateCallerEmails: computed('callers.@each.{email}', function () {
			var callers = this.get('callers');

			var validationCollection = callers.map(function (_ref5) {
				var email = _ref5.email;

				var isEmailFieldValid = (email || '').split(';').filter(function (mail) {
					return !!mail;
				}).every(_validateEmail.default);
				var isEmailFieldEmpty = !email;

				return {
					isValid: isEmailFieldEmpty || isEmailFieldValid,
					message: EMAIL_VALIDATION_MESSAGE
				};
			});

			this.ticket.set('isCallersEmailsValid', validationCollection.every(function (_ref6) {
				var isValid = _ref6.isValid;
				return isValid;
			}));

			return validationCollection;
		}),
		actions: {
			add: function add() {
				this.sendAction('addCaller');
			},
			remove: function remove(caller) {
				this.sendAction('removeCaller', caller);
			}
		}
	});
});