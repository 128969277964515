define('tickets/services/change-logs/task', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (log) {
		var oldValue = JSON.parse(log.get('oldValue'));
		var newValue = JSON.parse(log.get('newValue'));

		return {
			displayAttribute: log.get('displayAttribute'),
			createdAt: log.get('createdAt'),
			user: log.get('user'),
			oldValue: getTaskValue(oldValue),
			newValue: getTaskValue(newValue)
		};
	};

	/**
  * Get task change value
  * @param  {Object} value Task change values
  * @returns {string|null} Task formatted value or null
  */
	function getTaskValue(value) {
		var _ref = value || {},
		    subject = _ref.subject,
		    taskId = _ref.taskId;

		return subject && taskId ? '<a href="#/tasks/' + taskId + '?parentRoute=tickets">Go to task #' + taskId + ': ' + subject + '</a>' : null;
	} /**
    * Formats task change log
    * @param {Object} log Change log
    * @returns {Object} Ticket requested change log
    */
});