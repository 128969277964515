define('tickets/helpers/join', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.join = join;

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  /**
   * Returns array items joined using provided separator.
   * @param {Array} options Array of arguments
   * @returns {String} Joined array items
   */
  function join(_ref) {
    var _ref2 = _toArray(_ref),
        options = _ref2.slice(0);

    var _options$reverse = options.reverse(),
        _options$reverse2 = _toArray(_options$reverse),
        separator = _options$reverse2[0],
        arrays = _options$reverse2.slice(1);

    var array = [].concat.apply([], arrays).reverse().compact().uniq();

    return Ember.String.htmlSafe(array.join(separator));
  }

  exports.default = Ember.Helper.helper(join);
});