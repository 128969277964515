define('tickets/components/field-title-tooltip', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var ICON_TEMPLATE = '<i class="icon exclamation info circle"></i>';

	exports.default = Ember.Component.extend({
		title: ICON_TEMPLATE,
		text: ''
	});
});