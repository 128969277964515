define('tickets/serializers/ticket', ['exports', 'ember-data', 'tickets/serializers/application', 'tickets/enums/ticketRelations', 'tickets/enums/ticketRelationTypes'], function (exports, _emberData, _application, _ticketRelations, _ticketRelationTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
		relatedPhones: Ember.inject.service(),
		attrs: {
			ticketStatus: { serialize: 'ids', deserialize: 'records', key: 'ticketStatusId' },
			priorityType: { serialize: 'ids', deserialize: 'records', key: 'priorityTypeId' },
			customer: { serialize: 'ids', deserialize: 'records', key: 'customerId' },
			department: { serialize: 'ids', deserialize: 'records', key: 'departmentId' },
			group: { serialize: 'ids', deserialize: 'records', key: 'groupId' },
			attachments: { serialize: 'ids', deserialize: 'records' },
			tasks: { serialize: 'ids', deserialize: 'records' },
			tags: { serialize: 'ids', deserialize: 'records' },
			relatedTickets: { serialize: 'ids', deserialize: 'records' },
			externalComments: { serialize: false, deserialize: 'records' },
			channel: { embedded: 'always' }
		},
		normalize: function normalize(model, hash) {
			if (hash.relatedTickets && hash.relationTickets) {
				var ticketId = hash.id;
				var relatedTickets = hash.relatedTickets.map(function (relation) {
					return createRelation(relation, ticketId);
				});
				var relationTickets = hash.relationTickets.map(function (relation) {
					return createRelation(relation, ticketId, true);
				});

				hash.relatedTickets = relatedTickets.concat(relationTickets);
				delete hash.relationTickets;
			}

			return this._super.apply(this, arguments);
		},
		serialize: function serialize(snapshot) {
			var json = this._super.apply(this, arguments);

			json.relatedPhones = this.relatedPhones.buildTicketRelatedPhones(snapshot, json.callers);

			return json;
		}
	});


	/**
  * Modifies backend ticket to ticket relation to frontend ticket to ticket relation
  *
  * @param {Object} relation Ticket to ticket relation from backend
  * @param {Number} currentTicketId Serializing ticket Id
  * @param {Boolean} inverseRelation Inverse relation flag
  * @returns {Object} Updated ticket to ticket relation
  */
	function createRelation(_ref, currentTicketId) {
		var id = _ref.id,
		    createdAt = _ref.createdAt,
		    ticketId = _ref.ticketId,
		    relatedTicketId = _ref.relatedTicketId,
		    ticketRelationTypeId = _ref.ticketRelationTypeId;
		var inverseRelation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

		switch (ticketRelationTypeId) {
			case _ticketRelationTypes.default.DUPLICATES:
				return {
					id: id,
					createdAt: createdAt,
					ticketId: inverseRelation ? relatedTicketId : ticketId,
					relatedTicketId: inverseRelation ? ticketId : relatedTicketId,
					ticketRelationTypeId: inverseRelation ? _ticketRelations.default.DUPLICATES : _ticketRelations.default.DUPLICATED
				};
			default:
				return {
					id: id,
					createdAt: createdAt,
					ticketId: ticketId === currentTicketId ? relatedTicketId : ticketId,
					relatedTicketId: relatedTicketId === currentTicketId ? ticketId : relatedTicketId,
					ticketRelationTypeId: _ticketRelations.default.RELATED
				};
		}
	}
});