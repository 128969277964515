define('tickets/components/generic-table', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		isContentLoading: false,
		enableDeleteRows: false,
		users: Ember.inject.service(),
		items: null,
		showPagination: true,
		columns: null,
		tagName: '',
		page: null,
		limit: null,
		pageCount: null,
		rows: Ember.computed('items.[]', function () {
			var _this = this;

			var selected = this.get('selectedRowIds');

			return this.get('items').map(function (i) {
				return _this.createItemProxy(i, selected);
			});
		}),
		createItemProxy: function createItemProxy(item, selected) {
			return Ember.ObjectProxy.create({
				content: item,
				isSelected: selected ? selected.includes(item.id) : false
			});
		},

		actions: {
			selectItem: function selectItem(id) {
				this.sendAction('selectItem', id);
			},
			gotoPageNumber: function gotoPageNumber(props) {
				var _this2 = this;

				Ember.run.next(function () {
					return _this2.sendAction('gotoPageNumber', props);
				});
			},
			sortTable: function sortTable(sortOptions) {
				this.sendAction('sortTable', sortOptions);
			},
			deleteRow: function deleteRow(value) {
				this.sendAction('deleteRow', value);
			}
		}
	});
});