define('tickets/routes/departments/edit', ['exports', 'tickets/mixins/routes/departments', 'tickets/mixins/routes/edit'], function (exports, _departments, _edit) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_departments.default, _edit.default, {
		queryDepartment: function queryDepartment(params) {
			return this.getModel('department', params.id);
		}
	});
});