define('tickets/resources/dealersTicketTexts', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		fp: {
			placeholders: {
				serialNumber: 'Software serial #. Put N/A if not applicable'
			}
		},
		rm: {
			placeholders: {
				serialNumber: 'Serial Number'
			}
		}
	});
});