define('tickets/services/change-logs/publicConversation', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (log) {
		var newValue = JSON.parse(log.get('newValue')) || {};

		var _ref = newValue.details || {},
		    cc = _ref.cc,
		    to = _ref.to,
		    from = _ref.from;

		var details = {
			cc: Array.isArray(cc) ? cc.join(', ') : cc,
			to: Array.isArray(to) ? to.join(', ') : to,
			from: from
		};

		return {
			displayAttribute: log.get('displayAttribute'),
			createdAt: log.get('createdAt'),
			user: log.get('user'),
			newValue: _extends({}, newValue, { userName: log.get('user.userName'), createdAt: log.get('createdAt'), details: details }),
			template: 'public-conversation'
		};
	};

	var _extends = Object.assign || function (target) {
		for (var i = 1; i < arguments.length; i++) {
			var source = arguments[i];

			for (var key in source) {
				if (Object.prototype.hasOwnProperty.call(source, key)) {
					target[key] = source[key];
				}
			}
		}

		return target;
	};
});