define('tickets/routes/tags/edit', ['exports', 'tickets/mixins/routes/edit', 'tickets/mixins/routes/tags'], function (exports, _edit, _tags) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_tags.default, _edit.default, {
		queryTag: function queryTag(params) {
			return this.getModel('tag', params.id);
		}
	});
});