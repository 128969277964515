define('tickets/components/radiogroup-field', ['exports', 'tickets/components/input-field'], function (exports, _inputField) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = _inputField.default.extend({
		selected: null,
		options: [],
		description: '',
		radioGroupName: '',
		mapProperty: 'value',
		displayProperty: 'title',
		titleTooltipText: '',
		actions: {
			selectOption: function selectOption() {
				for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
					args[_key] = arguments[_key];
				}

				this.sendAction.apply(this, ['selectOption'].concat(_toConsumableArray(args)));
			}
		}
	});
});