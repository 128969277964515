define('tickets/helpers/ends-with', ['exports', '@abcum/ember-helpers/helpers/ends-with'], function (exports, _endsWith) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _endsWith.default;
    }
  });
  Object.defineProperty(exports, 'endsWith', {
    enumerable: true,
    get: function () {
      return _endsWith.endsWith;
    }
  });
});