define('tickets/helpers/ticket-priority', ['exports', 'tickets/enums/priorityTypes'], function (exports, _priorityTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.rowPriority = rowPriority;

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	var _PRIORITY_MAP;

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	var BLOCKER = _priorityTypes.default.BLOCKER,
	    HIGH = _priorityTypes.default.HIGH,
	    MEDIUM = _priorityTypes.default.MEDIUM,
	    LOW = _priorityTypes.default.LOW;

	var PRIORITY_MAP = (_PRIORITY_MAP = {}, _defineProperty(_PRIORITY_MAP, BLOCKER, 'blocker-priority'), _defineProperty(_PRIORITY_MAP, HIGH, 'high-priority'), _defineProperty(_PRIORITY_MAP, MEDIUM, 'medium-priority'), _defineProperty(_PRIORITY_MAP, LOW, 'low-priority'), _PRIORITY_MAP);

	/**
  * Returns corresponding color.
  * @param {int} priorityId of current ticket
  * @returns {int} className.
  */
	function rowPriority(_ref) {
		var _ref2 = _slicedToArray(_ref, 1),
		    priorityId = _ref2[0];

		return PRIORITY_MAP[priorityId];
	}

	exports.default = Ember.Helper.helper(rowPriority);
});