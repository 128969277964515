define('tickets/services/change-logs/delayedResponse', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (_ref) {
    var user = _ref.user,
        attribute = _ref.attribute,
        createdAt = _ref.createdAt;

    return { user: user, attribute: attribute, createdAt: createdAt, text: 'sent email notification "Ticket not claimed within 72 Hours"' };
  };
});