define('tickets/adapters/task', ['exports', 'tickets/adapters/application', 'tickets/config/environment'], function (exports, _application, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		urlForQuery: function urlForQuery(query) {
			if (query.type) {
				return _environment.default.host + '/api/v1/tasks/' + query.type;
			}

			return this._super.apply(this, arguments);
		}
	});
});