define('tickets/routes/groups/edit/general', ['exports', 'tickets/mixins/routes/groups'], function (exports, _groups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_groups.default, {
		model: function model() {
			return Ember.RSVP.hash(this.modelFor('groups.edit'));
		}
	});
});