define('tickets/services/upload', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		ajax: Ember.inject.service(),
		upload: function upload(url, file) {
			var absoluteUrl = '/api/v1/' + url;
			var data = new FormData();
			data.append(file.name, file);

			return this.get('ajax').request(absoluteUrl, {
				data: data,
				method: 'POST',
				cache: false,
				processData: false,
				contentType: false
			}).catch(function (e) {
				throw e.payload;
			});
		}
	});
});