define('tickets/routes/groups/edit', ['exports', 'tickets/mixins/routes/edit', 'tickets/mixins/routes/groups'], function (exports, _edit, _groups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_groups.default, _edit.default, {
		queryGroup: function queryGroup(params) {
			return this.getModel('group', params.id);
		}
	});
});