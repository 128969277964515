define('tickets/controllers/tasks/edit', ['exports', 'tickets/mixins/controllers/save', 'tickets/mixins/controllers/tasks/edit'], function (exports, _save, _edit) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed;
	exports.default = Ember.Controller.extend(_save.default, _edit.default, {
		modelName: 'task',
		queryParams: ['parentRoute'],
		showAssociatedTicketLink: true,
		task: computed.alias('model.task'),
		actions: {
			save: function save() {
				var _this = this;

				this._super().catch(function (validations) {
					return _this.set('validations', validations);
				});
			}
		}
	});
});