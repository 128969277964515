define('tickets/components/date-time-picker', ['exports', 'moment'], function (exports, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var DATE_TIME_FORMAT = 'MM/DD/YY hh:mm A';
	var DATE_FORMAT = 'MM/DD/YY';
	var PREVIOUS_DATES_RANGE = {
		'start': (0, _moment.default)('01/01/1970', DATE_FORMAT),
		'end': (0, _moment.default)().subtract(1, 'days')
	};

	exports.default = Ember.Component.extend({
		classNames: ['input-in calendar'],
		classNameBindings: ['focused:active-box'],
		focused: false,
		description: '',
		date: '',
		disablePastDates: false,
		value: Ember.computed('date', function () {
			var date = this.get('date');

			return date && (0, _moment.default)(date).format(DATE_TIME_FORMAT);
		}),
		didInsertElement: function didInsertElement() {
			var _this = this;

			this.$('.date-time-picker').calentim({
				disabledRanges: this.disablePastDates ? [PREVIOUS_DATES_RANGE] : [],
				format: DATE_TIME_FORMAT,
				startEmpty: true,
				singleDate: true,
				calendarCount: 1,
				oneCalendarWidth: 280,
				minuteSteps: 15,
				showHeader: false,
				showFooter: false,
				showOn: 'bottom',
				container: this.$('.calentim-unique-container'),
				onaftershow: function onaftershow() {
					return _this.set('focused', true);
				},
				onafterhide: function onafterhide() {
					_this.updateDate();
					_this.set('focused', false);
				},
				onafterselect: function onafterselect() {
					return _this.updateDate();
				},
				ontimechange: function ontimechange() {
					return _this.updateDate();
				}
			});
		},
		updateDate: function updateDate() {
			var inputValue = new Date(this.$('.date-time-picker').val());
			var date = (0, _moment.default)(inputValue).isValid() ? inputValue : null;

			this.sendAction('setTime', date);
		}
	});
});