define('tickets/validators/unique-tag-name', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var UniqueTagName = _base.default.extend({
		tags: Ember.inject.service(),
		validate: function validate(value, options, model) {
			var tagId = model.get('id');
			var lowerCaseValue = value.toLowerCase();
			var tagNames = this.tags.getAll().rejectBy('id', tagId).mapBy('name');
			var duplicatedName = tagNames.any(function (name) {
				return name.toLowerCase() === lowerCaseValue;
			});

			return duplicatedName ? 'Tag ' + value + ' already exists' : true;
		}
	});

	exports.default = UniqueTagName;
});