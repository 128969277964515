define('tickets/models/category', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed;
	var attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo,
	    hasMany = _emberData.default.hasMany;
	exports.default = _emberData.default.Model.extend({
		name: attr('string'),
		isActive: attr('boolean', { defaultValue: true }),
		brand: belongsTo('brand'),
		parent: belongsTo('category', { inverse: 'children' }),
		children: hasMany('category', { inverse: 'parent' }),
		activeChildren: computed('children.[]', 'children.@each.isActive', function () {
			return this.get('children') && this.get('children').rejectBy('isActive', false);
		})
	});
});