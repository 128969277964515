define('tickets/abilities/ticketing-system', ['exports', 'tickets/mixins/ability', 'tickets/enums/userTypes'], function (exports, _ability, _userTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _ability.default.extend({
		canAccess: Ember.computed('userType', function () {
			return this.userType === _userTypes.default.GOOGLE;
		})
	});
});