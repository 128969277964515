define('tickets/models/permission', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo,
	    hasMany = _emberData.default.hasMany;
	exports.default = _emberData.default.Model.extend({
		name: attr('string'),
		order: attr('number'),
		children: hasMany('permission', { inverse: 'parent' }),
		parent: belongsTo('permission', { inverse: 'children' })
	});
});