define('tickets/components/customer-information', ['exports', 'tickets/components/input-field'], function (exports, _inputField) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	var computed = Ember.computed,
	    observer = Ember.observer,
	    $ = Ember.$;
	var _Ember$run = Ember.run,
	    next = _Ember$run.next,
	    later = _Ember$run.later;


	var ENTER_CODE = 13;
	var ARROW_DOWN = 40;
	var ARROW_UP = 38;
	var FOCUS_HANDLER_DELAY_TIME = 10;
	var MIN_SEARCH_TERM_LENGTH = 3;
	var SEARCH_TERM_ERROR_MESSAGE = 'This field should contain at least ' + MIN_SEARCH_TERM_LENGTH + ' symbols';
	var DEFAULT_SEARCH_FIELD = 'all';

	exports.default = _inputField.default.extend({
		editTicketStorage: Ember.inject.service(),
		title: 'Additional customer data',
		tagName: 'div',
		searchTermSelector: '.search-term input',
		searchTerm: '',
		phoneSearchTerm: '',
		prevSearchTerm: '',
		searchField: 'all',
		showSearchTermLengthError: false,
		customerSearchResults: [],
		highlightedResults: computed('customerSearchResults.meta.highlighted.[]', function () {
			var _this = this;

			var highlightedFields = this.customerSearchResults.get('meta.highlighted') || [];

			return this.customerSearchResults.map(function (customer) {
				var _highlightedFields$fi = _slicedToArray(highlightedFields.findBy('id', customer.id).fields, 1),
				    firstHighlightedField = _highlightedFields$fi[0];

				customer.set('field', _this.getFieldByValue(firstHighlightedField));

				return customer;
			});
		}),
		assignCustomerName: 'assign-customer',
		searchFields: [{ text: 'All', value: 'all' }, { text: 'MID', value: 'mId' }, { text: 'Office ID', value: 'officeId' }, { text: 'Office Name', value: 'officeName' }, { text: 'File ID', value: 'fileId' }, { text: 'Serial', value: 'serialId' }, { text: 'Site ID', value: 'siteId' }, { text: 'Customer ID', value: 'customerId' }, { text: 'DBA Name', value: 'customerName' }, { text: 'Legal Name', value: 'legalName' }, { text: 'Email', value: 'email' }, { text: 'Phone', value: 'phone' }, { text: 'Federal Tax ID', value: 'federalTaxId' }, { text: 'Owner Name', value: 'primaryOwnerName' }, { text: 'Owner Name 2', value: 'secondaryOwnerName' }, { text: 'PNS Number', value: 'pnsNumber' }, { text: 'Address', value: 'address' }, { text: 'Shift4 MID', value: 'shift4Mid' }],
		hiddenSearchFields: [{ text: 'Associated Phone Numbers', value: 'associatedPhoneNumbers' }],
		searchFieldObserver: observer('searchField', function () {
			var _this2 = this;

			this.setCustomerSearchPreset();
			next(function () {
				return $(_this2.searchTermSelector).select();
			});
		}),
		didInsertElement: function didInsertElement() {
			var searchField = this.getCustomerSearchPreset();
			this.set('searchField', searchField);
			this.set('customerSearchResults', []);
			this.set('prevSearchTerm', this.get('searchTerm'));
			$(this.searchTermSelector).focus();

			if (this.phoneSearchTerm) {
				this.set('searchField', 'phone');
				this.set('searchTerm', this.phoneSearchTerm);
				this.search();
			}
		},

		highlightError: computed('validation', 'customer.isFulfilled', 'showSearchTermLengthError', function () {
			var validation = this.get('validation');
			var customer = this.get('customer');

			return validation && !validation.isValid && (!customer || customer.isFulfilled) || this.showSearchTermLengthError;
		}),
		errorMessage: computed('validation', function () {
			return this.get('validation.message') || SEARCH_TERM_ERROR_MESSAGE;
		}),
		searchResultsObserver: observer('customerSearchResults.isFulfilled', function () {
			var _this3 = this;

			var customerSearchResults = this.get('customerSearchResults');

			if (customerSearchResults.isFulfilled) {
				next(function () {
					return $(_this3.searchTermSelector).focus();
				});
			}
		}),
		search: function search() {
			var term = this.get('searchTerm').trim();
			var field = this.get('searchField');

			if (Ember.isBlank(term)) {
				return;
			}

			if (term.length < MIN_SEARCH_TERM_LENGTH) {
				return this.set('showSearchTermLengthError', true);
			}

			this.set('showSearchTermLengthError', false);
			this.set('prevSearchTerm', term);
			this.sendAction('searchCustomer', { field: field, term: term });
		},
		select: function select(customer) {
			if (this.get('customer.id') !== customer.get('id')) {
				this.sendAction('selectCustomer', customer.id);
			}
		},
		showItems: function showItems() {
			if (this.isDestroyed) {
				return;
			}

			this.set('focused', true);

			if (this.get('searchTerm') && this.get('customerSearchResults.isFulfilled')) {
				$('.dropdown-cell .search-results').show();
			}
		},
		hideItems: function hideItems() {
			if (this.get('element')) {
				this.set('focused', false);
				$('.search-results').hide();
				$('.search-results .result.selected').removeClass('selected');
			}
		},
		selectSiblingResult: function selectSiblingResult(direction) {
			var selectedItem = $('.search-results .result.selected');

			if (!selectedItem.length) {
				$('.search-results .result').first().addClass('selected');
			} else {
				var sibling = selectedItem[direction]();

				if (sibling.length) {
					selectedItem.removeClass('selected');
					sibling.addClass('selected');
				}
			}

			selectedItem = $('.search-results .result.selected')[0];
			selectedItem && selectedItem.scrollIntoView(false);
		},
		setCustomerSearchPreset: function setCustomerSearchPreset() {
			this.editTicketStorage.set({ customerSearchField: this.get('searchField') });
		},
		getCustomerSearchPreset: function getCustomerSearchPreset() {
			var isCustomerSet = Boolean(this.get('customer.customerId'));
			var storagePreset = this.editTicketStorage.get('customerSearchField');

			if (!isCustomerSet && storagePreset) {
				return storagePreset;
			}

			return DEFAULT_SEARCH_FIELD;
		},
		getFieldByValue: function getFieldByValue(value) {
			return this.searchFields.findBy('value', value) || this.hiddenSearchFields.findBy('value', value);
		},

		actions: {
			search: function search() {
				return this.search();
			},
			onKeyup: function onKeyup(event) {
				switch (event.keyCode) {
					case ENTER_CODE:
						if (this.get('customerSearchResults.length')) {
							var selectedItem = $('.search-results .result.selected');

							if (selectedItem.length) {
								selectedItem.click();
								this.hideItems();
							} else {
								this.search();
							}
						} else {
							this.search();
						}
						break;
					case ARROW_UP:
						this.selectSiblingResult('prev');
						break;
					case ARROW_DOWN:
						this.selectSiblingResult('next');
						break;
					default:
						if (this.get('prevSearchTerm') !== this.get('searchTerm')) {
							this.sendAction('clearCustomerData');
						}
				}
			},
			focusIn: function focusIn() {
				var _this4 = this;

				later(function () {
					$(_this4.searchTermSelector).select();
					_this4.showItems();

					$('html body').one('click', function (e) {
						var target = $(e.target);

						if (target.is($(_this4.searchTermSelector))) {
							$(_this4.searchTermSelector).focus();
						} else if (target.is($('.search-field input')) || target.closest($('.search-field')).length) {
							_this4.hideItems();
							_this4.set('focused', true);
						} else {
							_this4.hideItems();
						}
					});
				}, FOCUS_HANDLER_DELAY_TIME);
			},
			openResults: function openResults() {
				$(this.searchTermSelector).focus();
			},
			onSelectCustomer: function onSelectCustomer(customer) {
				this.select(customer);
			}
		}
	});
});