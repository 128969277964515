define('tickets/services/tags', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		ajax: Ember.inject.service(),
		store: Ember.inject.service(),
		fetch: function fetch() {
			var _this = this;

			this.ajax.request('/api/v1/tags/all').then(function (tags) {
				return _this.store.pushPayload(tags);
			});
		},
		getAll: function getAll() {
			return this.store.peekAll('tag').sortBy('name');
		}
	});
});