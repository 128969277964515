define('tickets/controllers/groups/edit/settings', ['exports', 'pluralize', 'tickets/mixins/controllers/groups/edit', 'tickets/mixins/controllers/groups/common', 'tickets/mixins/controllers/routing-tabs/common'], function (exports, _pluralize, _edit, _common, _common2) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_common2.default, _common.default, _edit.default, {
		globalSettings: Ember.inject.service(),
		enableReopenAfterCutoffDescription: Ember.computed('globalSettings.byKey', function () {
			var dayCount = this.globalSettings.byKey.ticketReopenCutoffDays;

			if (dayCount > 0) {
				return 'Ticket Reopen After ' + dayCount + ' ' + (0, _pluralize.default)('Day', dayCount);
			}

			return 'Ticket Reopen';
		}),
		unassignAfterReopenItems: [{ name: 'Reset assignee after reopen', value: true }, { name: 'Leave the ticket assigned after reopen', value: false }],
		enableReopenAfterCutoff: [{ name: 'Allow tickets to be reopened by customer email reply', value: true }, { name: 'Leave the tickets closed and send the customer an email notification to create a new ticket', value: false }],
		actions: {
			unassignAfterReopenChanged: function unassignAfterReopenChanged(value) {
				/** The dropdown field component returns string even if it has boolean values as options*/
				this.set('group.groupSettings.unassignAfterReopen', value === 'true');
			},
			enableReopenAfterCutoffChanged: function enableReopenAfterCutoffChanged(value) {
				/** The dropdown field component returns string even if it has boolean values as options*/
				this.set('group.groupSettings.enableReopenAfterCutoff', value === 'true');
			}
		}
	});
});