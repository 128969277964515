define('tickets/validators/customer', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Customer = _base.default.extend({
		validationMessage: 'Please select a customer',
		validate: function validate() {
			if (!this.get('model.customerName')) {
				return true;
			}

			if (this.get('model.customer.id')) {
				return true;
			}

			return this.validationMessage;
		}
	});

	Customer.reopenClass({
		getDependentsFor: function getDependentsFor() {
			return ['model.customerName'];
		}
	});

	exports.default = Customer;
});