define('tickets/components/sortable-cell', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: '',
		sortParam: '',
		sortQuery: '',
		direction: Ember.computed('sortQuery.{column,direction}', function () {
			var _get = this.get('sortQuery'),
			    column = _get.column,
			    direction = _get.direction;

			return column === this.get('sortParam') ? direction : '';
		}),
		actions: {
			sort: function sort() {
				this.sendAction('sortTable', {
					column: this.sortParam,
					direction: this.get('direction') === 'asc' ? 'desc' : 'asc'
				});
			}
		}
	});
});