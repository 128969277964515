define('tickets/routes/tickets/all', ['exports', 'tickets/mixins/routes/tickets/listing'], function (exports, _listing) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_listing.default, {
		afterModel: function afterModel(model, _ref) {
			var incomingCall = _ref.queryParams.incomingCall;

			this._super.apply(this, arguments);

			if (incomingCall && !model.tickets.length) {
				return this.transitionTo('tickets.create');
			}
		}
	});
});