define('tickets/services/users', ['exports', 'tickets/enums/userTypes'], function (exports, _userTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		store: Ember.inject.service(),
		ajax: Ember.inject.service(),
		self: null,
		brand: Ember.computed.alias('self.brand'),
		userName: Ember.computed.alias('self.userName'),
		permissions: Ember.computed.alias('self.role.permissions'),
		selfRank: Ember.computed.alias('self.role.roleRank.rank'),
		fetch: function fetch() {
			var _this = this;

			return this.store.queryRecord('user', { type: 'self' }).then(function (user) {
				return _this.set('self', user);
			}).then(function () {
				return _this.store.query('user', { type: 'all' });
			});
		},
		getAll: function getAll() {
			return this.store.peekAll('user').filterBy('userTypeId', _userTypes.default.GOOGLE).sortBy('userName');
		}
	});
});