define('tickets/components/dropdown-field', ['exports', 'tickets/components/input-field'], function (exports, _inputField) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var DEFAULT_MAP_PROPERTY = 'id';
	var DEFAULT_DISPLAY_PROPERTY = 'name';

	exports.default = _inputField.default.extend({
		addEmptyOption: false,
		emptyOptionText: '',
		fullTextSearch: 'exact',
		items: [],
		disabledItems: [],
		disableSorting: false,
		selectedDisplayValue: Ember.computed('selected', 'displayProperty', function () {
			var selected = this.selected;
			var displayProperty = this.displayProperty;

			if (!selected || !this.readonly) {
				return;
			}

			var selectedDisplayProperty = selected.get && selected.get(displayProperty);

			if (selectedDisplayProperty) {
				return selectedDisplayProperty;
			}

			var selectedItem = this.get('items').findBy(this.mapProperty || DEFAULT_MAP_PROPERTY, selected);

			return selectedItem ? selectedItem[displayProperty || DEFAULT_DISPLAY_PROPERTY] : '';
		})
	});
});