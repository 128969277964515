define('tickets/utils/deepEqual', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = deepEqual;

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	/**
  * Returns true if objects are equal.
  * @param {Object} first - First object to compare
  * @param {Object} second - Second object to compare
  * @returns {Boolean} - True if objects are equal
  */
	function deepEqual() {
		var first = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
		var second = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

		var firstProps = Object.keys(first);
		var secondProps = Object.keys(second);

		if (firstProps.length !== secondProps.length) {
			return false;
		}

		for (var i = 0; i < firstProps.length; i++) {
			var prop = firstProps[i];

			switch (_typeof(first[prop])) {
				case 'object':
					if (!deepEqual(first[prop], second[prop])) {
						return false;
					}
					break;
				default:
					if (first[prop] !== second[prop]) {
						return false;
					}
			}
		}

		return true;
	}
});