define('tickets/mixins/controllers/groups/common', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		modelName: 'group',
		parentRoute: 'groups',
		group: Ember.computed.alias('model.group')
	});
});