define('tickets/controllers/categories', ['exports', 'tickets/mixins/controllers/save'], function (exports, _save) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_save.default, {
		toast: Ember.inject.service(),
		categories: Ember.computed.alias('model.categories'),
		categoryLevels: ['Request Type', 'Product/Service', 'Category', 'Subcategory'],
		requestTypes: Ember.computed('categories', function () {
			return this.get('categories').rejectBy('parent.id').sortBy('name');
		}),
		actions: {
			add: function add(name, parentId) {
				var parent = parentId ? this.store.peekRecord('category', parentId) : null;
				var newCategory = this.store.createRecord('category', { name: name, parent: parent });

				this.save(newCategory);
			},
			update: function update(category) {
				this.save(category);
			},
			updateIsActive: function updateIsActive(_ref) {
				var item = _ref.item,
				    isChecked = _ref.isChecked;

				item.set('isActive', isChecked);
				this.save(item, false);
				this.toast.success(item.name + ' was ' + (isChecked ? 'enabled' : 'disabled') + ' successfully!');
			}
		}
	});
});