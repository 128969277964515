define('tickets/resources/groupPageTexts', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		tooltips: {
			brand: 'Communication Brand will be used to populate logo and company info in the outgoing communication',
			origin: 'Communication Brand Origin specifies the way how the Communication Brand (CB)\n\t\twill be applied to the outgoing communication.<br>\n\t\t<strong>Group</strong> will always populate CB with the one you\'ve selected above.<br>\n\t\t<strong>Database</strong> will take Customer\'s Brand from database or populate CB\n\t\twith the one you\'ve selected above if the Customer can\'t be found in the database.',
			priority: 'Set the default priority for the tickets that are created within this group',
			customerIdentification: 'Every time an email comes to this group system checks if there is a Customer related \n\t\tto this email. If you don\'t want to auto populate \n\t\tCustomer by email - select "Don\'t Identify Customer by Email" option.',
			teamName: 'Team Name is a variable which populates in signature of the outgoing emails.',
			unassignAfterReopen: 'Each email related to the Resolved ticket will reopen it. Select what should happen to the ticket assignee after reopening.'
		}
	});
});