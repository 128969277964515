define('tickets/services/ticket-channel-types', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		ajax: Ember.inject.service(),
		store: Ember.inject.service(),
		fetch: function fetch() {
			var _this = this;

			this.ajax.request('/api/v1/ticket-channel-types').then(function (type) {
				return _this.store.pushPayload(type);
			});
		},
		getAll: function getAll() {
			return this.store.peekAll('ticketChannelType').sortBy('name');
		}
	});
});