define('tickets/adapters/application', ['exports', 'ember-data', 'tickets/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _environment, _dataAdapterMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _extends = Object.assign || function (target) {
		for (var i = 1; i < arguments.length; i++) {
			var source = arguments[i];

			for (var key in source) {
				if (Object.prototype.hasOwnProperty.call(source, key)) {
					target[key] = source[key];
				}
			}
		}

		return target;
	};

	exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
		host: _environment.default.host,
		namespace: 'api/v1',
		session: Ember.inject.service(),
		ajaxService: Ember.inject.service('ajax'),
		authorize: function authorize(xhr) {
			var _get = this.get('session.data.authenticated'),
			    token = _get.token;

			if (Ember.isPresent(token)) {
				xhr.setRequestHeader('auth-token', token);
			}
		},
		ajax: function ajax(url, type, options) {
			return this.ajaxService.request(url, _extends({}, options, { type: type }));
		},
		shouldReloadRecord: function shouldReloadRecord() {
			return true;
		}
	});
});