define('tickets/components/multi-select', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: '',
		property: '',
		items: [],
		selected: [],
		disabled: false,
		unselected: Ember.computed.setDiff('items', 'selected'),
		sortOptions: Ember.computed('property', function () {
			return [this.get('property')];
		}),
		sortedUnselected: Ember.computed.sort('unselected', 'sortOptions'),
		sortedSelected: Ember.computed.sort('selected', 'sortOptions'),
		actions: {
			selectItem: function selectItem(item) {
				if (this.get('disabled')) {
					return;
				}
				var selected = this.get('selected').slice();
				selected.pushObject(item);
				this.sendAction('onChange', selected);
			},
			unselectItem: function unselectItem(item) {
				if (this.get('disabled')) {
					return;
				}
				var selected = this.get('selected').slice();
				selected.removeObject(item);
				this.sendAction('onChange', selected);
			},
			addAll: function addAll() {
				var selected = this.get('selected').slice();
				this.sendAction('onChange', selected.pushObjects(this.get('unselected')));
			},
			removeAll: function removeAll() {
				var selected = this.get('selected').slice();
				this.sendAction('onChange', selected.clear());
			}
		}
	});
});