define('tickets/mixins/sidebar', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var $ = Ember.$;
	exports.default = Ember.Mixin.create({
		menuOpened: true,
		menuContext: 'body',
		initSidebar: function initSidebar() {
			var _this = this;

			this.wrapSidebarAction(function () {
				$('.ui.sidebar').sidebar({
					context: $(_this.get('menuContext')),
					dimPage: false,
					closable: false,
					transition: 'overlay'
				});
			});
		},
		toggleSidebar: function toggleSidebar() {
			var _this2 = this;

			this.wrapSidebarAction(function () {
				$('.ui.sidebar').sidebar('toggle');
				_this2.toggleProperty('menuOpened');
			});
		},
		hideSidebar: function hideSidebar() {
			var _this3 = this;

			this.wrapSidebarAction(function () {
				$('.ui.sidebar').sidebar('hide');
				_this3.set('menuOpened', false);
			});
		},
		showSidebar: function showSidebar() {
			var _this4 = this;

			this.wrapSidebarAction(function () {
				$('.ui.sidebar').sidebar('show');
				_this4.set('menuOpened', true);
			});
		},
		wrapSidebarAction: function wrapSidebarAction(action) {
			Ember.run.next(function () {
				$.site('disable console');
				action();
				$.site('enable console');
			});
		}
	});
});