define('tickets/mixins/controllers/tags/common', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		modelName: 'tag',
		parentRoute: 'tags',
		tag: Ember.computed.alias('model.tag')
	});
});