define('tickets/services/meta-tags', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var DEFAULT_TITLE = 'Tickets';
	var TITLE_ID = 'meta-title-tag';

	exports.default = Ember.Service.extend({
		updateTitle: function updateTitle(title) {
			return _setHtmlById(TITLE_ID, title || DEFAULT_TITLE);
		},
		resetTitle: function resetTitle() {
			return _setHtmlById(TITLE_ID, DEFAULT_TITLE);
		}
	});


	/**
  * Sets inner html content into DOM element
  * @param {Number} id Dom element Id selector
  * @param {String} html Html string to be inserted in the DOM element
  * @returns {String} Html content that was set
  * @private
  */
	function _setHtmlById(id, html) {
		return document.getElementById(id).innerHTML = html;
	}
});