define('tickets/routes/tags/edit/general', ['exports', 'tickets/mixins/routes/tags'], function (exports, _tags) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_tags.default, {
		model: function model() {
			return Ember.RSVP.hash(this.modelFor('tags.edit'));
		}
	});
});