define('tickets/validators/unique-group-emails', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var UniqueGroupEmails = _base.default.extend({
		groups: Ember.inject.service(),
		validate: function validate() {
			var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
			    emails = _ref2.emails;

			var _ref3;

			var options = arguments[1];
			var _ref = arguments[2];
			var groupId = _ref.id;

			if (!emails) {
				return true;
			}

			var existingGroupsEmails = this.groups.getAll().rejectBy('id', groupId).mapBy('link.emails').compact().concat();
			var existingEmails = (_ref3 = []).concat.apply(_ref3, _toConsumableArray(existingGroupsEmails));
			var duplicatedEmails = emails.filter(function (email) {
				return existingEmails.includes(email.toLowerCase());
			});
			var count = duplicatedEmails.length;
			var duplicates = duplicatedEmails.join();

			if (!count) {
				return true;
			}

			if (count > 1) {
				return 'Group emails ' + duplicates + ' are used in other group';
			}

			return 'Group email ' + duplicates + ' is used in other group';
		}
	});

	exports.default = UniqueGroupEmails;
});