define('tickets/routes/users/index', ['exports', 'tickets/mixins/routes/ticketing-system', 'tickets/enums/requiredPermissions'], function (exports, _ticketingSystem, _requiredPermissions) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _extends = Object.assign || function (target) {
		for (var i = 1; i < arguments.length; i++) {
			var source = arguments[i];

			for (var key in source) {
				if (Object.prototype.hasOwnProperty.call(source, key)) {
					target[key] = source[key];
				}
			}
		}

		return target;
	};

	function _objectWithoutProperties(obj, keys) {
		var target = {};

		for (var i in obj) {
			if (keys.indexOf(i) >= 0) continue;
			if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
			target[i] = obj[i];
		}

		return target;
	}

	exports.default = Ember.Route.extend(_ticketingSystem.default, {
		requiredPermissions: _requiredPermissions.default.USERS,
		queryParams: {
			page: { refreshModel: true },
			limit: { refreshModel: true }
		},
		model: function model(_ref) {
			var page = _ref.page,
			    limit = _ref.limit,
			    rest = _objectWithoutProperties(_ref, ['page', 'limit']);

			return Ember.RSVP.hash({
				users: this.store.query('user', {
					page: page,
					limit: limit,
					filter: _extends({}, rest)
				})
			});
		},

		actions: {
			queryParamsDidChange: function queryParamsDidChange() {
				this._super.apply(this, arguments);

				this.refresh();
			}
		}
	});
});