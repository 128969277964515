define('tickets/components/change-logs', ['exports', 'tickets/mixins/modal', 'ember-data', 'tickets/enums/ticketChannelTypes'], function (exports, _modal, _emberData, _ticketChannelTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed,
	    RSVP = Ember.RSVP,
	    Component = Ember.Component;


	var INTERNAL_COMMENT_MODAL_TITLE = 'Internal Conversation';
	var PUBLIC_CONVERSATION_MODAL_TITLE = 'Public Conversation';

	exports.default = Component.extend(_modal.default, {
		changeLog: Ember.inject.service(),
		ticket: null,
		internalComment: null,
		internalCommentModalTitle: INTERNAL_COMMENT_MODAL_TITLE,
		internalCommentModalName: 'internal-comment',
		publicConversation: null,
		publicConversationModalTitle: PUBLIC_CONVERSATION_MODAL_TITLE,
		publicConversationModalName: 'public-conversation',
		singleLineAttributes: ['request', 'ticketRelation', 'delayedResponse'],
		isEmailTicket: computed.equal('ticket.channel.ticketChannelType.id', _ticketChannelTypes.default.EMAIL),
		createdLog: computed('ticket.reporter.userName', 'ticket.channel', function () {
			var ticket = this.get('ticket');
			var createdLogRecord = { createdAt: ticket.get('createdAt') };

			if (this.get('isEmailTicket') && ticket.get('channel.details.customerEmail')) {
				createdLogRecord.originEmail = ticket.get('channel.details.originEmail');
				createdLogRecord.userName = ticket.get('channel.details.customerEmail');
			} else {
				createdLogRecord.userName = ticket.get('reporter.userName');
			}

			return createdLogRecord;
		}),
		changeLogs: computed('ticket.changeLogs.[]', function () {
			var _this = this;

			return _emberData.default.PromiseArray.create({
				promise: RSVP.all(this.get('ticket.changeLogs').sortBy('createdAt').reverse().map(function (log) {
					return _this.get('changeLog').formatChangeLog(log);
				}))
			});
		}),
		actions: {
			showInternalCommentModal: function showInternalCommentModal(currentInternalComment) {
				this.setProperties({
					internalComment: currentInternalComment,
					internalCommentModalTitle: INTERNAL_COMMENT_MODAL_TITLE + ' #' + currentInternalComment.id
				});
				this.showModal(this.get('internalCommentModalName'));
			},
			hideInternalCommentModal: function hideInternalCommentModal() {
				this.hideModal(this.get('internalCommentModalName'));
			},
			showPublicConversationModal: function showPublicConversationModal(currentPublicConversation) {
				this.setProperties({
					publicConversation: currentPublicConversation,
					publicConversationModalTitle: PUBLIC_CONVERSATION_MODAL_TITLE + ' #' + currentPublicConversation.id
				});
				this.showModal(this.get('publicConversationModalName'));
			},
			hidePublicConversationModal: function hidePublicConversationModal() {
				this.hideModal(this.get('publicConversationModalName'));
			}
		}
	});
});