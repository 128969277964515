define("tickets/enums/ticketRelationTypes", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		RELATES: 100,
		DUPLICATES: 200
	});
});