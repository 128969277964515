define('tickets/helpers/empty', ['exports', '@abcum/ember-helpers/helpers/empty'], function (exports, _empty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _empty.default;
    }
  });
  Object.defineProperty(exports, 'empty', {
    enumerable: true,
    get: function () {
      return _empty.empty;
    }
  });
});