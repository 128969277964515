define('tickets/helpers/natural-sort', ['exports', '@abcum/ember-helpers/helpers/natural-sort'], function (exports, _naturalSort) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _naturalSort.default;
    }
  });
  Object.defineProperty(exports, 'naturalSort', {
    enumerable: true,
    get: function () {
      return _naturalSort.naturalSort;
    }
  });
});