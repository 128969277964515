define('tickets/controllers/users/edit', ['exports', 'tickets/mixins/controllers/save'], function (exports, _save) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_save.default, {
		canService: Ember.inject.service('can'),
		users: Ember.inject.service(),
		modelName: 'user',
		parentRoute: 'users',
		confirmPageLeaving: true,
		isUserSelf: Ember.computed('model.user.id', 'users.self.id', function () {
			return this.get('model.user.id') === this.get('users.self.id');
		}),
		isUserHigherRanking: Ember.computed('model.user.role.roleRank.rank', 'users.selfRank', function () {
			return this.get('model.user.role.roleRank.rank') < this.get('users.selfRank');
		}),
		roleEditDisabled: Ember.computed('model.user.role.isReadonly', 'this.isUserSelf', function () {
			return this.get('model.user.role.isReadonly') || this.isUserSelf;
		}),
		isEditingDisabled: Ember.computed('model.users.permissions', 'this.isUserHigherRanking', function () {
			return !this.canService.can('write user') || this.isUserHigherRanking;
		}),
		readOnlyPermissionReason: Ember.computed('model.users.permissions', 'this.isUserHigherRanking', function () {
			if (this.canService.can('write user') && this.isUserHigherRanking) {
				return 'Your role is unsuitable to edit this user';
			}
		}),
		actions: {
			updateSelectedGroups: function updateSelectedGroups(groups) {
				this.set('model.user.groups', groups);
			},
			updateIsActive: function updateIsActive(_ref) {
				var isChecked = _ref.isChecked;

				this.set('model.user.isActive', isChecked);
			}
		}
	});
});