define('tickets/services/reports', ['exports', 'tickets/services/report-formatters'], function (exports, _reportFormatters) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var AVAILABLE_FORMATS = {
		json: 'json',
		csv: 'csv'
	};

	exports.default = Ember.Service.extend({
		ajax: Ember.inject.service(),
		getReportFormatter: function getReportFormatter(reportType) {
			return _reportFormatters.default[reportType] || function (_ref) {
				var rows = _ref.rows,
				    headers = _ref.headers;
				return { rows: rows, headers: headers };
			};
		},
		generate: function generate(reportType, data) {
			return this.ajax.request('/api/v1/reports/' + reportType + '/' + AVAILABLE_FORMATS.json, {
				method: 'POST',
				data: data
			});
		},
		export: function _export(reportType, data) {
			return this.ajax.request('/api/v1/reports/' + reportType + '/' + AVAILABLE_FORMATS.csv, {
				dataType: 'arraybuffer',
				method: 'POST',
				data: data
			});
		}
	});
});