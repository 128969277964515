define('tickets/routes/groups/edit/email-templates', ['exports', 'tickets/enums/sourceTypes', 'tickets/mixins/routes/groups'], function (exports, _sourceTypes, _groups) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var DEFAULT_ROUTE = 'groups.edit.general';

	exports.default = Ember.Route.extend(_groups.default, {
		model: function model() {
			var _modelFor = this.modelFor('groups.edit'),
			    group = _modelFor.group;

			if (group.get('sourceType.id') !== _sourceTypes.default.EMAIL) {
				return this.transitionTo(DEFAULT_ROUTE);
			}

			return Ember.RSVP.hash({ group: group });
		}
	});
});