define('tickets/services/related-phones', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var NUMBERS_REGEX = /[^0-9]/g;

	exports.default = Ember.Service.extend({
		buildTicketRelatedPhones: function buildTicketRelatedPhones(snapshot, callers) {
			var customerPhone = snapshot.record.get('customer.phone');
			var associatedPhones = (snapshot.record.get('customer.associatedPhoneNumbers') || '').split(', ');
			var callerPhones = callers.mapBy('phone');
			var relatedPhones = [customerPhone].concat(_toConsumableArray(callerPhones), _toConsumableArray(associatedPhones)).map(function () {
				var phone = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
				return phone && phone.replace(NUMBERS_REGEX, '');
			}).filter(Boolean).join(' ');

			return relatedPhones || null;
		}
	});
});