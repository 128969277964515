define('tickets/mixins/routes/permissions', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		users: Ember.inject.service(),
		permissions: Ember.inject.service(),
		userAuthenticated: Ember.computed.bool('users.self'),
		beforeModel: function beforeModel(transition) {
			this._super.apply(this, arguments);

			if (transition.isAborted) {
				return;
			}

			var permissions = this.get('requiredPermissions');

			if (this.userAuthenticated && !this.permissions.can(permissions)) {
				transition.abort();

				this.replaceWith('/not-found');
			}
		}
	});
});