define('tickets/adapters/global-setting', ['exports', 'tickets/adapters/application', 'tickets/config/environment'], function (exports, _application, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		urlForQuery: function urlForQuery(query) {
			switch (query.type) {
				case 'all':
					delete query.type;

					return _environment.default.host + '/api/v1/global-settings';
				default:
					return this._super.apply(this, arguments);
			}
		}
	});
});