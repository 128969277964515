define('tickets/components/dropdown-multi-select-with-subitems', ['exports', 'tickets/components/input-field', 'tickets/mixins/components/dropdownPlaceholder'], function (exports, _inputField, _dropdownPlaceholder) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _inputField.default.extend(_dropdownPlaceholder.default, {
		tagName: 'div',
		classNames: ['filter-box', 'prop-box'],
		value: [],
		selectAllModifier: 'all',
		fullTextSearch: 'exact',
		selected: Ember.computed.alias('value'),
		itemsLength: Ember.computed.alias('allItems.length'),
		allSelected: Ember.computed('selected.length', 'itemsLength', function () {
			return this.get('selected.length') === this.get('allItems.length');
		}),
		items: Ember.computed('allItems', function () {
			var _this = this;

			var filterItems = this.get('allItems');
			var parentName = this.get('groupBy');
			var displayProperty = this.get('displayProperty');
			var parentDisplayProperty = this.get('parentDisplayProperty');
			var parentOrderProperty = 'parent.' + parentDisplayProperty;

			var processedItems = filterItems.reduce(function (acc, filterItem) {
				var parent = filterItem.get(parentName);
				var parentId = filterItem.get(parentName + '.id');
				var existentOptionsGroup = acc.findBy('parentId', parentId);
				var selected = _this.get('selected') || [];

				filterItem.set('checked', selected.includes(filterItem.get('id')));
				filterItem.set('displayValue', filterItem.get(displayProperty));
				parent.set('displayValue', parent.get(parentDisplayProperty));

				if (!existentOptionsGroup) {
					acc.push(Ember.Object.create({
						checked: false,
						parentId: parentId,
						parent: parent,
						items: [filterItem]
					}));
				} else {
					existentOptionsGroup.items.push(filterItem);
				}

				return acc;
			}, []);

			processedItems.forEach(function (parent) {
				parent.set('checked', _this.allChildrenChecked(parent));
				parent.items.sortBy(displayProperty);
			});

			return processedItems.sortBy(parentOrderProperty);
		}),
		allChildrenChecked: function allChildrenChecked(parent) {
			return parent.items.isEvery('checked');
		},
		updateSelected: function updateSelected() {
			this.set('selected', this.get('items').reduce(function (acc, current) {
				var ids = current.items.filterBy('checked', true).map(function (_ref) {
					var id = _ref.id;
					return id;
				});

				return acc.concat(ids);
			}, []));
		},
		onSelectChild: function onSelectChild(parentId, id) {
			var parent = this.get('items').findBy('parentId', parentId);
			var child = parent.items.findBy('id', id);

			child.set('checked', !child.checked);
			parent.set('checked', this.allChildrenChecked(parent));
			this.updateSelected();
		},
		onSelectParent: function onSelectParent(parentId) {
			var parent = this.get('items').findBy('parentId', parentId);
			var isParentChecked = parent.checked;

			parent.items.setEach('checked', !isParentChecked);
			parent.set('checked', !isParentChecked);
			this.updateSelected();
		},
		onSelectAll: function onSelectAll() {
			var allSelected = this.get('allSelected');

			this.get('items').forEach(function (parent) {
				parent.set('checked', !allSelected);
				parent.items.setEach('checked', !allSelected);
			});
			this.updateSelected();
		},

		actions: {
			onChange: function onChange(text, value, element) {
				var _ref2 = element.length ? element[0].dataset : element.dataset,
				    id = _ref2.id,
				    parentId = _ref2.parentId,
				    modifier = _ref2.modifier;

				if (modifier === this.selectAllModifier) {
					return this.onSelectAll();
				}

				if (!id) {
					return this.onSelectParent(parentId);
				}

				return this.onSelectChild(parentId, id);
			},
			onHide: function onHide() {
				var value = this.get('selected');

				this.set('value', value);
				this.sendAction('onSelect', value);
			}
		}
	});
});