define('tickets/components/generic-filters/multi-select-with-subitems-filter', ['exports', 'tickets/components/dropdown-multi-select-with-subitems'], function (exports, _dropdownMultiSelectWithSubitems) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _dropdownMultiSelectWithSubitems.default.extend({
		tagName: '',
		filter: {},
		actions: {
			onSelect: function onSelect(values) {
				this.sendAction('updateFilter', this.get('filter.property'), values);
			}
		}
	});
});