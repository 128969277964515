define('tickets/components/dropdown-multi-select', ['exports', 'tickets/components/input-field', 'tickets/services/sort-resolvers', 'tickets/mixins/components/dropdownPlaceholder'], function (exports, _inputField, _sortResolvers, _dropdownPlaceholder) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var DEFAULT_SORT_RESOLVER = 'simple';

	exports.default = _inputField.default.extend(_dropdownPlaceholder.default, {
		tagName: 'div',
		items: [],
		selected: [],
		placeholder: '',
		mapProperty: '',
		sortResolver: '',
		selectAllModifier: 'all',
		fullTextSearch: 'exact',
		itemsLength: Ember.computed.alias('items.length'),
		sortedItems: Ember.computed('items.[]', 'displayProperty', function () {
			var sort = _sortResolvers.default[this.sortResolver || DEFAULT_SORT_RESOLVER];

			return sort(this.get('items'), this.get('displayProperty'));
		}),
		isSelectAllVisible: Ember.computed('sortedItems', function () {
			return this.sortedItems && !this.disableSelectAll;
		}),
		allSelected: Ember.computed('selected.length', 'itemsLength', function () {
			var total = this.get('items.length');

			return total && this.get('selected.length') === total;
		}),
		onSelectAll: function onSelectAll() {
			var allItems = this.get('items').mapBy(this.get('mapProperty'));
			var selectedItems = this.get('allSelected') ? [] : allItems;

			this.set('selected', selectedItems);
		},
		onSelect: function onSelect(prop) {
			var updatedSelected = void 0;
			var currentSelected = this.get('selected') || [];
			var selected = [].concat(_toConsumableArray(currentSelected));
			var isSelected = selected.includes(prop);

			if (isSelected) {
				updatedSelected = selected.without(prop);
			} else {
				selected.push(prop);
				updatedSelected = selected;
			}

			this.set('selected', updatedSelected);
		},

		actions: {
			onChange: function onChange(text, value) {
				var selectAll = value === this.selectAllModifier;

				selectAll ? this.onSelectAll() : this.onSelect(value);
			},
			onHide: function onHide() {
				this.sendAction('selectItems', this.get('selected'));
			},
			showDropdown: function showDropdown() {
				this.$('.ui.dropdown').dropdown('show');
			}
		}
	});
});