define('tickets/components/table-pagination', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: 'div',
		classNames: ['pagination-container', 'clearfix'],
		page: null,
		pageCount: null,
		limit: null,
		isProcessing: true,
		itemsPerPage: [10, 25, 50],
		showItems: Ember.computed('page', 'limit', 'pageCount', function () {
			var currentPage = +this.get('page');
			var pageCount = +this.get('pageCount');

			var showFirstPage = currentPage > 2;
			var showBackDots = currentPage > 3;
			var showLastPage = pageCount - currentPage >= 2;
			var showForwardDots = pageCount - currentPage >= 3;
			var gotoBackEnabled = currentPage > 1;
			var gotoForwardEnabled = currentPage < pageCount;

			var firstPage = buildPage(1, showFirstPage);
			var currentPageObj = buildPage(currentPage);
			var backDots = buildPage(currentPage - 3, showBackDots, '...');
			var forwardDots = buildPage(currentPage + 3, showForwardDots, '...');
			var lastPage = buildPage(+pageCount, showLastPage);
			var prevPageObj = buildPage(currentPage - 1, gotoBackEnabled);
			var nextPageObj = buildPage(currentPage + 1, gotoForwardEnabled);

			return [firstPage, backDots, prevPageObj, currentPageObj, nextPageObj, forwardDots, lastPage].filterBy('isVisible');
		}),
		actions: {
			changePage: function changePage(page) {
				var pageCount = +this.get('pageCount');

				if (page > pageCount || page < 1) {
					return;
				}

				var props = {
					page: page,
					limit: +this.get('limit'),
					pageCount: +pageCount
				};

				this.sendAction('gotoPageNumber', props);
			},
			setPageLimit: function setPageLimit(limit) {
				var props = {
					page: 1,
					limit: +limit,
					pageCount: +this.get('pageCount')
				};

				this.sendAction('gotoPageNumber', props);
			}
		}
	});


	/**
  * Build page object.
  *
  * @param {Number} index - page index
  * @param {Boolean} isVisible - is button visible
  * @param {String} displayName - page display name
  * @returns {Object} page object to bind
  */
	function buildPage(index) {
		var isVisible = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
		var displayName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

		return { displayName: displayName || index, page: index, isVisible: isVisible };
	}
});