define('tickets/services/departments', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		store: Ember.inject.service(),
		ajax: Ember.inject.service(),
		fetch: function fetch() {
			var _this = this;

			return this.ajax.request('/api/v1/departments/all').then(function (depts) {
				return _this.store.pushPayload(depts);
			});
		},
		getAll: function getAll() {
			return this.store.peekAll('department').sortBy('name');
		}
	});
});