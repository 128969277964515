define('tickets/models/role', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo,
	    hasMany = _emberData.default.hasMany;
	exports.default = _emberData.default.Model.extend({
		name: attr('string'),
		isReadonly: attr('boolean'),
		isDefault: attr('boolean'),
		brand: belongsTo('brand'),
		permissions: hasMany('permission'),
		roleRank: attr()
	});
});