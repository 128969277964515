define('tickets/services/change-logs/callers', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (log) {
		var oldValue = JSON.parse(log.get('oldValue'));
		var newValue = JSON.parse(log.get('newValue'));
		var arrayOfChanges = oldValue && oldValue.length > newValue.length ? oldValue : newValue;

		var callers = arrayOfChanges.map(function (caller, index) {
			return {
				oldValue: oldValue ? oldValue[index] : '',
				newValue: newValue[index] || ''
			};
		});

		return {
			displayAttribute: log.get('displayAttribute'),
			createdAt: log.get('createdAt'),
			user: log.get('user'),
			template: 'callers',
			callers: callers
		};
	};
});