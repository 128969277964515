define('tickets/routes/users/edit', ['exports', 'tickets/mixins/routes/edit', 'tickets/enums/requiredPermissions'], function (exports, _edit, _requiredPermissions) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_edit.default, {
		requiredPermissions: _requiredPermissions.default.USERS,
		groups: Ember.inject.service(),
		roles: Ember.inject.service(),
		users: Ember.inject.service(),
		model: function model(params) {
			var _this = this;

			return Ember.RSVP.hash({
				user: this.getModel('user', params.id),
				groups: this.groups.getAll(),
				roles: this.roles.getBranded(),
				higherRoles: this.roles.getBranded().filter(function (role) {
					return role.roleRank.rank < _this.users.get('selfRank');
				}).map(function (role) {
					return String(role.id);
				})
			});
		}
	});
});