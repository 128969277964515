define('tickets/mixins/controllers/routing-tabs/common', ['exports', 'tickets/mixins/controllers/save', 'tickets/mixins/leavePageConfirmation'], function (exports, _save, _leavePageConfirmation) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create(_save.default, _leavePageConfirmation.default, {
		confirmPageLeaving: true,
		actions: {
			save: function save() {
				var _this = this;

				return this._super().then(function () {
					var modelName = _this.get('modelName');
					_this.transitionToRoute(_this.nextStepRouteName, _this[modelName].id);
				}).catch(function (validations) {
					_this.set('validations', validations);
				});
			}
		}
	});
});