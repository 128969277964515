define('tickets/adapters/ticket', ['exports', 'tickets/adapters/application', 'tickets/config/environment'], function (exports, _application, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		urlForQuery: function urlForQuery(query) {
			var customerId = query.customerId;


			switch (query.type) {
				case 'available':
					delete query.type;

					return _environment.default.host + '/api/v1/tickets/available';
				case 'latestByCustomerId':
					delete query.type;
					delete query.customerId;

					return _environment.default.host + '/api/v1/customers/' + customerId + '/tickets-latest';
				default:
					return this._super.apply(this, arguments);
			}
		},
		urlForQueryRecord: function urlForQueryRecord(query) {
			var ticketId = query.relatedTicketId;

			if (ticketId) {
				delete query.relatedTicketId;

				return _environment.default.host + '/api/v1/tickets/related/' + ticketId;
			}

			return this._super.apply(this, arguments);
		}
	});
});