define('tickets/mixins/leavePageConfirmation', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		router: Ember.inject.service(),
		confirmationMessage: 'Would you like to save changes?',
		getModelChanges: function getModelChanges(model, excludeAttrs) {
			var changes = model.getModelChanges();

			excludeAttrs.forEach(function (name) {
				delete changes[name];
			});

			return Object.keys(changes);
		},
		defaultResetModel: function defaultResetModel(controller) {
			var model = controller.model,
			    modelName = controller.modelName,
			    resetModel = controller.resetModel;

			var currentModel = model[modelName];

			if (resetModel) {
				return resetModel.apply(controller);
			}

			currentModel.rollback();
		},
		defaultCheckSaveCondition: function defaultCheckSaveCondition(controller) {
			var checkSaveCondition = controller.checkSaveCondition;


			if (checkSaveCondition) {
				return checkSaveCondition.apply(controller);
			}

			return controller.actions.save.apply(controller);
		},
		handleGoToRoute: function handleGoToRoute(route, queryParams) {
			var controller = this.get('currentController');

			var model = controller.model,
			    modelName = controller.modelName,
			    _controller$confirmPa = controller.confirmPageLeaving,
			    confirmPageLeaving = _controller$confirmPa === undefined ? false : _controller$confirmPa,
			    _controller$excludeMo = controller.excludeModelAttrs,
			    excludeModelAttrs = _controller$excludeMo === undefined ? [] : _controller$excludeMo;

			var currentModel = model[modelName];

			if (!confirmPageLeaving) {
				this.defaultResetModel(controller);

				return this.get('router').transitionTo(route, { queryParams: queryParams });
			}

			var changes = this.getModelChanges(currentModel, excludeModelAttrs);

			if (changes.length && confirm(this.confirmationMessage)) {
				currentModel.redirectAfterSave = { route: route, queryParams: queryParams };

				return this.defaultCheckSaveCondition(controller);
			}

			this.defaultResetModel(controller);
			this.get('router').transitionTo(route, { queryParams: queryParams });
		},

		actions: {
			goToRoute: function goToRoute(route) {
				var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
				    queryParams = _ref.values;

				this.handleGoToRoute(route, queryParams);
			}
		}
	});
});