define('tickets/controllers/groups/edit', ['exports', 'tickets/enums/sourceTypes', 'tickets/mixins/controllers/groups/common', 'tickets/mixins/controllers/routing-tabs/wrapper'], function (exports, _sourceTypes, _common, _wrapper) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Controller.extend(_wrapper.default, _common.default, {
		pageTitle: 'Edit Group',
		showEmailTemplates: Ember.computed('group.sourceType.id', function () {
			var sourceType = this.get('group.sourceType.id');

			return sourceType === _sourceTypes.default.EMAIL;
		}),
		tabs: Ember.computed('showEmailTemplates', function () {
			return [{ title: 'General Information', route: 'groups.edit.general' }, { title: 'Settings', route: 'groups.edit.settings' }].concat(_toConsumableArray(this.get('showEmailTemplates') && [{
				title: 'Email Templates',
				route: 'groups.edit.email-templates'
			}]));
		})
	});
});