define('tickets/mixins/components/dropdownPlaceholder', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		message: { count: '' },
		dynamicPlaceholder: false,
		selectOnKeydown: true,
		someSelected: Ember.computed.bool('selected.length'),
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			this.initPlaceholder();
		},
		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			this.destroyPlaceholder();
		},
		setPlaceholder: function setPlaceholder() {
			var placeholder = this.get('placeholder');
			var allItemsLength = this.get('itemsLength');
			var selectedLength = this.get('selected.length');
			var label = selectedLength ? placeholder + ' (' + selectedLength + '/' + allItemsLength + ')' : placeholder;

			return this.$('input.search').attr('placeholder', label);
		},
		initPlaceholder: function initPlaceholder() {
			if (this.get('dynamicPlaceholder')) {
				this.setPlaceholder();

				return Ember.addObserver(this, 'selected.length, itemsLength', this, 'setPlaceholder');
			}
		},
		destroyPlaceholder: function destroyPlaceholder() {
			if (this.get('dynamicPlaceholder')) {
				this.setPlaceholder();

				return Ember.removeObserver(this, 'selected.length, itemsLength', this, 'setPlaceholder');
			}
		}
	});
});