define('tickets/models/ticket-relation', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo;
	exports.default = _emberData.default.Model.extend({
		createdAt: attr('date'),
		ticketRelationTypeId: attr(),
		ticket: belongsTo('ticket', { inverse: null }),
		relatedTicket: belongsTo('ticket', { inverse: null }),

		internalComments: Ember.computed.alias('relatedTicket.internalComments'),
		externalComments: Ember.computed.alias('relatedTicket.externalComments')
	});
});