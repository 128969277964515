define('tickets/services/report-formatters/index', ['exports', 'tickets/services/report-formatters/categories', 'tickets/services/report-formatters/users', 'tickets/services/report-formatters/groups', 'tickets/services/report-formatters/transfers', 'tickets/services/report-formatters/open-tickets'], function (exports, _categories, _users, _groups, _transfers, _openTickets) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		categories: _categories.default,
		users: _users.default,
		groups: _groups.default,
		transfers: _transfers.default,
		'open-tickets': _openTickets.default
	};
});