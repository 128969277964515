define('tickets/components/checklist-section', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var DEFAULT_SORT_PROPERTY = 'id';

	exports.default = Ember.Component.extend({
		items: [],
		availableItems: [],
		disabled: false,
		isAllSelected: Ember.computed.not('items.length'),
		selectedItems: Ember.computed.alias('items.[]'),
		itemGroups: Ember.computed('availableItems.[]', function () {
			var availableOptions = this.get('availableItems');
			var hasOrder = Boolean(availableOptions.get('firstObject.order'));
			var sortProp = hasOrder ? 'order' : DEFAULT_SORT_PROPERTY;

			return this.get('availableItems').rejectBy('parent.id').sortBy(sortProp).map(function (item) {
				var children = item.get('children') || [];

				item.set('children', children.sortBy(sortProp));

				return item;
			});
		}),
		actions: {
			toggle: function toggle(item) {
				if (this.get('disabled')) {
					return;
				}

				var items = this.get('items');
				var parent = item.get('parent.content');
				var childSections = item.get('children').toArray();

				if (items.includes(item)) {
					items.removeObjects([item].concat(_toConsumableArray(childSections)));
				} else {
					items.pushObjects([item].concat(_toConsumableArray(childSections)));
				}

				if (parent) {
					var children = parent.get('children');

					if (children.every(function (s) {
						return items.includes(s);
					})) {
						items.pushObject(parent);
					}

					if (children.any(function (s) {
						return !items.includes(s);
					})) {
						items.removeObject(parent);
					}
				}
			},
			toggleAll: function toggleAll() {
				if (this.get('disabled')) {
					return;
				}

				if (this.get('isAllSelected')) {
					this.set('items', this.get('availableItems'));
				} else {
					this.get('items').clear();
				}
			}
		}
	});
});