define('tickets/models/customer', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo;

	var RELATED_IDS = ['relatedFileIds', 'relatedSerialNumbers', 'relatedMIds'];
	var originTags = Object.freeze({
		'merchants-link': 'MerchantLink',
		'merchants-link-devices': 'MerchantLink',
		'irn-products': 'IRN',
		'irn-merchants': 'IRN',
		'ubc-offices': 'Titan',
		'ubc-equipment': 'Titan',
		'ubc-services': 'Titan',
		'ubc-merchants': 'Titan',
		'citadel-serials': 'Citadel',
		'citadel-merchants': 'Citadel',
		'citadel-software': 'Citadel'
	});

	exports.default = _emberData.default.Model.extend({
		rm: attr('string'),
		nsm: attr('string'),
		mId: attr('string'),
		rank: attr('string'),
		email: attr('string'),
		phone: attr('string'),
		address: attr('string'),
		sicCode: attr('string'),
		timeZone: attr('string'),
		fileId: attr('string'),
		siteId: attr('string'),
		serialId: attr('string'),
		officeId: attr('string'),
		customerId: attr('string'),
		legalName: attr('string'),
		vipMember: attr('boolean'),
		vipProgram: attr('string'),
		statusCode: attr('string'),
		officeName: attr('string'),
		partnerLevel: attr('string'),
		salesRepId: attr('string'),
		shift4Mid: attr('string'),
		statusName: attr('string'),
		yearsWithUs: attr('string'),
		relatedMIds: attr('string'),
		salesRepName: attr('string'),
		federalTaxId: attr('string'),
		customerName: attr('string'),
		fundingStatus: attr('string'),
		ownershipType: attr('string'),
		supportRepName: attr('string'),
		relatedFileIds: attr('string'),
		primaryOwnerName: attr('string'),
		secondaryOwnerName: attr('string'),
		tertiaryOwnerName: attr('string'),
		authorizedContactName: attr('string'),
		relatedSerialNumbers: attr('string'),
		associatedPhoneNumbers: attr('string'),
		origin: attr('string'),
		brand: belongsTo('brand'),
		relatedIds: Ember.computed.apply(Ember, RELATED_IDS.concat([function () {
			var _this = this;

			return RELATED_IDS.map(function (fieldName) {
				return _this.get(fieldName);
			}).filter(Boolean).join(', ').split(', ').uniq().join(', ');
		}])),
		originTag: Ember.computed('origin', function () {
			return originTags[this.get('origin')] || null;
		}),
		impactedMIDs: Ember.computed('shift4Mid', function () {
			var _getProperties = this.getProperties('origin', 'shift4Mid'),
			    origin = _getProperties.origin,
			    shift4Mid = _getProperties.shift4Mid;

			return origin && shift4Mid && origin.includes('merchants') ? shift4Mid : '';
		})
	});
});