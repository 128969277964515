define('tickets/services/permissions', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Service.extend({
		canService: Ember.inject.service('can'),
		can: function can() {
			return this.hasPermission.apply(this, arguments);
		},
		cannot: function cannot() {
			return !this.hasPermission.apply(this, arguments);
		},
		/**
   * If the array consist of array of permissions check that some of them contains needed permissions:
   * `[[p1, p2], [p3], [p4, p5]]` means `[p1, p2] or [p3] or [p4, p5]`
   * If array consist of permission check that all of them are required:
   * `[p1, p2, p3]` means `p1 and p2 and p3`
   * @param {String|Array<String>|Array<Array<String>>} permission permissions list to check
   * @param {*} args any other arguments
   * @returns {boolean} has or not permission
   */
		hasPermission: function hasPermission(permission) {
			var _this = this;

			for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
				args[_key - 1] = arguments[_key];
			}

			if (!permission) {
				return true;
			}

			var permissions = Array.isArray(permission) ? permission : [permission];

			var everyPermissionPredicate = function everyPermissionPredicate(p) {
				var _canService;

				return (_canService = _this.canService).can.apply(_canService, [p].concat(_toConsumableArray(args)));
			};

			if (Array.isArray(permissions[0])) {
				return permissions.some(function (permissionsGroup) {
					return permissionsGroup.every(everyPermissionPredicate);
				});
			}

			return permissions.every(everyPermissionPredicate);
		}
	});
});