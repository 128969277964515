define('tickets/validators/external-communication-emails-array', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var ExternalCommunicationEmailsArray = _base.default.extend({
		validate: function validate(emails) {
			var isValid = Array.isArray(emails) && !emails.some(function (email) {
				return !email.includes('@shift4.com');
			});
			return isValid || 'Only shift4 domain emails are allowed';
		}
	});

	exports.default = ExternalCommunicationEmailsArray;
});