define('tickets/mixins/controllers/tickets/statusResolver', ['exports', 'tickets/enums/ticketStatuses'], function (exports, _ticketStatuses) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed;
	exports.default = Ember.Mixin.create({
		users: Ember.inject.service(),
		allStatuses: computed.alias('model.ticketStatuses'),
		disabledStatusIds: [],
		manualStatuses: computed('allStatuses', function () {
			return this.allStatuses.rejectBy('id', _ticketStatuses.default.DUPLICATE);
		}),
		availableStatuses: computed('ticket.{assignee,isNew,ticketStatus}', function () {
			return this.statusesResolverBuilder(this.ticket).getAvailableStatuses();
		}),
		statusesResolverBuilder: function statusesResolverBuilder(ticket) {
			var _this = this;

			switch (ticket.get('ticketStatus.id')) {
				case _ticketStatuses.default.RESOLVED:
					return {
						getAvailableStatuses: function getAvailableStatuses() {
							return _this.manualStatuses.filter(function (status) {
								return [_ticketStatuses.default.OPEN, _ticketStatuses.default.RESOLVED].includes(status.id);
							});
						}
					};
				case _ticketStatuses.default.OPEN:
					return {
						getAvailableStatuses: function getAvailableStatuses() {
							var inProgressDisabled = _this.get('ticket.assignee.id') !== _this.get('users.self.id');

							if (inProgressDisabled) {
								return _this.manualStatuses.rejectBy('id', _ticketStatuses.default.IN_PROGRESS);
							}

							return _this.manualStatuses;
						}
					};
				case _ticketStatuses.default.DUPLICATE:
					return { getAvailableStatuses: function getAvailableStatuses() {
							return _this.allStatuses.filterBy('id', _ticketStatuses.default.DUPLICATE);
						} };
				default:
					return { getAvailableStatuses: function getAvailableStatuses() {
							return _this.manualStatuses;
						} };
			}
		}
	});
});