define('tickets/controllers/roles/index', ['exports', 'tickets/mixins/controllers/listing'], function (exports, _listing) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_listing.default, {
		pageCount: Ember.computed.alias('model.roles.meta.pageCount'),
		selectRoleRoute: 'roles.edit',
		columns: [{
			name: 'Role name',
			attribute: 'name'
		}]
	});
});