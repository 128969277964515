define('tickets/mixins/ability', ['exports', 'ember-can'], function (exports, _emberCan) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberCan.Ability.extend({
		users: Ember.inject.service(),
		permissions: Ember.computed.alias('users.permissions'),
		userType: Ember.computed.alias('users.self.userTypeId'),
		hasPermission: function hasPermission(permission) {
			return this.permissions && this.permissions.isAny('id', permission);
		},
		hasNoPermission: function hasNoPermission(permission) {
			return !this.hasPermission(permission);
		}
	});
});