define('tickets/serializers/task', ['exports', 'ember-data', 'moment', 'tickets/serializers/application'], function (exports, _emberData, _moment, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
		attrs: {
			taskStatus: { serialize: 'ids', deserialize: 'records', key: 'taskStatusId' },
			ticket: { serialize: 'ids', deserialize: 'records', key: 'ticketId' }
		},
		normalize: function normalize(typeClass, hash) {
			if (hash.reminder) {
				var timeDiffInHours = _moment.default.duration((0, _moment.default)(hash.deadline).diff(hash.reminder)).asHours();
				hash.reminder = timeDiffInHours;
			}

			return this._super.apply(this, arguments);
		},
		serialize: function serialize() {
			var json = this._super.apply(this, arguments);

			if (json.reminder) {
				var hoursCount = json.reminder;
				json.reminder = (0, _moment.default)(json.deadline).subtract(hoursCount, 'hours');
			}

			return json;
		}
	});
});