define('tickets/routes/tasks/edit', ['exports', 'tickets/mixins/routes/ticketing-system'], function (exports, _ticketingSystem) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_ticketingSystem.default, {
		users: Ember.inject.service(),
		model: function model(params) {
			return Ember.RSVP.hash({
				task: this.store.findRecord('task', params.id),
				assignees: this.users.getAll()
			});
		},
		setupController: function setupController(controller) {
			this._super.apply(this, arguments);

			controller.setProperties({
				validations: null
			});
		}
	});
});