define('tickets/components/tooltip-section', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var INIT_TIMEOUT = 1000;
	var SHOW_DELAY = 150;

	exports.default = Ember.Component.extend({
		title: null,
		text: null,
		canInit: Ember.computed('title', 'text', function () {
			return this.title && this.text;
		}),
		didRender: function didRender() {
			this._super.apply(this, arguments);
			this.initTooltip();
		},
		initTooltip: function initTooltip() {
			var _this = this;

			if (!this.canInit) {
				return;
			}

			return Ember.run.later(function () {
				var $tooltip = _this.$('.tooltip-section');

				$tooltip && $tooltip.popup({
					className: { popup: 'ui popup tooltip-section-popup' },
					delay: { show: SHOW_DELAY },
					hoverable: true,
					performance: false,
					verbose: false
				});
			}, INIT_TIMEOUT);
		}
	});
});