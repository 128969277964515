define('tickets/controllers/tickets/edit', ['exports', 'tickets/mixins/controllers/tasks/edit', 'tickets/mixins/controllers/tickets/edit', 'tickets/mixins/controllers/tickets/relatedTickets'], function (exports, _edit, _edit2, _relatedTickets) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_edit2.default, _edit.default, _relatedTickets.default, {
		ajax: Ember.inject.service(),
		toast: Ember.inject.service(),
		permissions: Ember.inject.service(),
		queryParams: ['autoAssign'],
		pageTitle: 'Edit Ticket',
		saveTaskDisabled: true,
		excludeModelAttrs: ['watchers'],
		tasksSortParams: ['taskStatus.id:asc', 'createdAt:asc'],
		tasks: Ember.computed.sort('ticket.tasks', 'tasksSortParams'),
		cannotEditTicket: Ember.computed.not('canEditTicket'),
		canEditTicket: Ember.computed('ticket.assigneeId', function () {
			return this.permissions.can('edit ticket', this.get('ticket'));
		}),
		appMenuHeader: Ember.computed('ticket.id', function () {
			return this.get('ticket.id') && '#' + this.get('ticket.id') + ' ' + this.get('ticket.subject');
		}),
		observeTaskChanges: Ember.observer('task.{subject,description}', function () {
			var _this = this;

			Ember.run.next(function () {
				_this.get('task') && _this.get('task').validate().then(function (_ref) {
					var validations = _ref.validations;

					_this.set('saveTaskDisabled', !validations.isValid);
				});
			});
		}),
		actions: {
			save: function save() {
				this._super().catch(function () {});
			},
			clone: function clone(cloneId) {
				this.transitionToRoute('tickets.create', { cloneId: cloneId });
			},
			saveTask: function saveTask() {
				this.save(this.get('task'));
				this.hideModal(this.get('taskModalName'));
			},
			updateWatchers: function updateWatchers(watchers) {
				this.set('ticket.watchers', watchers);
			}
		}
	});
});