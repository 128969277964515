define('tickets/components/department-group-users-select', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: '',
		addEmptyOption: true,
		selectedDepartment: '',
		selectedGroup: '',
		selectedUsers: [],
		departmentGroups: Ember.computed('selectedDepartment', function () {
			var id = this.selectedDepartment;

			return id ? this.get('groups').filterBy('department.id', id) : [];
		}),
		groupUsers: Ember.computed('selectedGroup', function () {
			var groupId = this.selectedGroup;

			return groupId ? this.groups.findBy('id', groupId).users : [];
		}),
		selectedDepartmentObserver: Ember.observer('selectedDepartment', function () {
			this.set('selectedGroup');
		}),
		selectedGroupObserver: Ember.observer('selectedGroup', function () {
			var _this = this;

			var allUserIds = this.groupUsers.mapBy('id');

			Ember.run.next(function () {
				_this.set('selectedUsers', allUserIds);
				_this.send('updateSelectedUsers', allUserIds);
			});
		}),
		actions: {
			updateSelectedUsers: function updateSelectedUsers(ids) {
				this.sendAction('updateSelectedItems', ids);
			}
		}
	});
});