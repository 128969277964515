define('tickets/mixins/controllers/listing', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		queryParams: ['page', 'limit'],
		page: 1,
		limit: 50,
		actions: {
			gotoPageNumber: function gotoPageNumber(props) {
				this.setProperties({
					page: props.page,
					limit: props.limit
				});
			}
		}
	});
});