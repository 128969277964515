define('tickets/mixins/controllers/tickets/comments', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _extends = Object.assign || function (target) {
		for (var i = 1; i < arguments.length; i++) {
			var source = arguments[i];

			for (var key in source) {
				if (Object.prototype.hasOwnProperty.call(source, key)) {
					target[key] = source[key];
				}
			}
		}

		return target;
	};

	exports.default = Ember.Mixin.create({
		init: function init() {
			this._super.apply(this, arguments);

			this.setDefaultComments();
		},

		externalComment: null,
		externalCommentValidations: {},
		internalComment: null,
		internalCommentValidations: {},
		createComment: function createComment(modelName, text) {
			var _this = this;

			var attachments = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
			var params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

			var comment = this.get(modelName);

			comment.setProperties(_extends({
				text: text
			}, params, {
				createdAt: new Date(),
				user: this.store.peekRecord('user', this.get('users.self.id'))
			}));

			return comment.validate().then(function (_ref) {
				var validations = _ref.validations;

				if (!validations.isValid) {
					return _this.set(modelName + 'Validations', comment.get('validations.attrs'));
				}

				comment.set(_this.get('ticketModelName'), _this.get('ticket'));

				if (attachments.length) {
					comment.get('attachments').pushObjects(attachments);
				}
				if (!_this.get('ticket.isNew')) {
					_this.save(comment, true, { errorMessage: "Something went wrong. We couldn't process your request and save the comment. Please try to save again." }).then(function () {
						_this.setDefaultComment(modelName);
						_this.get('newCommentAttachments').clear();
					}).catch(function () {
						comment.set(_this.get('ticketModelName'), undefined);
					});
				} else {
					_this.setDefaultComment(modelName);
				}
			});
		},
		setDefaultComments: function setDefaultComments() {
			this.setDefaultComment('externalComment');
			this.setDefaultComment('internalComment');
		},
		setDefaultComment: function setDefaultComment(modelName) {
			this.set(modelName + 'Validations');

			return this.set(modelName, this.store.createRecord(modelName));
		},

		actions: {
			addInternalComment: function addInternalComment(_ref2) {
				var text = _ref2.text;

				this.createComment('internalComment', text);
			},
			addExternalComment: function addExternalComment(_ref3) {
				var text = _ref3.text,
				    attachments = _ref3.attachments,
				    details = _ref3.details,
				    from = _ref3.from;

				this.createComment('externalComment', text, attachments, { details: details, from: from });
			},
			clearNewCommentAttachments: function clearNewCommentAttachments() {
				this.get('newCommentAttachments').clear();
			},
			clearNewComment: function clearNewComment(modelName) {
				this.setDefaultComment(modelName);
			}
		}
	});
});