define('tickets/helpers/pattern-numbers', ['exports', '@abcum/ember-helpers/helpers/pattern-numbers'], function (exports, _patternNumbers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _patternNumbers.default;
    }
  });
  Object.defineProperty(exports, 'patternNumbers', {
    enumerable: true,
    get: function () {
      return _patternNumbers.patternNumbers;
    }
  });
});