define('tickets/controllers/groups/create', ['exports', 'tickets/mixins/controllers/groups/edit', 'tickets/mixins/controllers/groups/common', 'tickets/mixins/controllers/routing-tabs/wrapper'], function (exports, _edit, _common, _wrapper) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_wrapper.default, _common.default, _edit.default, {
		pageTitle: 'Create Group',
		nextStepRouteName: 'groups.edit.general',
		tabs: [{
			title: 'General Information',
			route: 'groups.edit.general'
		}]
	});
});