define('tickets/routes/sign-in', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		ajax: Ember.inject.service(),
		session: Ember.inject.service(),
		authStrategy: null,
		authenticatedObserver: Ember.observer('session.isAuthenticated', function () {
			if (this.get('session.isAuthenticated')) {
				return this.transitionTo(this.get('authStrategy.defaultRoute'));
			}
		}),
		beforeModel: function beforeModel(transition) {
			var _this = this;

			var strategy = transition.params['sign-in'].strategy;

			var authStrategy = this.set('authStrategy', this._getAuthStrategy(strategy));

			if (this.get('session.isAuthenticated')) {
				return this.transitionTo(authStrategy.defaultRoute);
			}

			return authStrategy.authenticate(transition).then(function (route) {
				transition.abort();
				_this.transitionTo('/preheat/' + encodeURIComponent(route));
			}).catch(function () {
				_this.transitionTo('sign-in');
			});
		},
		model: function model() {
			return this.authStrategy.model && this.authStrategy.model();
		},
		_getAuthStrategy: function _getAuthStrategy(strategyName) {
			var owner = Ember.getOwner(this);

			return owner.lookup('service:sign-in-strategies/' + strategyName.toLowerCase());
		}
	});
});