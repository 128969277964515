define('tickets/models/department', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo,
	    hasMany = _emberData.default.hasMany;


	var validations = (0, _emberCpValidations.buildValidations)({
		name: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('length', { max: 64 }), (0, _emberCpValidations.validator)('unique-department-name')],
		externalCommunicationEmails: [(0, _emberCpValidations.validator)('external-communication-emails-array')]
	});

	exports.default = _emberData.default.Model.extend(validations, {
		name: attr('string'),
		brand: belongsTo('brand'),
		groups: hasMany('group'),
		mailingOptions: hasMany('mailingOption'),
		externalCommunicationEmails: attr(),
		isActive: attr('boolean'),
		notifyCallers: attr('boolean'),
		defaultNotifyCallersSelection: attr('boolean')
	});
});