define('tickets/mixins/routes/tickets/edit', ['exports', 'tickets/mixins/routes/edit', 'tickets/mixins/routes/document-title'], function (exports, _edit, _documentTitle) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create(_edit.default, _documentTitle.default, {
		tags: Ember.inject.service(),
		users: Ember.inject.service(),
		groups: Ember.inject.service(),
		departments: Ember.inject.service(),
		surveysGroups: Ember.inject.service(),
		categories: Ember.inject.service(),
		queryTicket: function queryTicket() {
			return this.store.createRecord('ticket');
		},
		getRouteTitle: function getRouteTitle(_ref) {
			var _ref$ticket = _ref.ticket,
			    id = _ref$ticket.id,
			    subject = _ref$ticket.subject;

			return id && subject && '#' + id + ' - ' + subject;
		},
		model: function model(params) {
			return Ember.RSVP.hash({
				ticket: this.queryTicket(params),
				ticketStatuses: this.store.peekAll('ticketStatus'),
				resolutionTypes: this.store.peekAll('resolutionType'),
				filteredResolutionTypes: this.store.peekAll('resolutionType').filterBy('hidden', false),
				terminalSofts: this.store.peekAll('terminalSoft'),
				utgBuilds: this.store.peekAll('utgBuild'),
				assignees: this.users.getAll(),
				brands: this.store.peekAll('brand'),
				groups: this.groups.getAll(),
				departments: this.departments.getAll(),
				surveysGroups: this.surveysGroups.getAll(),
				requestTypes: this.categories.getAll(),
				priorityTypes: this.store.peekAll('priorityType'),
				ticketChannelTypes: this.store.peekAll('ticketChannelType'),
				tags: this.tags.getAll()
			});
		},
		setupController: function setupController(controller) {
			this._super.apply(this, arguments);

			controller.setProperties({
				customer: null,
				validations: null,
				newCommentAttachments: []
			});
		}
	});
});