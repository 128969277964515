define('tickets/mixins/keyboardHandlers', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		handleEscapeKeyDown: function handleEscapeKeyDown(event) {
			if (!this.showMenu) {
				return;
			}

			if (this.parentRoute && event.key === 'Escape') {
				var parsedParentOptions = JSON.parse(this.parentOptions || '{}');

				this.handleGoToRoute(this.parentRoute, parsedParentOptions);
			}
		},
		handleKeyDown: function handleKeyDown(event) {
			this.handleEscapeKeyDown(event);
		},
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			var menuComponent = this;

			menuComponent.handleKeyDown = menuComponent.handleKeyDown.bind(menuComponent);
			window.addEventListener('keydown', menuComponent.handleKeyDown);
		},
		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			var menuComponent = this;

			window.removeEventListener('keydown', menuComponent.handleKeyDown);
		}
	});
});