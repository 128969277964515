define('tickets/services/surveys-groups', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		store: Ember.inject.service(),
		ajax: Ember.inject.service(),
		fetch: function fetch() {
			var _this = this;

			return this.ajax.request('/api/v1/surveys/groups').then(function (groups) {
				var mappedGroups = groups.whitelistGroupsIds.map(function (groupId) {
					return {
						id: groupId
					};
				});

				_this.store.pushPayload({ surveysGroups: mappedGroups });
			});
		},
		getAll: function getAll() {
			return this.store.peekAll('surveys-group').sortBy('id');
		}
	});
});