define('tickets/services/edit-ticket-storage', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _extends = Object.assign || function (target) {
		for (var i = 1; i < arguments.length; i++) {
			var source = arguments[i];

			for (var key in source) {
				if (Object.prototype.hasOwnProperty.call(source, key)) {
					target[key] = source[key];
				}
			}
		}

		return target;
	};

	exports.default = Ember.Service.extend({
		get: function get(fieldName) {
			var currentData = this._getTicketPresetData();

			return currentData[fieldName];
		},
		set: function set(ticketPreset) {
			var currentData = this._getTicketPresetData();
			var updatedData = _extends({}, currentData, ticketPreset);

			localStorage.ticketPreset = JSON.stringify(updatedData);

			return updatedData;
		},
		_getTicketPresetData: function _getTicketPresetData() {
			var preset = localStorage.ticketPreset;

			return preset ? JSON.parse(preset) : {};
		}
	});
});