define('tickets/transforms/callers', ['exports', 'ember-data', 'tickets/utils/clearFalsyValues'], function (exports, _emberData, _clearFalsyValues) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var EMPTY_CALLERS = [{}];

	exports.default = _emberData.default.Transform.extend({
		deserialize: function deserialize(serialized) {
			if (!serialized) {
				serialized = EMPTY_CALLERS;
			}

			return serialized;
		},
		serialize: function serialize(deserialized) {
			return getValidCallers(deserialized);
		}
	});


	/**
  * Remove empty params from each caller
  * @param {Object} callers Ticket callers
  * @returns {Array|Null} valid ticket callers
  */
	function getValidCallers(callers) {
		if (!callers) {
			return EMPTY_CALLERS;
		}

		var callersWithoutEmptyParams = callers.map(_clearFalsyValues.default);
		var validCallers = callersWithoutEmptyParams.filter(function (caller) {
			return Object.keys(caller).length;
		});

		return validCallers.length ? validCallers : EMPTY_CALLERS;
	}
});