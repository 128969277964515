define('tickets/components/comments-tab/internal', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed;
	exports.default = Ember.Component.extend({
		modelName: 'internalComment',
		comments: [],
		commentsSortParams: ['createdAt:asc'],
		sortedComments: computed.sort('comments', 'commentsSortParams'),
		newInternalComment: null,
		validations: {},
		actions: {
			addComment: function addComment() {
				this.sendAction('addComment', { text: this.get('newInternalComment.text') });
			},
			discardComment: function discardComment() {
				this.sendAction('clearNewComment', this.modelName);
			}
		}
	});
});