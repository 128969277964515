define('tickets/serializers/dealer-ticket', ['exports', 'ember-data', 'tickets/serializers/application'], function (exports, _emberData, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
		relatedPhones: Ember.inject.service(),
		attrs: {
			ticketStatus: { serialize: 'ids', deserialize: 'records', key: 'ticketStatusId' },
			customer: { serialize: 'ids', deserialize: 'records', key: 'customerId' },
			attachments: { serialize: 'ids', deserialize: 'records' },
			externalComments: { serialize: false, deserialize: 'records' },
			channel: { embedded: 'always' }
		},
		normalize: function normalize(model, hash) {
			if (hash.customerId) {
				hash.customer = {
					customerId: hash.customerId
				};
			}

			return this._super.apply(this, arguments);
		},
		serialize: function serialize(snapshot) {
			var json = this._super.apply(this, arguments);

			json.relatedPhones = this.relatedPhones.buildTicketRelatedPhones(snapshot, json.callers);

			return json;
		},
		payloadKeyFromModelName: function payloadKeyFromModelName() {
			return 'ticket';
		}
	});
});