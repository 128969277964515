define('tickets/routes/not-found', ['exports', 'tickets/config/environment', 'tickets/mixins/sidebar'], function (exports, _environment, _sidebar) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_sidebar.default, {
		users: Ember.inject.service(),
		afterModel: function afterModel() {
			var _this = this;

			Ember.run.next(function () {
				return _this.hideSidebar();
			});
		},
		actions: {
			willTransition: function willTransition() {
				var _this2 = this;

				Ember.run.next(function () {
					return _this2.showSidebar();
				});
			},
			goBack: function goBack() {
				window.history.back();
			},
			goToHomePage: function goToHomePage() {
				return this.transitionTo(_environment.default.userHomeRoute);
			}
		}
	});
});