define('tickets/services/sign-in-strategies/oauth', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		ajax: Ember.inject.service(),
		session: Ember.inject.service(),
		defaultRoute: '/',
		authenticate: function authenticate(transition) {
			var _this = this;

			return this.get('session').authenticate('authenticator:sso', transition.queryParams.token).then(function () {
				return transition.queryParams.attempt ? transition.queryParams.attempt : _this.defaultRoute;
			});
		},
		model: function model() {
			var transitionAttempt = this.get('session.attemptedTransition.intent.url');
			var queryParams = transitionAttempt ? '?attempt=' + encodeURIComponent(transitionAttempt) : '';
			var callback = encodeURIComponent(window.location.origin + '/#/sign-in/oAuth' + queryParams);

			return Ember.RSVP.hash({
				authUrl: this.get('ajax').request('/api/v1/auth/url?callback=' + callback).then(function (_ref) {
					var url = _ref.url;
					return url;
				})
			});
		}
	});
});