define('tickets/validators/callers', ['exports', 'ember-cp-validations/validators/base', 'tickets/enums/ticketChannelTypes'], function (exports, _base, _ticketChannelTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Callers = _base.default.extend({
		validationMessage: 'This field can\'t be blank',
		validate: function validate(value, options, model) {
			var isTicketChannelTypePhone = model.get('channel.ticketChannelType.id') === _ticketChannelTypes.default.PHONE;
			var isNewTicketChannelTypePhone = model.get('ticketChannelType.id') === _ticketChannelTypes.default.PHONE;

			if (isTicketChannelTypePhone || isNewTicketChannelTypePhone) {
				var isEveryCallerValid = value.every(function (_ref) {
					var name = _ref.name,
					    phone = _ref.phone;
					return name && phone;
				});

				return isEveryCallerValid || this.validationMessage;
			}

			return true;
		}
	});

	Callers.reopenClass({
		getDependentsFor: function getDependentsFor() {
			return ['model.callers.@each.{name,phone}', 'model.ticketChannelType.id'];
		}
	});

	exports.default = Callers;
});