define('tickets/routes/categories', ['exports', 'tickets/mixins/routes/ticketing-system', 'tickets/enums/requiredPermissions'], function (exports, _ticketingSystem, _requiredPermissions) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_ticketingSystem.default, {
		categories: Ember.inject.service(),
		requiredPermissions: _requiredPermissions.default.CATEGORIES,
		model: function model() {
			return Ember.RSVP.hash({ categories: this.store.peekAll('category') });
		}
	});
});