define('tickets/mixins/controllers/departments/common', ['exports', 'tickets/utils/regex', 'tickets/constants/notifyCallersDropdownTypes'], function (exports, _regex, _notifyCallersDropdownTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		modelName: 'department',
		parentRoute: 'departments',
		department: Ember.computed.alias('model.department'),
		validations: {},
		notifyCallersDropdownTypes: _notifyCallersDropdownTypes.default,
		actions: {
			updateIsActive: function updateIsActive(_ref) {
				var isChecked = _ref.isChecked;

				this.set('department.isActive', isChecked);
			},

			externalEmailsChanged: function externalEmailsChanged(emails) {
				this.set('department.externalCommunicationEmails', emails.filter(_regex.testEmail));
			},
			updateNotifyCallers: function updateNotifyCallers(_ref2) {
				var isChecked = _ref2.isChecked;

				this.set('department.notifyCallers', isChecked);

				if (!isChecked) {
					this.set('department.defaultNotifyCallersSelection', true);
				}
			},
			updateDefaultNotifyCallersSelection: function updateDefaultNotifyCallersSelection(value) {
				this.set('department.defaultNotifyCallersSelection', value === 'true');
			}
		}
	});
});