define('tickets/models/notification', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo;
	exports.default = _emberData.default.Model.extend({
		details: attr(),
		ticketId: attr('string'),
		createdAt: attr('date'),
		isRead: attr('boolean'),
		author: belongsTo('user')
	});
});