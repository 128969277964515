define('tickets/utils/regex', ['exports', 'validator/es/lib/isEmail'], function (exports, _isEmail) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.replaceCommas = exports.testEmail = undefined;


  /**
   * Validates email string
   * @param {?String} email Email
   * @returns {boolean} returns if email is valid
   */
  function testEmail(email) {
    return (0, _isEmail.default)(email || '');
  }

  /**
   * Replaces commas with spaces in the provided string
   * @param {String} string Provided string
   * @returns {String} String without commas
   */
  function replaceCommas() {
    var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

    return string.replace(/,/g, ' ');
  }

  exports.testEmail = testEmail;
  exports.replaceCommas = replaceCommas;
});