define('tickets/enums/reportAvailableFields', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		'all-tickets': [{
			value: 'id',
			displayProperty: 'Ticket ID'
		}, {
			value: 'sourceType',
			displayProperty: 'Source Type'
		}, {
			value: 'priorityName',
			displayProperty: 'Priority'
		}, {
			value: 'status',
			displayProperty: 'Status'
		}, {
			value: 'resolutionReason',
			displayProperty: 'Resolution Reason'
		}, {
			value: 'subject',
			displayProperty: 'Subject'
		}, {
			value: 'assignee',
			displayProperty: 'Assignee'
		}, {
			value: 'customerName',
			displayProperty: 'Customer'
		}, {
			value: 'customerId',
			displayProperty: 'Customer ID'
		}, {
			value: 'rank',
			displayProperty: 'Customer Rank'
		}, {
			value: 'tags',
			displayProperty: 'Tags'
		}, {
			value: 'originatingDepartment',
			displayProperty: 'Originating Department'
		}, {
			value: 'department',
			displayProperty: 'Department'
		}, {
			value: 'group',
			displayProperty: 'Group'
		}, {
			value: 'description',
			displayProperty: 'Problem Description'
		}, {
			value: 'actionPlan',
			displayProperty: 'Action Plan'
		}, {
			value: 'summary',
			displayProperty: 'Case Summary'
		}, {
			value: 'managerNotes',
			displayProperty: 'Manager Notes'
		}, {
			value: 'updatedAt',
			displayProperty: 'Updated Date'
		}, {
			value: 'createdAt',
			displayProperty: 'Created Date'
		}, {
			value: 'resolvedAt',
			displayProperty: 'Resolve Date'
		}, {
			value: 'posPms',
			displayProperty: 'POS/PMS'
		}]
	});
});