define('tickets/serializers/external-comment', ['exports', 'ember-data', 'tickets/serializers/application'], function (exports, _emberData, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
		attrs: {
			user: { serialize: 'ids', deserialize: 'ids' },
			attachments: { serialize: 'ids', deserialize: 'records' }
		},
		serialize: function serialize() {
			return this._super.apply(this, arguments);
		}
	});
});