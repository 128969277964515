define('tickets/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo,
	    hasMany = _emberData.default.hasMany;
	exports.default = _emberData.default.Model.extend({
		email: attr('string'),
		firstName: attr('string'),
		lastName: attr('string'),
		ticketsCount: attr('number'),
		userTypeId: attr('number'),
		externalIds: attr(),
		brand: belongsTo('brand'),
		role: belongsTo('role'),
		groups: hasMany('group'),
		isActive: attr('boolean'),
		userName: Ember.computed('firstName', 'lastName', function () {
			return this.get('firstName') + ' ' + this.get('lastName');
		})
	});
});