define("tickets/services/report-formatters/categories", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (_ref) {
		var rows = _ref.rows,
		    headers = _ref.headers;

		rows = rows.map(function (row) {
			row.isTotal = !!row[0];
			return row;
		});

		return { rows: rows, headers: headers };
	};
});