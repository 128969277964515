define('tickets/services/roles', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		store: Ember.inject.service(),
		users: Ember.inject.service(),
		brandId: Ember.computed.alias('users.brand.id'),
		fetch: function fetch() {
			return this.store.query('role', { type: 'all' });
		},
		getAll: function getAll() {
			return this.store.peekAll('role').sortBy('name');
		},
		getBranded: function getBranded() {
			return this.store.peekAll('role').filterBy('brand.id', this.get('brandId')).rejectBy('isReadonly').sortBy('roleRank.rank');
		}
	});
});