define('tickets/mixins/controllers/tasks/listing', ['exports', 'tickets/mixins/controllers/listing'], function (exports, _listing) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var QUERY_PARAMS = ['deadline', 'direction', 'column'];

	exports.default = Ember.Mixin.create(_listing.default, {
		pageCount: Ember.computed.alias('model.tasks.meta.pageCount'),
		selectTaskRoute: 'tasks.edit',
		selectTaskOptions: { parentRoute: 'tasks.index' },
		relatedName: 'Reporter',
		relatedAttribute: 'reporter.userName',
		direction: 'desc',
		column: 'deadline',
		queryParams: QUERY_PARAMS,
		columns: Ember.computed('relatedName', 'relatedAttribute', function () {
			return [{
				name: 'Subject',
				attribute: 'subject'
			}, {
				name: this.get('relatedName'),
				attribute: this.get('relatedAttribute'),
				template: 'assignee'
			}, {
				name: 'Ticket #',
				template: 'ticket-link',
				attribute: this.get('selectTaskOptions').parentRoute
			}, {
				name: 'Status',
				attribute: 'taskStatus.name',
				template: 'task-status-color'
			}, {
				name: 'Created Date',
				attribute: 'createdAt',
				template: 'date-time-cell'
			}, {
				name: 'Due Date',
				attribute: 'deadline',
				template: 'date-time-cell',
				sortParam: 'deadline'
			}];
		}),
		sortQuery: Ember.computed('column', 'direction', function () {
			return {
				column: this.get('column'),
				direction: this.get('direction')
			};
		}),
		actions: {
			updateSort: function updateSort(_ref) {
				var _this = this;

				var column = _ref.column,
				    direction = _ref.direction;

				Ember.run.next(function () {
					return _this.setProperties({ column: column, direction: direction, page: 1 });
				});
			}
		}
	});
});