define('tickets/components/generic-filters/date-range-filter', ['exports', 'tickets/components/input-field'], function (exports, _inputField) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	exports.default = _inputField.default.extend({
		presetDates: Ember.computed('filter.value', function () {
			var value = this.get('filter.value');

			if (this.isValueInvalid(value)) {
				return null;
			}

			var _value = _slicedToArray(value, 2),
			    start = _value[0],
			    end = _value[1];

			return { start: start, end: end };
		}),
		ranges: Ember.computed('filter.ranges', function () {
			return this.get('filter.ranges');
		}),
		isValueInvalid: function isValueInvalid(value) {
			return !value || !Array.isArray(value) || !value.length;
		},

		actions: {
			setRange: function setRange(_ref) {
				var start = _ref.start,
				    end = _ref.end;

				var value = [start.toISOString(), end.toISOString()];

				this.sendAction('updateFilter', this.get('filter.property'), value);
			},
			resetRange: function resetRange() {
				this.sendAction('resetFilter', this.get('filter.property'));
			}
		}
	});
});