define('tickets/helpers/begs-with', ['exports', '@abcum/ember-helpers/helpers/begs-with'], function (exports, _begsWith) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _begsWith.default;
    }
  });
  Object.defineProperty(exports, 'begsWith', {
    enumerable: true,
    get: function () {
      return _begsWith.begsWith;
    }
  });
});