define("tickets/services/report-formatters/groups", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (_ref) {
		var rows = _ref.rows,
		    headers = _ref.headers;

		rows[rows.length - 1].isTotal = true;

		return { rows: rows, headers: headers };
	};
});