define('tickets/mixins/routes/tickets/user-groups-filter-preset', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		users: Ember.inject.service(),
		filterStorage: Ember.inject.service(),
		beforeModel: function beforeModel() {
			this._super.apply(this, arguments);

			var listingType = this.routeName.split('.').pop();
			var presetFilter = this.filterStorage.get(listingType);

			if (!presetFilter.empty) {
				return;
			}

			var presetGroupIds = this.get('users.self.groups').mapBy('id');

			return this.filterStorage.set(listingType, { groupId: presetGroupIds });
		}
	});
});