define('tickets/validators/callers-email', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var CallersEmail = _base.default.extend({
		validationMessage: 'This field can\'t be blank',
		validate: function validate(value) {
			if (!value) {
				return this.validationMessage;
			}

			return true;
		}
	});

	CallersEmail.reopenClass({
		getDependentsFor: function getDependentsFor() {
			return ['model.callers.@each.{email}'];
		}
	});

	exports.default = CallersEmail;
});