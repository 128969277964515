define('tickets/enums/timezones', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		'Pacific (UTC-8)': 10,
		'Mountain (UTC-7)': 20,
		'Central (UTC-6)': 30,
		'Eastern (UTC-5)': 40,
		'Atlantic (UTC-4)': 50,
		'Alaska (UTC-9)': 60,
		'Hawaii (UTC-10)': 70,
		'Samoa (UTC-11)': 80,
		'UTC+9': 90,
		'UTC+10': 100,
		'UTC+11': 110,
		'UTC+12': 120,
		'None (UTC0)': 130
	});
});