define('tickets/mixins/controllers/tickets/brandingStrategy', ['exports', 'tickets/enums/brandingStrategies'], function (exports, _brandingStrategies) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		updateCommunicationBrand: function updateCommunicationBrand() {
			var isNewTicket = this.get('ticket.isNew');
			var hasExternalComments = this.get('ticket.externalComments.length');

			if (hasExternalComments && !isNewTicket) {
				return;
			}

			this.set('ticket.communicationBrand', this.getCommunicationBrand());
		},
		getCommunicationBrand: function getCommunicationBrand() {
			var brandingStrategyId = this.get('ticket.group.brandingStrategy.id');
			var groupBrand = this.get('ticket.group.communicationBrand');
			var customerBrand = this.get('ticket.customer.brand');

			switch (brandingStrategyId) {
				case _brandingStrategies.default.GROUP:
					return groupBrand;
				case _brandingStrategies.default.DATABASE:
					return customerBrand || groupBrand;
				default:
					throw new Error('Strategy ' + brandingStrategyId + ' is not implemented.');
			}
		}
	});
});