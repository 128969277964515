define('tickets/mixins/routes/tickets/listing', ['exports', 'tickets/mixins/routes/ticketing-system', 'tickets/mixins/routes/tickets/deferred-customers', 'tickets/mixins/routes/tickets/user-groups-filter-preset'], function (exports, _ticketingSystem, _deferredCustomers, _userGroupsFilterPreset) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _extends = Object.assign || function (target) {
		for (var i = 1; i < arguments.length; i++) {
			var source = arguments[i];

			for (var key in source) {
				if (Object.prototype.hasOwnProperty.call(source, key)) {
					target[key] = source[key];
				}
			}
		}

		return target;
	};

	function _objectWithoutProperties(obj, keys) {
		var target = {};

		for (var i in obj) {
			if (keys.indexOf(i) >= 0) continue;
			if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
			target[i] = obj[i];
		}

		return target;
	}

	exports.default = Ember.Mixin.create(_ticketingSystem.default, _deferredCustomers.default, _userGroupsFilterPreset.default, {
		tags: Ember.inject.service(),
		toast: Ember.inject.service(),
		users: Ember.inject.service(),
		groups: Ember.inject.service(),
		categories: Ember.inject.service(),
		counter: Ember.inject.service(),
		filterStorage: Ember.inject.service(),
		departments: Ember.inject.service(),
		ticketChannelTypes: Ember.inject.service(),
		beforeModel: function beforeModel(transition) {
			this._super.apply(this, arguments);

			if (this.isFirstAppTransition(transition)) {
				transition.abort();
				this.transitionTo(this.routeName, { queryParams: { presetFilters: true } });
			}
		},
		model: function model(_ref) {
			var _this = this;

			var page = _ref.page,
			    limit = _ref.limit,
			    column = _ref.column,
			    direction = _ref.direction,
			    presetFilters = _ref.presetFilters,
			    rest = _objectWithoutProperties(_ref, ['page', 'limit', 'column', 'direction', 'presetFilters']);

			if (page === 1) {
				this.counter.updateTimeAndCounter();
			}

			var listingType = this.routeName.split('.').pop();
			var presetFilter = this.filterStorage.get(listingType);
			var filter = rest;

			if (presetFilters) {
				filter = _extends({}, filter, presetFilter);
			}

			this.get('filterStorage').set(listingType, filter);
			var query = {
				page: page,
				limit: limit,
				filter: filter,
				sort: { column: column, direction: direction }
			};

			return Ember.RSVP.hash({
				tickets: this.store.query('ticket', query).catch(function () {
					var result = [];

					result.query = query;
					_this.toast.warning('You have too many values in the Filter. Please reset the filter and reload the page.');

					return result;
				}),
				tags: this.tags.getAll(),
				groups: this.groups.getAll(),
				assignees: this.users.getAll(),
				requestTypes: this.categories.getAllRequestTypes(),
				ticketStatuses: this.store.peekAll('ticketStatus'),
				resolutionTypes: this.store.peekAll('resolutionType'),
				departments: this.departments.getAll(),
				ticketChannelTypes: this.ticketChannelTypes.getAll()
			});
		},
		setupController: function setupController(controller, _ref2) {
			var filter = _ref2.tickets.query.filter;

			this._super.apply(this, arguments);

			controller.setProperties(_extends({}, filter, { presetFilters: false }));
		},
		isFirstAppTransition: function isFirstAppTransition(transition) {
			return transition.sequence === 0;
		},

		actions: {
			queryParamsDidChange: function queryParamsDidChange() {
				this._super.apply(this, arguments);
				this.refresh();
			}
		}
	});
});