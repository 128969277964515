define('tickets/routes/incoming-call', ['exports', 'tickets/mixins/routes/ticketing-system', 'tickets/enums/ticketStatuses'], function (exports, _ticketingSystem, _ticketStatuses) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_ticketingSystem.default, {
		model: function model(_ref) {
			var phone = _ref.phone;

			return this.redirectToListing(phone);
		},
		redirectToListing: function redirectToListing(phone) {
			this.transitionTo('tickets.all', {
				queryParams: {
					phone: phone,
					incomingCall: true,
					ticketStatusId: [_ticketStatuses.default.OPEN]
				}
			});
		}
	});
});