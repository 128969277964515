define('tickets/services/report-formatters/transfers', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var NEW_LINE_STRING = '/</br>';

	exports.default = function (_ref) {
		var headers = _ref.headers,
		    rows = _ref.rows;

		rows[rows.length - 1].isTotal = true;
		headers = headers.map(function (header) {
			header.title = replaceSlashWithNewLine(header.title);

			return header;
		});
		rows = rows.map(function (row, i) {
			row[i + 1] = '-';
			row[0] = replaceSlashWithNewLine(row[0]);

			return row;
		});

		return { headers: headers, rows: rows };
	};

	/**
  * Replace slash with new line html string
  * @param {String} text String to find matches at
  * @returns {String} Modified string
  * @private
  */
	function replaceSlashWithNewLine(text) {
		return text.replace('/', NEW_LINE_STRING);
	}
});