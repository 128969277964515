define("tickets/services/report-formatters/open-tickets", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (_ref) {
		var rows = _ref.rows,
		    headers = _ref.headers;

		rows = rows.map(function (row) {
			if (row.firstObject) {
				row.isTotal = true;
			}

			return row;
		});

		return { rows: rows, headers: headers };
	};
});