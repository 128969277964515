define('tickets/serializers/ticket-relation', ['exports', 'ember-data', 'tickets/serializers/application', 'tickets/enums/ticketRelations', 'tickets/enums/ticketRelationTypes'], function (exports, _emberData, _application, _ticketRelations, _ticketRelationTypes) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
		serialize: function serialize() {
			var json = this._super.apply(this, arguments);

			return createTicketRelation(json);
		}
	});


	/**
  * Modifies frontend ticket relations to backend ticket relations
  *
  * @param {Object} relation Ticket to ticket relation from frontend
  * @returns {Object} Ticket to ticket relation for backend
  */
	function createTicketRelation(_ref) {
		var ticketId = _ref.ticketId,
		    relatedTicketId = _ref.relatedTicketId,
		    ticketRelationTypeId = _ref.ticketRelationTypeId;

		switch (+ticketRelationTypeId) {
			case _ticketRelations.default.DUPLICATES.value:
				return {
					ticketId: relatedTicketId,
					relatedTicketId: ticketId,
					ticketRelationTypeId: _ticketRelationTypes.default.DUPLICATES
				};
			case _ticketRelations.default.DUPLICATED.value:
				return { ticketId: ticketId, relatedTicketId: relatedTicketId, ticketRelationTypeId: _ticketRelationTypes.default.DUPLICATES };
			default:
				return { ticketId: ticketId, relatedTicketId: relatedTicketId, ticketRelationTypeId: _ticketRelationTypes.default.RELATES };
		}
	}
});