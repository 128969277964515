define('tickets/enums/ticketStatuses', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Object.freeze({
		OPEN: '100',
		IN_PROGRESS: '200',
		WAITING_ON_CUSTOMER: '300',
		RESOLVED: '500',
		DUPLICATE: '600'
	});
});