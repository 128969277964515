define('tickets/services/sort-resolvers/assignee', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var UNASSIGNED_ITEM_ID = 'unassigned';

	exports.default = function (items, property) {
		if (!items) {
			return [];
		}

		var assignees = items.rejectBy('id', UNASSIGNED_ITEM_ID).sortBy(property);
		var unassign = items.findBy('id', UNASSIGNED_ITEM_ID);

		return [unassign].concat(_toConsumableArray(assignees));
	};
});