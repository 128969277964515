define('tickets/models/task', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo;


	var validations = (0, _emberCpValidations.buildValidations)({
		subject: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('length', { max: 128 })],
		description: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('length', { max: 512 })]
	});

	exports.default = _emberData.default.Model.extend(validations, {
		subject: attr('string'),
		description: attr('string'),
		deadline: attr('date'),
		reminder: attr('number'),
		createdAt: attr('date'),
		updatedAt: attr('date'),
		reporter: belongsTo('user'),
		assignee: belongsTo('user'),
		ticket: belongsTo('ticket'),
		taskStatus: belongsTo('taskStatus')
	});
});