define('tickets/models/group', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    hasMany = _emberData.default.hasMany,
	    belongsTo = _emberData.default.belongsTo;


	var validations = (0, _emberCpValidations.buildValidations)({
		name: [(0, _emberCpValidations.validator)('presence', { presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('length', { max: 64 }), (0, _emberCpValidations.validator)('unique-group-name')],
		teamName: [(0, _emberCpValidations.validator)('length', { max: 64 })],
		communicationBrand: [(0, _emberCpValidations.validator)('presence', { presence: true })],
		brandingStrategy: [(0, _emberCpValidations.validator)('presence', { presence: true })],
		department: [(0, _emberCpValidations.validator)('presence', { presence: true })],
		priorityType: [(0, _emberCpValidations.validator)('presence', { presence: true })],
		link: [(0, _emberCpValidations.validator)('group-link'), (0, _emberCpValidations.validator)('unique-group-emails')],
		externalCommunicationEmails: [(0, _emberCpValidations.validator)('external-communication-emails-array')]
	});

	exports.default = _emberData.default.Model.extend(validations, {
		name: attr('string'),
		teamName: attr('string'),
		link: attr(),
		groupSettings: attr(),
		users: hasMany('user'),
		department: belongsTo('department'),
		brandingStrategy: belongsTo('brandingStrategy'),
		priorityType: belongsTo('priorityType'),
		communicationBrand: belongsTo('brand'),
		sourceType: belongsTo('sourceType'),
		enableChangeTrackingOfInitState: true,
		isActive: attr('boolean'),
		externalCommunicationEmails: attr(),
		isWhitelisted: attr('boolean')
	});
});