define('tickets/helpers/task-status', ['exports', 'tickets/enums/taskStatuses'], function (exports, _taskStatuses) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.taskStatus = taskStatus;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var _STATUS_COVER_MAP;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var DONE = _taskStatuses.default.DONE,
      OPEN = _taskStatuses.default.OPEN;

  var STATUS_COVER_MAP = (_STATUS_COVER_MAP = {}, _defineProperty(_STATUS_COVER_MAP, OPEN, 'open-task'), _defineProperty(_STATUS_COVER_MAP, DONE, 'done-task'), _STATUS_COVER_MAP);

  /**
   * Returns class name related to task status
   * @param {int} statusId ID of current task status
   * @returns {int} className.
   */
  function taskStatus(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        statusId = _ref2[0];

    return STATUS_COVER_MAP[statusId];
  }

  exports.default = Ember.Helper.helper(taskStatus);
});