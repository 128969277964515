define('tickets/services/filter-storage', ['exports', 'tickets/utils/clearFalsyValues'], function (exports, _clearFalsyValues) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		store: Ember.inject.service(),
		users: Ember.inject.service(),
		userId: Ember.computed.alias('users.self.id'),
		all: Ember.computed('userId', {
			get: function get(key) {
				return _getByUser(key, this.userId);
			},
			set: function set(key, value) {
				return _setForUser(key, value, this.userId);
			}
		}),
		my: Ember.computed('userId', {
			get: function get(key) {
				return _getByUser(key, this.userId);
			},
			set: function set(key, value) {
				return _setForUser(key, value, this.userId);
			}
		}),
		unassigned: Ember.computed('userId', {
			get: function get(key) {
				return _getByUser(key, this.userId);
			},
			set: function set(key, value) {
				return _setForUser(key, value, this.userId);
			}
		})
	});


	/**
  * Gets preset of filters on listing pages for user
  * @param {String} key Listing type
  * @param {Number} userId Current logged in user id
  * @returns {Object} Filters for user
  */
	function _getByUser(key, userId) {
		if (!userId) {
			return {};
		}

		var filterName = key + 'Tickets';
		var data = localStorage[filterName] && JSON.parse(localStorage[filterName]);

		var emptyData = { empty: true };
		var userData = data ? data[userId] || emptyData : emptyData;

		///TODO createdAt filter format has changed, think of removing this block later
		if (userData.createdAt && !Array.isArray(userData.createdAt)) {
			delete userData.createdAt;
			_setForUser(key, userData, userId);
		}

		return userData;
	}

	/**
  * Save preset of filters on listing pages for user
  * @param {String} key Listing type
  * @param {Object} value Filter preset
  * @param {Number} userId Current logged in user id
  * @returns {Object} Set value
  */
	function _setForUser(key, value, userId) {
		if (!userId) {
			return {};
		}

		var filterName = key + 'Tickets';
		var truthyValue = (0, _clearFalsyValues.default)(value);
		var field = localStorage[filterName] ? JSON.parse(localStorage[filterName]) : {};

		field[userId] = truthyValue;
		localStorage[filterName] = JSON.stringify((0, _clearFalsyValues.default)(field));

		return truthyValue;
	}
});