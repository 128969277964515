define('tickets/initializers/helpers', ['exports', '@abcum/ember-helpers/initializers/helpers'], function (exports, _helpers) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		name: 'ember-helpers',
		initialize: _helpers.default
	};
});